<div class="dialog-content" mat-dialog-content>
  <mat-icon aria-hidden="false" aria-label="Ícone de expandir para direita" class="material-symbols-outlined warning-class" fontIcon="warning"></mat-icon>

  <div class="dialog-text-first">
      <span>Não foi possível gerar o boleto nesse momento.<b>O Liberacred irá gerar de forma automática e enviar por e-mail</b> assim que possível.</span>
  </div>

  <div class="message-document">
    <mat-icon aria-hidden="false" aria-label="Ícone de informação"
       class="material-symbols"
       fontIcon="info"> </mat-icon><span>Caso queira tentar novamente de forma manual, acesse a tela de detalhes do plano.</span>
  </div>

  <div class="dialog-buttons">
    <yfs-button state="enabled" label="FECHAR" type="primary" (click)="close()"></yfs-button>
  </div>
</div>
