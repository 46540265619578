<div class="dialog-content" mat-dialog-content>
    <img src="assets/svg/warning-icon.svg" alt="logo modal alert" />

    <div class="dialog-text">
        <span>Tem certeza que deseja cancelar? </span>
        <span>Seus dados serão perdidos.</span>
    </div>

    <div class="button-content">
        <yfs-button state="enabled" label="SIM" type="secondary" (click)="clearStorage()"></yfs-button>
        <yfs-button state="enabled" label="NÃO CANCELAR" type="primary" (click)="onNoClick()"></yfs-button>
    </div>
</div>
