import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { TimeoutError, catchError, throwError, timeout, toArray } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { UserService } from 'src/app/services/user.service';
import { customEmailValidator } from 'src/app/shared/validators/email-validator';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';

@Component({
  selector: 'yfs-create-external-user',
  templateUrl: './create-external-user.component.html',
  styleUrls: ['./create-external-user.component.scss']
})
export class CreateExternalUserComponent {
  public fullName = new FormControl('', [Validators.required, LocalValidators.nomeValidator, Validators.maxLength(100)]);
  public email = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]);
  public emailConfirm = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]);
  public cpf = new FormControl('', [Validators.required, LocalValidators.cpfValidator]);
  public celular = new FormControl('', [Validators.required]);
  public concessionaria = new FormControl([], Validators.required);
  public userProfile = new FormControl('', [Validators.required]);
  public concessionarias: any[] = [];
  public listaConcessionarias: any[] = []
  public selectedConcessionarias: number[] = []
  public idsConcessionarias: number[] = []
  public role = '';
  public multiple = true;

  public timeoutError = false;
  public genericError = false;
  public isLoading = false;

  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public celularMask = ['(', /\d/, /\d/, ')', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  public filteredUserProfiles: { idPerfilAcesso: number; nome: string, interno: boolean }[] = [];

  public userProfiles: any;
  public currentListType = 1;

  constructor(private router: Router, public dialog: MatDialog, private apiService: ApiHttpService, private userService: UserService) {}

  ngOnInit() {
    const user = this.userService.getCurrentUser();
    if (user) {
      this.role = user.PerfilAcesso;
    }
    this.getPerfilAcesso();
    this.setupConcessionariaObserver();
    this.userProfile.valueChanges.subscribe((newProfile: number | string | null) => {
      this.email.setValidators([
        Validators.required,
        Validators.email,
        Validators.maxLength(50),
        customEmailValidator(newProfile),
      ]);
      this.email.updateValueAndValidity();
    });
  }

  onUserProfileChange(event: MatSelectChange): void {
    const selectedProfile = event.value;
    if (selectedProfile === 5 || selectedProfile === 13) {
      this.multiple = false
    }else{
      this.multiple = true
    }

    if (this.role !== 'Master' && this.role !== 'Master Representante') {
      this.concessionaria.setValue([]);
      if (selectedProfile == 12 || selectedProfile == 13) {
        if (this.currentListType == 1) this.getConcessionarias(2);
      } else {
        if (this.currentListType == 2) this.getConcessionarias(1);
      }
    }
  }

  checkEmailsMatch() {
    if (this.email.value !== this.emailConfirm.value) {
      this.emailConfirm.setErrors({ emailMismatch: true });
    } else {
      this.emailConfirm.setErrors(null);
    }
  }

  validateFone(){
    if (this.celular.value) {
      this.celular.setValidators(Validators.required);
    }
  }

  parseJsonString(jsonString: any) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Erro ao fazer parsing do JSON:", error);
      return null;
    }
  }

  verifyRole() {
    switch (this.role) {
      case 'Master':
        this.filteredUserProfiles = this.userProfiles.filter((item: { nome: string; }) => item.nome === 'Vendedor');
        this.concessionariaData();
        break;
      case 'Master Representante':
        this.filteredUserProfiles = this.userProfiles.filter((item: { nome: string; }) => item.nome === 'Vendedor Representante');
        this.concessionariaData();
        break;
      case 'SI-Acessos':
      case 'Produtos':
        this.filteredUserProfiles = this.userProfiles;
        this.getConcessionarias(1);
        break;
      default:
        break;
    }
  }

  concessionariaData() {
    const user = this.userService.getCurrentUser();
    if (user) {
      this.concessionarias = user.Concessionarias;
    }

    if (!Array.isArray(this.concessionarias)) {
      this.concessionarias = [this.concessionarias];
    }

    const concessionariasFormatadas = this.concessionarias.map(this.parseJsonString);
    this.listaConcessionarias = concessionariasFormatadas;
    this.selectedConcessionarias = this.listaConcessionarias.map(concessionaria => concessionaria.IdConcessionaria);
    this.idsConcessionarias = this.listaConcessionarias.map(concessionaria => concessionaria.IdConcessionaria);
  }

  getConcessionarias(type: number) {
    this.isLoading = true;
    this.apiService.getConcessionaria(undefined, [1], type).subscribe((result: any[]) => {
      this.isLoading = false;
      this.currentListType = type;
      this.listaConcessionarias = result.sort((a,b) => (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0));
      this.selectedConcessionarias = this.listaConcessionarias.map(concessionaria => concessionaria.IdConcessionaria);
      this.concessionaria.setValue(this.selectedConcessionarias as never[]);
      this.idsConcessionarias = this.listaConcessionarias.map(concessionaria => concessionaria.IdConcessionaria);
    });
  }

  setupConcessionariaObserver() {
    this.concessionaria.valueChanges.subscribe((selectedConcessionarias: number | number[] | null) => {
      if (selectedConcessionarias) {
        this.idsConcessionarias = Array.isArray(selectedConcessionarias) ? selectedConcessionarias : [selectedConcessionarias];
      } else {
        this.idsConcessionarias = [];
      }
    });
  }

  validateCpf(event: any) {
    const value = event.target.value
    if (value && value.length > 0) {
      const normalize = value.replace(/[^0-9]/g, '');
      if (normalize.length == 11) {
        this.isLoading = true;
        this.apiService.getRegistration(normalize, true).pipe(
          timeout(30000),
          catchError((error) => {
            if (error instanceof TimeoutError) {
              this.isLoading = false;
              this.timeoutError = true;
              return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
            }
            return throwError(() => error)
          })
        )
          .subscribe({
            next: result => {
              if (result &&  result.body.Success === true) {
                this.isLoading = false;
                this.openDialogCpf();
              }
              this.isLoading = false;
            },
            error: error => {
              this.isLoading = false;
              console.log(error)
            }
          })
      }
    }
  }

  openDialogCreateUser = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Criar novo usuário externo?',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.sendRequest() },
        secundaryButtonText: 'SIM, CRIAR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  verifyData() {
    this.userProfile.markAsTouched();
    this.celular.markAsTouched();
    this.concessionaria.markAsTouched();
    this.fullName.markAsTouched();
    this.cpf.markAsTouched();
    this.emailConfirm.markAsTouched();
    this.email.markAsTouched();
  }

  sendRequest() {
    this.checkEmailsMatch();
    this.verifyData();
    const cpfNumber = this.cpf.value?.replace(/\D/g, '');
    const celularNumber = this.celular.value?.replace(/\D/g, '');
    const usuario = {
      nome: this.fullName.value,
      interno: false,
      idPerfilAcesso: this.userProfile.value,
      cpf: cpfNumber,
      email: this.email.value,
      telefone: [celularNumber],
      idConcessionaria: this.idsConcessionarias
    };
    this.isLoading = true;
    this.apiService
      .createInternalUser(usuario)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if(error.status === 400 || error.status === 404 || error.status === 500){
            this.openDialogCreateUserError();
            this.isLoading = false;
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.success === true) {
            this.openDialogCreateUserSuccess()
            this.isLoading = false;
          }  else {
            this.isLoading = false;
            this.openDialogCreateUserError();
          }
        },
      });
  }

  openDialogCreateUserSuccess() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Usuário criado com sucesso.',
        icon: 'success',
        primaryButtonText: 'IR PARA A LISTA DE USUÁRIOS',
        primaryButtonVariant: 'primary',
        primaryButtonAction: () => {
          dialogRef.close();
          this.goToUserList() },
      },
    });
  }

  openDialogCreateUserError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Houve um problema ao tentar criar o usuário.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.sendRequest() },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogCancel() {
    this.dialog.open(ModalGenericComponent, {
      width: '424px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.goToUserList() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogCpf() {
    const dialogRef =  this.dialog.open(ModalGenericComponent, {
      width: '346px',
      data: {
        text: 'Usuário já está cadastrado no Liberacred.',
        icon: 'warning',
        primaryButtonText: 'IR PARA A LISTA DE USUÁRIOS',
        primaryButtonVariant: 'primary',
        primaryButtonAction: () => {
          dialogRef.close();
          this.goToUserList() },
      },
    });
  }

  goToUserList() {
    this.router.navigate(['/user-management']);
  }

  getPerfilAcesso() {
    this.isLoading = true;
    this.apiService.getPerfilAcesso().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result && result.length > 0) {
            this.userProfiles = result.filter((el: { interno: any; }) => el.interno == false);
            this.verifyRole();
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }
}
