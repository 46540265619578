import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yfs-modal-term-cancel',
  templateUrl: './modal-term-cancel.component.html',
  styleUrls: ['./modal-term-cancel.component.scss']
})
export class ModalTermCancelComponent {
  public date: string = '';

  constructor(
    public dialogRef: MatDialogRef<ModalTermCancelComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.date = data;
    }

  close() {
    this.dialogRef.close();
  }
}
