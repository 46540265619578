<yfs-header></yfs-header>
<section class="container-content">
  <div *ngIf="currentFlow === 'communication'">
    <div class="button-back">
      <yfs-link-back text="Voltar para Minha área" (click)="getBack()"></yfs-link-back>
    </div>
    <h1>MINUTAS</h1>
    <mat-card class="card">
      <span class="h1">Selecione uma opção</span>
      <yfs-button
      class="template-button"
      state="enabled"
      label="NOVA MINUTA"
      type="primary"
      (click)="goToMessage()"
    ></yfs-button>
    <mat-card-content class="card-content">
      <mat-form-field class="date-field" appearance="fill">
        <mat-label>Data de início de vigência</mat-label>
        <input type="hidden" [matDatepicker]="pickerInicial" [(ngModel)]="dataInicialLabel" />
        <input matInput [textMask]="{ mask: mask }" [formControl]="dataInicial" [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'" />
        <mat-datepicker-toggle matIconSuffix [for]="pickerInicial"></mat-datepicker-toggle>
        <mat-datepicker #pickerInicial></mat-datepicker>
        <mat-error class="date-error" *ngIf="dataInicial.hasError('invalidDate')">Data inválida.</mat-error>
      </mat-form-field>

      <mat-form-field class="date-field" appearance="fill">
        <mat-label>Data final de vigência</mat-label>
        <input type="hidden" [matDatepicker]="pickerFinal" [(ngModel)]="dataFinalLabel" />
        <input matInput [textMask]="{ mask: mask }" [formControl]="dataFinal" [ngModel]="dataFinalLabel | date : 'dd/MM/yyyy'"/>
        <mat-datepicker-toggle matIconSuffix [for]="pickerFinal"></mat-datepicker-toggle>
        <mat-datepicker #pickerFinal></mat-datepicker>
        <mat-error class="date-error" *ngIf="dataFinal.hasError('invalidDate')">Data inválida.</mat-error>
      </mat-form-field>

      <mat-form-field class="select-field" id="status" appearance="fill">
        <mat-label>Tipo da minuta</mat-label>
        <mat-select [formControl]="status" multiple (ngModelChange)="onStatusChange($event)">
          <div class="select-all" (click)="selectAllStatus()">
            <mat-checkbox [(ngModel)]="checkedAllStatus"
                [indeterminate]="selectedStatus.length > 0 && selectedStatus.length < listaStatus.length"
                color="primary"><span class="select-label">Todos</span></mat-checkbox>
          </div>
          <mat-option *ngFor="let status of listaStatus" [value]="status.idStatus">
            <span class="select-label">{{ status.nome }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-card-content>
    </mat-card>
    <mat-card class="card" *ngIf="!showErrorMessage && !emptyDocument" style="margin-top: 16px">
      <div class="template-header">
        <div class="template-header-itens">
          <span class="minutas">{{templates}} minutas encontradas</span>
        </div>
      </div>
      <mat-card-content class="card-content-template">
        <mat-card  class="min-card-template" *ngFor="let message of templateList">
          <div class="card-message">
           <span class="h1">{{ message.nomenclatura | ellipsis:48 }}</span>
            <span class="h2">{{ message.descricao | ellipsis:50 }}</span>
            <span class="flag" *ngIf="message.vigente">EM USO</span>
            <div class="card-button">
              <div class="button">
                <span class="selected-preview">
                  <yfs-tooltip [tooltipText]="'Ver prévia'"
                    ><mat-icon
                      (click)="previewMessage($event, message)"
                      aria-hidden="false"
                      aria-label="Ícone de preview"
                      class="material-symbols"
                      fontIcon="visibility"
                    >
                    </mat-icon>
                  </yfs-tooltip>
                </span>
              </div>
              <div (click)="editMessage($event, message)" class="edit-button">
                <span class="selected-edit-delete">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Ícone de selecionado"
                    class="material-symbols-outlined"
                    fontIcon="edit"
                  ></mat-icon>
                  Editar
                </span>
              </div>
            </div>
          </div>
        </mat-card>
      </mat-card-content>
      <div class="button-more">
        <yfs-button type="secondary" *ngIf="!empty" state="enabled" size="small" label="CARREGAR MAIS" (click)="moreItens()" icon="add-circle-outline" ></yfs-button>
      </div>
    </mat-card>
    <mat-card class="card" *ngIf="showErrorMessage" style="margin-top: 16px">
      <mat-card-content class="card-content-error">
        <img src="assets/svg/error-icon.svg" alt="documento vazio" height="48" width="48">
        <span class="error-message">Estamos com problemas para exibir os templates no momento.</span>
        <span class="error-message">Tente recarregar ou volte mais tarde.</span>
        <yfs-button
          state="enabled"
          class="button-error"
          label="VOLTAR PARA MINHA ÁREA"
          type="primary"
          (click)="getBack()"
        ></yfs-button>
      </mat-card-content>
    </mat-card>
    <mat-card class="card" *ngIf="emptyDocument" style="margin-top: 16px">
      <mat-card-content class="card-content-error">
        <img src="assets/svg/empty.svg" alt="documento vazio" height="48" width="48">
        <span class="error-message">Ainda não há minutas criadas.</span>
        <span class="error-message">Clique no botão abaixo para criar uma nova.</span>
        <yfs-button
          state="enabled"
          class="button-error"
          label="NOVA MINUTA"
          type="primary"
          (click)="goToMessage()"
        ></yfs-button>
      </mat-card-content>
    </mat-card>
  </div>
  <yfs-new-draft *ngIf="currentFlow === 'newDraft'" (currentFlowChanged)="closeNewMessage()" [templateData]="templateSelected"></yfs-new-draft>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
