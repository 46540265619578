import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private userService: UserService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log("inter", err)
            if ([401].includes(err.status)) {
                // auto logout if 401 Unauthorized
                this.userService.logout();
                this.router.navigate(["home"]);
            }

            const isLoginRequest = request.url.split('api/')[1] === 'login'

            if (!isLoginRequest && [403].includes(err.status)) {
                // auto redirect to my area if 403 Unauthorized
                localStorage.setItem("visibleModalUnauthorized", 'open')
                this.router.navigate(["area"]);
            }

            // const error = err.error.message || err.statusText;
            return throwError(err);
        }))
    }
}