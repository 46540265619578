import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { UserService } from 'src/app/services/user.service';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';

interface IRelationBackFlow {
  [key: string]: string;
}

@Component({
  selector: 'yfs-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public currentFlow = 'concessionaire';
  public relationBackFlow: IRelationBackFlow = {
    concessionaire: 'home',
    document: 'concessionaire',
    personalData: 'document',
    address: 'personalData',
    contact: 'address',
    verifyData: 'contact',
  };
  // [fluxo futuro] - permitir usuario nao logado e redirecionar usuario caso faça login em uma conta sem permissão
  // private tokenSubscription: any;
  // constructor(private router: Router, private userService: UserService, public dialog: MatDialog) {}
  constructor(private router: Router) {}

  ngOnInit() {
    // [fluxo futuro]
    // this.tokenSubscription = this.userService.getLoggedUserObservable().subscribe(user => {
    //   const userLocal: any = user;
    //   if (userLocal && !userLocal.PermissaoAcesso.includes(EPermissaoAcesso.CONTRATAR_PLANO)) {
    //     this.dialog.closeAll()
    //     this.openModalUnauthorized();
    //     this.router.navigate(["home"]);
    //   }
    // });
  }

  // [fluxo futuro]
  // ngOnDestroy() {
  //   if (this.tokenSubscription) {
  //     this.tokenSubscription.unsubscribe();
  //   }
  // }

  changeCurrentFlow(flow: string) {
    this.currentFlow = flow;
  }

  getBackHandle() {
    const navigationExtras: NavigationExtras = {
      state: {
        flow: 'productView',
      },
    };

    this.router.navigate(['/simulation'], navigationExtras);

    const flowToGo = this.relationBackFlow[this.currentFlow];
    this.currentFlow = flowToGo;
  }

  // [fluxo futuro]
  // openModalUnauthorized() {
  //   this.dialog.open(ModalGenericComponent, {
  //     width: '384px',
  //     data: {
  //       text: 'Não é possível visualizar essas informações pois você não tem permissões para acessar essa tela.',
  //       primaryButtonAction: 'close',
  //       primaryButtonText: 'FECHAR',
  //       primaryButtonVariant: 'primary',
  //     }
  //   })
  // }
}
