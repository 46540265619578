<div class="dialog-content" mat-dialog-content>
    <div class="container-cards">
        <div class="cards-content">
            <div class="header-informations">
                <span id="name">{{data?.detail?.cliente?.nome}}</span>
                <div id="informations">
                    <span>{{data?.detail?.cliente?.cpf | cpf}}</span>
                    <div class="separator"></div>
                    <span>{{data?.detail?.cliente?.email}}</span>
                    <div class="separator"></div>
                    <span>{{data?.detail?.cliente?.telefone | telefone}}</span>
                </div>
            </div>
            <div class="cards">
                <mat-card class="mini-card-secundary">
                    <span class="h1">Canal de Originação</span>
                    <span class="h2">{{data?.detail?.plano?.canalOriginacao}}</span>
                </mat-card>
                <mat-card class="mini-card-secundary">
                    <span class="h1">Canal de Relacionamento</span>
                    <span class="h2">{{data?.detail?.plano?.canalRelacionamento}}</span>
                </mat-card>
                <mat-card class="mini-card-secundary">
                    <span class="h1">Valor do contrato</span>
                    <span class="h2">{{data?.detail?.plano?.valorContrato | currency}}</span>
                </mat-card>
                <mat-card class="mini-card-secundary">
                    <span class="h1">Data de ativação</span>
                    <span class="h2">{{data?.detail?.plano?.dataAtivacao | date : 'dd/MM/yyyy' }}</span>
                </mat-card>
                <mat-card class="mini-card-secundary">
                    <span class="h1">Contrato</span>
                    <span class="h2">{{data?.detail?.plano?.numeroContrato}}</span>
                </mat-card>
                <mat-card class="mini-card-secundary">
                    <span class="h1">Produto</span>
                    <span class="h2">{{data?.detail?.plano?.produto}}</span>
                </mat-card>
                <mat-card class="mini-card-secundary">
                    <span class="h1">Contrato aditado</span>
                    <span class="h2">{{data?.detail?.plano?.contratoAditado ? 'Sim' : 'Não' }}</span>
                </mat-card>
                <mat-card class="mini-card-secundary">
                    <span class="h1">Multa</span>
                    <span class="h2">{{data?.detail?.plano?.multa | currency}}</span>
                </mat-card>
            </div>
        </div>
        <div class="cards-content">
            <span class="status-plano">Status do plano</span>
            <div class="cards">
                <mat-card class="mini-card">
                    <span class="h1">Recargas</span>
                    <span class="h2">{{data?.detail?.plano?.recargas}}</span>
                </mat-card>
                <mat-card class="mini-card">
                    <span class="h1">Recargas restantes</span>
                    <span class="h2">{{data?.detail?.plano?.recargasRestantes}}</span>
                </mat-card>
                <mat-card class="mini-card">
                    <span class="h1">Pontuais</span>
                    <span class="h2">{{data?.detail?.plano?.pontuais}}</span>
                </mat-card>
                <mat-card class="mini-card">
                    <span class="h1">Saldo</span>
                    <span class="h2">{{data?.detail?.plano?.saldo | currency}}</span>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="seal-eligibility">
        <yfs-seal-eligibility statusText="INELEGÍVEL" [punctuality]="data?.detail?.plano?.pontualidade" [discharge]="data?.detail?.plano?.quitacao"
            [restriction]="data?.detail?.plano?.restricao" [showDetailButton]="false"></yfs-seal-eligibility>
    </div>

    <mat-card>
        <mat-card-content>
            <table mat-table [dataSource]="dataSource2" #secondTable #secondTableSort="matSort" matSort
                (matSortChange)="announceSortChange2($event)">

                <ng-container matColumnDef="numero">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by numero">
                        Recarga
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.numero}}</td>
                </ng-container>

                <ng-container matColumnDef="valor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by valor">
                        Valor
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.valor | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="dataVencimento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by dataVencimento">
                        Data de vencimento
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.dataVencimento | date : 'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="dataLimitePagamento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by dataLimitePagamento">
                        Limite para Pagamento
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.dataLimitePagamento | date : 'dd/MM/yyyy'}}</td>
                </ng-container>

                <ng-container matColumnDef="dataPagamento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by dataPagamento">
                        Data de pagamento
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.dataPagamento | date : 'dd/MM/yyyy'}}</td>
                </ng-container>

                <ng-container matColumnDef="pontual">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by pontual">
                        Pontualidade
                    </th>
                    <td mat-cell *matCellDef="let element" [style.color]="getPontualidadeColor(element.pontual)">
                        <mat-icon aria-hidden="false" aria-label="Ícone" class="material-symbols-outlined"
                            [fontIcon]="getPontualidadeIcon(element.pontual)"></mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by status">
                        Status
                    </th>
                    <td mat-cell *matCellDef="let element"
                        [style.color]="element.status == 'Atrasado' ? 'red' : '' ">
                        {{getStatusName(element.status)}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
            </table>
        </mat-card-content>
    </mat-card>

    <div class="continer-button">
        <yfs-button state="enabled" label="FECHAR DETALHES" type="primary" (click)="closeModal()"></yfs-button>
    </div>
</div>