import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const allowedRoles = route.data['allowedPermissions'] as Array<string>;
    const user = this.userService.getCurrentUser()

    if (!user.PermissaoAcesso || user.PermissaoAcesso.length === 0) {
      localStorage.setItem("visibleModalUnauthorized", 'open')
      this.router.navigate(["area"]);
      return false;
    }

    const isAllowed = allowedRoles.some(r => user.PermissaoAcesso.includes(r))

    if (isAllowed) return true

    localStorage.setItem("visibleModalUnauthorized", 'open')
    this.router.navigate(["area"]);
    return false;
  }


}
