import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IAddress, IConcessionaireRegister, IContact, IDocuments, IPersonalData, IProductRegister, IProposal } from 'src/app/models/register.interface';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { ModalCancelComponent } from 'src/app/components/modal-cancel/modal-cancel.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

interface Parametro {
  assinaturaEletronicaHabilitada: boolean;
}


@Component({
  selector: 'yfs-verify-data',
  templateUrl: './verify-data.component.html',
  styleUrls: ['./verify-data.component.scss']
})
export class VerifyDataComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();

  public product: IProductRegister | undefined
  public documents: IDocuments | undefined
  public personalDatas: IPersonalData | undefined
  public address: IAddress | undefined
  public contact: IContact | undefined
  public timeoutError = false;
  public genericError = false;
  public isLoading = false;
  public proposal: IProposal | undefined;
  public hideRg = false;
  public concessionaire: IConcessionaireRegister | undefined;
  public assinaturaEletronicaHabilitada: boolean | null = false;

  constructor(private router: Router, private apiService: ApiHttpService, public dialog: MatDialog) { }

  ngOnInit() {
    this.assinaturaEletronicaHabilitada = this.obterAssinaturaEletronicaHabilitada();
    if (localStorage.getItem('concessionaria'))
      this.concessionaire = JSON.parse(atob(localStorage.getItem('concessionaria') || '{}'))
    if (localStorage.getItem('produto'))
      this.product = JSON.parse(atob(localStorage.getItem('produto') || '{}'))
    if (localStorage.getItem('documentos'))
      this.documents = JSON.parse(atob(localStorage.getItem('documentos') || '{}'))
    if (localStorage.getItem('dadosPessoais'))
      this.personalDatas = JSON.parse(atob(localStorage.getItem('dadosPessoais') || '{}'))
    if (localStorage.getItem('endereco'))
      this.address = JSON.parse(atob(localStorage.getItem('endereco') || '{}'))
    if (localStorage.getItem('contato'))
      this.contact = JSON.parse(atob(localStorage.getItem('contato') || '{}'))

    this.bidingProposalContract()
  }

  obterAssinaturaEletronicaHabilitada(): boolean | null {
    const parametrosJSON = sessionStorage.getItem('parametros');

    if (parametrosJSON) {
        const parametros: Parametro[] = JSON.parse(parametrosJSON);

        if (parametros.length > 0 && typeof parametros[0].assinaturaEletronicaHabilitada === 'boolean') {
            return parametros[0].assinaturaEletronicaHabilitada;
        }
    }

    return null;
}

  bidingProposalContract() {
    let client = {
      documentos: {
        cpf: this.documents?.cpf.toString(),
        rg: {
          numero: this.documents?.rg.numero ? this.documents.rg.numero.replace(/[^a-zA-Z0-9]/g, '') : "",
          uf: this.documents?.rg.uf,
          orgaoExpedidor: this.documents?.rg.orgaoExpedidor,
          dataExpedicao: this.documents?.rg.dataExpedicao
        }
      },
      dadosPessoais: {
        nomeCompleto: this.personalDatas?.nomeCompleto ? this.personalDatas?.nomeCompleto.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : "",
        dataNascimento: this.personalDatas?.dataNascimento,
        estadoCivil: this.personalDatas?.estadoCivil,
        sexo: this.personalDatas?.sexo,
        nomeMae: this.personalDatas?.nomeMae ? this.personalDatas?.nomeMae.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : "",
      },
      endereco: this.address,
      contato: {
        celularComDdd: this.contact?.celularComDdd ? this.contact?.celularComDdd.toString() : undefined,
        telefoneResidencialComDdd: this.contact?.telefoneResidencialComDdd ? this.contact?.telefoneResidencialComDdd.toString() : undefined,
        email: this.contact?.email
      },
    };
    let planoContratado = {
      idProduto: this.product?.idProduto,
      valorReferencia: this.product?.valorReferencia,
      porcentagemPlano: this.product?.porcentagemPlano,
      idFormaPagamento: this.product?.idFormaPagamento,
      valorTotalLiquido: this.product?.valorTotalLiquido,
      numeroParcelas: this.product?.numeroParcelas,
      valorParcelaBruta: this.product?.valorParcelaBruta,
      idConcessionaria: this.concessionaire?.idConcessionaria,
    }
    this.proposal = {
      planoContratado: planoContratado,
      cliente: client,
    }
  }

  getBackHandle(action: string) {
    if (action !== 'product')
      this.currentFlowChanged.emit(action)
    else {
      this.router.navigate(["simulation"]);
    }
  }

  getSexoDescription(sexo?: string) {
    if (sexo === "M") return "Masculino"
    if (sexo === "F") return "Feminino"
    if (sexo === "O") return "Outro"
    return "-"
  }

  nextStep(){
    this.currentFlowChanged.emit("complete")
  }

  register() {
    this.isLoading = true;
    this.apiService.postContratoManual(this.proposal).pipe(
      timeout(300000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          this.timeoutError = true;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    ).subscribe({
      next: result => {
        if(result.sucesso === false) {
          this.openDialogGenericError();
          this.isLoading = false
        }
        if (result && result.sucesso && result.minuta && result.regulamento && result.idContrato) {
          this.isLoading = false;
          this.completeHandle(result)
        } else {
          this.isLoading = false,
          this.openDialogGenericError();
        }
      },
      error: error => {
        this.isLoading = false;

        if (this.timeoutError === false) this.openDialogGenericError();
        console.log(error)
      }
    })
  }

  openDialogGenericError() {
    this.dialog.open(ModalGenericComponent, {
      width: '350px',
      data: {
        text: 'Não foi possível concluir a contratação. Tente novamente mais tarde.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openDialog() {
    this.dialog.open(ModalCancelComponent, {
      width: '381px'
    })
  }

  clearStorage() {
    localStorage.removeItem("concessionaria")
    localStorage.removeItem("documentos")
    localStorage.removeItem("dadosPessoais")
    localStorage.removeItem("endereco")
    localStorage.removeItem("contato")
  }

  completeHandle(result: any) {
    this.clearStorage()
    this.currentFlowChanged.emit('complete')
    localStorage.setItem("result", result.sucesso)
    localStorage.setItem("minuta", result.minuta)
    localStorage.setItem("boleto", result.boleto)
    localStorage.setItem("regulamento", result.regulamento)
    localStorage.setItem("idContrato", result.idContrato)
    localStorage.setItem("cpf", this.documents?.cpf as string)
  }
}
