<div class="dialog-content" mat-dialog-content>
    <img alt="Ícone de atenção" src="assets/svg/warning-icon.svg" />

    <div  class="dialog-text">
        <span>Tem certeza que deseja excluir este template?</span>
    </div>

    <div class="dialog-contract-area">
        <span class="name">{{data.templateName}}</span>
    </div>

    <div class="dialog-buttons">
        <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="closeModal()"></yfs-button>
        <yfs-button state="enabled" label="SIM, EXCLUIR" type="primary" color="red" (click)="deleteTemplate()"></yfs-button>
    </div>
</div>
