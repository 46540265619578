<yfs-header></yfs-header>
<section>
  <div class="container-content">
    <carousel>
      <slide class="slide">
        <img src="assets/img/banner-moto.png" alt="Banner com a moto">
        <div class="content-slide">
          <span class="h1">LIBERACRED YAMAHA</span>
          <div class="h2">
            <span>O JEITO FÁCIL DE CONSEGUIR</span>
            <span>O SEU FINANCIAMENTO</span>
          </div>

          <div class="h3">
            <span>Garanta a aprovação do</span>
            <span>Financiamento pelo Banco Yamaha</span>

            <span style="margin-top: 15px;">Parcele a entrada em até 12x</span>
          </div>

          <div class="h4">
            <div class="flex-center">
              <div class="dot"></div>
              <span>Crédito pré-aprovado</span>
            </div>
            <div style="margin-top: 5px;" class="flex-center">
              <div class="dot"></div>
              <span>Sem comprovação de renda</span>
            </div>
            <div style="margin-top: 5px;" class="flex-center">
              <div class="dot"></div>
              <span>Sem consulta do nome</span>
            </div>
          </div>

          <div style="margin-top: 20px;">
            <yfs-button state="enabled" data-test="button-simulate" color="white" label="SIMULE" type="primary" (click)="goToSimulation()"></yfs-button>
          </div>
        </div>
      </slide>
      <slide class="slide">
        <img src="assets/img/banner-motor.png" alt="Banner com o motor">
        <div class="content-slide">
          <span class="h1">LIBERACRED YAMAHA</span>
          <div class="h2">
            <span>O JEITO FÁCIL DE CONSEGUIR</span>
            <span>O SEU FINANCIAMENTO</span>
          </div>

          <div class="h3">
            <span>Garanta a aprovação do</span>
            <span>Financiamento pelo Banco Yamaha</span>

            <span style="margin-top: 15px;">Parcele a entrada em até 12x</span>
          </div>

          <div class="h4">
            <div class="flex-center">
              <div class="dot"></div>
              <span>Crédito pré-aprovado</span>
            </div>
            <div style="margin-top: 5px;" class="flex-center">
              <div class="dot"></div>
              <span>Sem comprovação de renda</span>
            </div>
            <div style="margin-top: 5px;" class="flex-center">
              <div class="dot"></div>
              <span>Sem consulta do nome</span>
            </div>
          </div>
          <div style="margin-top: 20px;">
            <yfs-button state="enabled" color="white" label="SIMULE" type="primary" (click)="goToSimulation()"></yfs-button>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
  <div class="container-vantagens" id="Saiba">
    <div class="container-vantagens-itens">
      <div class="container-title">
        <h1>Veja como é simples:</h1>
        <div class="separator"></div>
      </div>
      <div class="about-content">
        <img src="assets/img/step1.png" alt="primeira etapa">
        <div class="separator-dashed"></div>
        <img src="assets/img/step2.png" alt="segunda etapa">
        <div class="separator-dashed"></div>
        <img src="assets/img/step3.png" alt="terceira etapa">
        <div class="separator-dashed"></div>
        <img src="assets/img/step4.png" alt="quarta etapa">
      </div>
      <yfs-button state="enabled" label="FAÇA A SUA SIMULAÇÃO" type="primary" (click)="goToSimulation()" data-test="make-your-simulation"></yfs-button>
    </div>
  </div>
  <div class="container-requisitos" id="Requisitos">
    <div class="container-requisitos-itens">
      <h2>Requisitos</h2>
      <div class="separator separator-white" style="width: 49px;"></div>
      <div class="requirements-h2">
        <div style="margin-top: 16px;" class="flex-center">
          <div class="dot" style="margin-right: 8px;"></div>
          <span>Ter efetuado as 12 recargas no valor total do plano escolhido;</span>
        </div>
        <div style="margin-top: 16px;" class="flex-center">
          <div class="dot" style="margin-right: 8px;"></div>
          <span>Realizar, pelo menos 8 recargas pontuais (*) durante o período de 12 meses do programa;</span>
        </div>
        <div style="margin-top: 16px;" class="flex-center">
          <div class="dot" style="margin-right: 8px;"></div>
          <span>Não possuir restrição no nome ao final do plano.</span>
        </div>
      </div>

      <div class="requirements-h3">
        <span>(*) Pague 1 parcela por mês, em qualquer dia útil entre o primeiro e o último dia do mês, independente da 
          data de vencimento do boleto.</span>
        <span>Ex.: Se a data de vencimento for dia 05, você pode pagar do primeiro dia útil até o último dia útil dentro 
          do mês que a parcela será considerada pontual.
        </span>
        <span style="margin-top: 20px;">O valor acumulado pelo cliente será oferecido como entrada no
          financiamento.</span>
      </div>
    </div>
  </div>
  <div class="container-know-more">
    <div class="container-video">
      <h2>SAIBA MAIS</h2>
      <video id="video" [src]="urlVideo" poster="assets/img/poster-video.png" controls></video>
    </div>
  </div>
</section>
