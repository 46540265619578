import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { CpfValidator } from 'src/app/shared/validators/cpf-validator';
import { UserService } from 'src/app/services/user.service';
import { customEmailValidator } from 'src/app/shared/validators/email-validator';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'yfs-edit-external-user',
  templateUrl: './edit-external-user.component.html',
  styleUrls: ['./edit-external-user.component.scss']
})
export class EditExternalUserComponent {
  public fullName = new FormControl('', [Validators.required, LocalValidators.nomeValidator, Validators.maxLength(100)]);
  public cpf = new FormControl('', [Validators.required, CpfValidator.cpf]);
  public celular = new FormControl('', [Validators.required]);
  public email = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]);
  public emailConfirm = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]);
  public concessionaria = new FormControl({ value: [], disabled: true }, [Validators.required])
  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public celularMask = ['(', /\d/, /\d/, ')', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public idPerfilAcesso: number = 0;
  public idsConcessionarias: number[] = [];
  public checkedAll = false;
  public selectedConcessionaria: number[] = [];
  public listaConcessionaria: any[] = [];
  public currentUser: any;
  public idUsuario: any;
  public multiple = false;

  public timeoutError = false;
  public genericError = false;
  public isLoading = false;
  public userProfile = new FormControl('', [Validators.required]);

  public userProfiles: any;
  public currentListType = 1;

  constructor(private router: Router, public dialog: MatDialog, private apiService: ApiHttpService, private userService: UserService, private route: ActivatedRoute) {
    this.currentUser = this.userService.getCurrentUser();
    this.route.paramMap.subscribe(params => {
      const login = params.get('login');
      if (login) {
        this.getUserData(login);
      }
    });
    this.userProfile.valueChanges.subscribe((newProfile: number | string | null) => {
      this.email.setValidators([
        Validators.required,
        Validators.email,
        Validators.maxLength(50),
        customEmailValidator(newProfile),
      ]);
      this.email.updateValueAndValidity();
    });
  }

  ngOnInit() {
    this.verifyDataValue()
  }

  getBackHandle() {
    this.router.navigate(['/user-management']);
  }

  onUserProfileChange(event: MatSelectChange): void {
    this.idPerfilAcesso = event.value;
    this.enableConcessionaria(this.idPerfilAcesso);
    this.multiple = !(this.idPerfilAcesso === 5 || this.idPerfilAcesso === 13);

    if (this.currentUser.PerfilAcesso !== 'Master' && this.currentUser.PerfilAcesso !== 'Master Representante') {
      this.concessionaria.setValue([]);
      if (this.idPerfilAcesso == 12 || this.idPerfilAcesso == 13) {
        if (this.currentListType == 1) this.getConcessionarias(2);
      } else {
        if (this.currentListType == 2) this.getConcessionarias(1);
      }
    }
  }

  checkHandle(IdConcessionaria: number) {
    if (this.selectedConcessionaria.includes(IdConcessionaria)) {
      this.selectedConcessionaria = this.selectedConcessionaria.filter(item => item !== IdConcessionaria);
    } else {
      this.selectedConcessionaria.push(IdConcessionaria);
    }

    this.idsConcessionarias = this.selectedConcessionaria.slice();

    if (this.selectedConcessionaria.length === this.listaConcessionaria.length) {
      this.checkedAll = true;
    } else {
      this.checkedAll = false;
    }
    this.concessionaria.setValue(this.selectedConcessionaria as any);
  }

  onConcessionariaSelectionChange(concessionariaId: any) {
    if (this.multiple === false) {
      this.idsConcessionarias = [concessionariaId];
    } else {
      this.idsConcessionarias = concessionariaId;
    }
  }

  checkEmailsMatch() {
    if (this.email.value !== this.emailConfirm.value) {
      this.emailConfirm.setErrors({ emailMismatch: true });
    } else {
      this.emailConfirm.setErrors(null);
    }
  }

  openDialogSaveUser = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Salvar alterações do usuário externo?',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.verifyData() },
        secundaryButtonText: 'SIM, SALVAR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogSaveUserSuccess() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Alterações salvas com sucesso.',
        icon: 'success',
        primaryButtonText: 'IR PARA A LISTA DE USUÁRIOS',
        primaryButtonVariant: 'primary',
        primaryButtonAction: () => {
          dialogRef.close();
          this.goToUserList();
        },
      },
    });
  }

  openDialogSaveUserError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Houve um problema ao tentar salvar as alterações do usuário.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogCancel() {
    this.dialog.open(ModalGenericComponent, {
      width: '424px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.goToUserList() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  goToUserList() {
    this.router.navigate(['/user-management']);
  }

  handleAccessPermissions(perfil: number) {
    if (this.currentUser.PerfilAcesso === 'Master' || this.currentUser.PerfilAcesso === 'Master Representante') {
      this.cpf.disable();
      this.email.disable();
      this.emailConfirm.disable();
      this.userProfile.disable();

      if (typeof this.currentUser.Concessionarias === 'string') {
        this.currentUser.Concessionarias = [this.currentUser.Concessionarias]
      }

      const objectJson = this.currentUser.Concessionarias.map((dado: any) => {
        const objetoRetorno = JSON.parse(dado)
        objetoRetorno.idConcessionaria = objetoRetorno.IdConcessionaria;
        delete objetoRetorno.IdConcessionaria;

        objetoRetorno.razaoSocial = objetoRetorno.RazaoSocial;
        delete objetoRetorno.RazaoSocial;

        return objetoRetorno;
      })
      this.listaConcessionaria = [...objectJson]
      return;
    }
    let type = 1;
    if (perfil == 12 || perfil == 13) type = 2;
    this.getConcessionarias(type);
    if (this.currentUser.PerfilAcesso === 'SI-Acessos') {
      this.cpf.disable();
      return;
    }
  }

  getUserData(login: string) {
    this.isLoading = true;
    const page = 1;
    const pageSize = 10;
    this.apiService.getUserEdit(login, page, pageSize).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result.items) {
            this.idUsuario = result.items[0].idUsuario;
            this.idPerfilAcesso = result.items[0].idPerfilAcesso;
            this.fullName.setValue(result.items[0].nome);
            this.cpf.setValue(result.items[0].cpf);
            this.email.setValue(result.items[0].email);
            this.emailConfirm.setValue(result.items[0].email);
            this.userProfile.setValue(result.items[0].idPerfilAcesso);
            this.idsConcessionarias = result.items[0].usuariosConcessionarias.map((item: any) => item.idConcessionaria);
            if (result.items[0].telefones) {
              let telefones = result.items[0].telefones;
              let numeros = telefones?.replace(/\s/g, '').split(',');
              let primeiroNumero = numeros[0].substring(0, 11);
              this.celular.setValue(primeiroNumero);
            }
          }
          this.multiple = !(result.items[0].idPerfilAcesso === 5 || result.items[0].idPerfilAcesso === 13);
          this.handleAccessPermissions(this.idPerfilAcesso);
          this.getPerfilAcesso();
          this.pickConcessionaria()

        }
      });
  }

  enableConcessionaria(value: number) {
    let perfis = this.userProfiles.map((x : any) => x.idPerfilAcesso);

    if (perfis.includes(value))
      this.concessionaria.enable();
    else
      this.concessionaria.disable();
  }

  pickConcessionaria() {
    let concessionariaSeleted: any = this.multiple ? this.idsConcessionarias : this.idsConcessionarias[0];
    this.concessionaria.setValue(concessionariaSeleted);
  }

  verifyDataValue() {
    this.userProfile.markAsTouched();
    this.celular.markAsTouched();
    this.concessionaria.markAsTouched();
    this.fullName.markAsTouched();
    this.cpf.markAsTouched();
    this.emailConfirm.markAsTouched();
    this.email.markAsTouched();
  }

  verifyData() {
    if (this.currentUser.PerfilAcesso === 'Master' || this.currentUser.PerfilAcesso === 'Master Representante') {
      this.celular.markAllAsTouched();
      this.fullName.markAllAsTouched();
      this.concessionaria.markAllAsTouched();
      if (this.celular.valid &&
        this.fullName.valid &&
        this.concessionaria.valid) {
        this.sendRequest()
      }
    } else {
      this.checkEmailsMatch();
      this.fullName.markAllAsTouched();
      this.celular.markAllAsTouched();
      this.email.markAllAsTouched();
      this.emailConfirm.markAllAsTouched();
      this.concessionaria.markAllAsTouched();
      this.userProfile.markAllAsTouched();
      if (this.fullName.valid &&
        this.email.valid &&
        this.celular.valid &&
        this.emailConfirm.valid &&
        this.concessionaria.valid &&
        this.userProfile.valid) {
        this.sendRequest()
      }
    }
  }

  sendRequest() {
    const usuario = {
      idPerfilAcesso: this.userProfile.value,
      idUsuario: this.idUsuario,
      nome: this.fullName.value,
      email: this.email.value,
      telefone: [this.celular.value?.replace(/[^0-9]/g, '')],
      IdsConcessionaria: this.idsConcessionarias
    };
    this.isLoading = true;
    this.apiService
      .patchEditUser(usuario)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if (error.status == 400 || error.status == 404 || error.status == 500) {
            this.openDialogSaveUserError()
            this.isLoading = false;
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openDialogSaveUserSuccess();
        }
      });
  }

  getConcessionarias(type?: number) {
    this.isLoading = true;
    this.apiService.getConcessionaria(undefined, [1], type).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result: any[]) => {
          this.currentListType = type || 1;
          this.isLoading = false;
          this.listaConcessionaria = result.sort((a,b) => (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0));;
          this.idsConcessionarias = this.listaConcessionaria.map((item: any) => item.idConcessionaria);
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  getPerfilAcesso() {
    this.isLoading = true;
    this.apiService.getPerfilAcesso().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result && result.length > 0) {
            this.userProfiles = result.filter((el: { interno: any; }) => el.interno == false);
            this.enableConcessionaria(this.idPerfilAcesso);
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }


}
