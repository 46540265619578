import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, interval } from 'rxjs';
import { ModalGenericComponent } from '../components/modal-generic/modal-generic.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class NewAppVersionTrackerService {
  private versionCheckIntervalSeconds = 60;

  constructor(
    private swUpdate: SwUpdate,
    private zone: NgZone,
    public dialog: MatDialog
  ) {}

  public init() {
    this.watchForUpdates();
  }

  private watchForUpdates(): void {
    if (!this.swUpdate.isEnabled) return;

    console.info(`Version Tracker Initializing`);

    this.zone.runOutsideAngular(() => {
      interval(this.versionCheckIntervalSeconds * 1000).subscribe(() => {
        console.log('Checking for SW Update');
        this.swUpdate
          .checkForUpdate()
          .then((versionFound) => {
            console.log('CHECK RESULT:', versionFound);
          })
          .catch(() => console.log('SW Update check failed?'));
      });
    });

    this.swUpdate.versionUpdates.subscribe((versionEvent) => {
      switch (versionEvent.type) {
        case 'VERSION_DETECTED':
          console.info(`New application version found, beginning download: ${versionEvent.version.hash}`);
          break;
        case 'VERSION_READY':
          console.info(`New app version ready: ${versionEvent.latestVersion.hash}`);
          this.openModalReload();
          break;
      }
    });
  }

  openModalReload() {
    this.dialog.open(ModalGenericComponent, {
        width: '340px',
        disableClose: true,
        hasBackdrop: true,
        data: {
            text: 'Há uma nova atualização. Por favor recarregue a página',
            primaryButtonAction: () => { document.location.reload() },
            primaryButtonText: 'RECARREGAR PÁGINA',
            primaryButtonVariant: 'primary',
      }
    })
  }
}