import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { timeout, catchError, TimeoutError, throwError, Subscription, combineLatest, startWith, skip } from 'rxjs';
import { ModalDeleteMessageComponent } from 'src/app/components/modal-delete-message/modal-delete-message.component';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ModalPreviewDraftComponent } from 'src/app/components/modal-preview-draft/modal-preview-draft.component';
import { ModalPreviewMessageComponent } from 'src/app/components/modal-preview-message/modal-preview-message.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { getTemplateMinuta } from 'src/app/shared/mocks/template-minuta';

interface TipoMinuta {
  idTipoMinuta: number;
  descricao: string;
}

@Component({
  selector: 'yfs-list-draft',
  templateUrl: './list-draft.component.html',
  styleUrls: ['./list-draft.component.scss']
})
export class ListDraftComponent {

  public currentFlow = 'communication'
  public isLoading = false;
  public timeoutError = false;
  public isCardSelected: boolean[] = [false, false];
  public templateList: any[] = [];
  public templates: number = 0;
  public pageSize = 8;
  public pageNumber = 1;
  public showErrorMessage = false;
  public empty = false;
  public emptyDocument = false;
  public templateSelected: any;
  public selectedStatus: number[] = [];
  public status = new FormControl();
  public idsStatus: number[] = [];
  public checkedAllStatus = false;
  public listaStatus: any[] = [
    {
      idStatus: 1,
      nome: "Termo de Adesão"
    },
    {
      idStatus: 2,
      nome: "Termo de Cancelamento"
    },
  ];
  public pageIndex = 0;


  public dataInicialLabel: Date | undefined;
  public dataInicial = new FormControl('', [dateFormat.dateValidator]);
  public dataFinalLabel: Date | undefined;
  public dataFinal = new FormControl('', [dateFormat.dateValidator]);
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public draftOptions: any;

  constructor(private apiService: ApiHttpService,private router: Router, public dialog: MatDialog) {
  }

  ngOnInit(){
    this.getMinutas();

    combineLatest([
      this.dataInicial.valueChanges.pipe(startWith(this.dataInicial.value), skip(1)),
      this.dataFinal.valueChanges.pipe(startWith(this.dataFinal.value), skip(1))
    ]).subscribe(() => {
      this.getMinutas();
    });
  }

  onStatusChange(event: any) {
    this.selectedStatus = event;
    if (this.selectedStatus.length === this.listaStatus.length) {
      this.checkedAllStatus = true;
    } else {
      this.checkedAllStatus = false;
    }
    this.getMinutas();
  }

  toggleCard(index: number) {
    this.isCardSelected = this.isCardSelected.map((_, i) => i === index);

    if (index === 0) {
      this.clearQuery()
      this.getMinutas();
    }
  }

  previewMessage(event: Event, templateSelected: any) {
    event.stopPropagation();
    this.templateSelected = templateSelected;
    this.openModalPreview(templateSelected)
  }

  editMessage(event: Event, templateSelected: any) {
    // remover apos implementação da integração com back end
    templateSelected = getTemplateMinuta();
    event.stopPropagation();
    this.templateSelected = templateSelected;
    this.currentFlow = 'newDraft';
  }

  setPageStart() {
    this.pageIndex = 0;
  }

  goToMessage() {
    this.templateSelected = undefined;
    this.currentFlow = 'newDraft';
  }

  closeNewMessage() {
    this.currentFlow = 'communication'
    this.clearQuery()
    this.getMinutas();
  }

  clearQuery() {
    this.pageNumber = 1;
    this.empty = false;
    this.templateList = []
  }

  getBack() {
    this.router.navigate(['area']);
  }

  moreItens() {
    this.pageNumber = this.pageNumber + 1;
    this.getMinutas();
  }

  selectAllStatus() {
    if (this.selectedStatus.length === this.listaStatus.length) {
      this.selectedStatus = [];
      this.idsStatus = [];
      this.checkedAllStatus = false;
    } else {
      this.selectedStatus = this.listaStatus.map(status => status.idStatus);
      this.idsStatus = this.selectedStatus.slice();
      this.checkedAllStatus = true;
    }
    this.status.setValue(this.selectedStatus);
    this.setPageStart();
  }

  getMinutas() {
    this.templateList = [];

    const dataInicial = this.dataInicial.value || '';
    const dataFinal = this.dataFinal.value || '';
    const tipoMinuta = this.selectedStatus || '';

    this.isLoading = true;

    this.apiService.getTemplatesMinuta('', this.pageNumber, this.pageSize, dataInicial, dataFinal, tipoMinuta)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            this.timeoutError = true;
            return throwError(() => "A requisição demorou muito tempo e foi cancelada.");
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: result => {
          if (!result.items || result.items.length === 0) {
            this.isLoading = false;
            this.emptyDocument = true;
            this.templateList = []
            return;
          }
          if (result?.items && result?.items?.length > 0) {
            this.templateList = [...result.items];
            this.templates = result.totalCount;
            this.isLoading = false;
            this.emptyDocument = false;
          }
        },
        error: error => {
          this.isLoading = false;
          this.showErrorMessage = true;
          if (this.timeoutError === false) {
            console.log(error);
          }
        }
      });
  }

  tiposDeMinuta() {
    this.apiService.getTipoMinuta().subscribe({
      next: (tipos: TipoMinuta[]) => {
        this.draftOptions = tipos.map((tipo) => ({
          value: tipo.idTipoMinuta,
          nome: tipo.descricao,
        }));
      },
      error: (erro) => {
        console.error('Erro ao carregar os tipos de minuta', erro);
      }
    });
  }

  openModalPreview(templateSelected: any) {
    this.dialog.open(ModalPreviewDraftComponent, {
      height: '900px',
      panelClass: 'preview-draft-dialog',
      data: {
        previewContent: templateSelected?.template,
        nomenclatura: templateSelected?.nomenclatura,
        dataVigenciaInicial: templateSelected?.dataInicioVigencia,
        dataVigenciaFinal: templateSelected?.dataFinalVigencia,
        tipoMinuta: templateSelected?.idTipoMinuta,
        openEditTemplate: () => { this.currentFlow = 'newDraft'; }
      }
    })
  }

}
