import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { TipoLiberacaoOption } from '../release-values.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Recarga } from 'src/app/models/release-values-details.interface';

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

@Component({
  selector: 'yfs-release-values-details',
  templateUrl: './release-values-details.component.html',
  styleUrls: ['./release-values-details.component.scss'],
})
export class ReleaseValuesDetailsComponent implements OnInit {
  @Input() releaseValueSelected: any;
  @Output() currentFlowChanged = new EventEmitter<string>();

  public searchString = new FormControl('');
  public currentFlow = 'releaseValues';
  public pageIndex = 0;
  public isLoading = false;
  public hasValue = true;
  public tipoLiberacaoOptions: TipoLiberacaoOption[] | undefined;
  public tipoLiberacaoControl = '';
  public tipoLiberacao = new FormControl('');
  public convertBase64 = 'data:image/png;base64,';
  public awaitData = true;
  public canceladoAntesDosSeteDias = false;
  public dataSource: any;
  public recargas: any;
  public listaRecargas: any;
  public errorMessage: any;

  displayedColumns: string[] = [
    'parcelaPaga',
    'numero',
    'statusSituacaoTitulo',
    'dataVencimento',
    'dataPagamento',
    'valorParcela',
  ];

  constructor(
    public dialog: MatDialog,
    private _liveAnnouncer: LiveAnnouncer,
    private apiService: ApiHttpService
  ) { }

  @ViewChild('releaseDetailTableSort') releaseDetailTableSort = new MatSort();

  ngOnInit() {
    this.releaseValueSelected;
    this.sendRequest();
  }

  ngAfterViewInit() {
    if (this.releaseDetailTableSort) {
      this.releaseDetailTableSort.sortChange.subscribe(sortState => {
        this.announceSortChange(sortState);
      });
    }
  }


  getNome(valor: string) {
    return valor.split('(')[0].trim();
  }

  getCnpj(valor: string) {
    const match = valor?.match(/\(([^)]+)\)/);
    if (match) {
      const cnpjDigits = match[1];
      const cnpjWithMask = cnpjDigits.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5'
      );
      return '(' + cnpjWithMask + ')';
    }
    return '';
  }

  getBack() {
    this.currentFlowChanged.emit('releaseValues');
  }

  announceSortChange(sortState: Sort) {

    const sortedData = this.recargas.data.slice().sort((a: any, b: any) => {
      const isAsc = sortState.direction === 'asc';

      switch (sortState.active) {
        case 'parcelaPaga':
          return compare(a.parcelaPaga, b.parcelaPaga, isAsc);
        case 'numero':
          return compare(a.numero, b.numero, isAsc);
        case 'statusSituacaoTitulo':
          return compare(a.statusSituacaoTitulo, b.statusSituacaoTitulo, isAsc);
        case 'dataVencimento':
          return compare(a.dataVencimento, b.dataVencimento, isAsc);
        case 'dataPagamento':
          return compare(a.dataPagamento, b.dataPagamento, isAsc);
        case 'valorParcela':
          return compare(a.valorParcela, b.valorParcela, isAsc);
        default:
          return 0;
      }
    });

    this.recargas.data = sortedData;

    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  changeCurrentFlow(flow: string) {
    this.currentFlow = flow;
  }

  openModalComplete() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Tem certeza que deseja concluir a liberação?',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => {
          this.completeRelease();
        },
        secundaryButtonText: 'SIM, CONCLUIR LIBERAÇÃO',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openModalSuccess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'A liberação foi feita com sucesso.',
        primaryButtonAction: () => {
          this.getBack();
          this.dialog.closeAll();
        },
        primaryButtonText: 'IR PARA LIBERAÇÃO DE VALORES',
        primaryButtonVariant: 'primary',
        icon: 'success',
      },
    });
  }

  openModalError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não foi possível concluir a liberação. Tente novamente mais tarde.',
        primaryButtonAction: () => {
          this.getBack();
          this.dialog.closeAll();
        },
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
        icon: 'error',
      },
    });
  }

  openModalCancel() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Tem certeza que deseja cancelar esta desistência?',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => {
          this.completeReleaseCancel();
        },
        secundaryButtonText: 'SIM, CANCELAR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openModalCancelSuccess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'A desistência foi cancelada com sucesso.',
        primaryButtonAction: () => {
          this.getBack();
          this.dialog.closeAll();
        },
        primaryButtonText: 'IR PARA LIBERAÇÃO DE VALORES',
        primaryButtonVariant: 'primary',
        icon: 'success',
      },
    });
  }

  openModalCancelError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: this.errorMessage,
        primaryButtonAction: () => {
          this.getBack();
          this.dialog.closeAll();
        },
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
        icon: 'error',
      },
    });
  }


  completeRelease() {
    const numeroContrato = this.releaseValueSelected?.numeroContrato || null;
    this.isLoading = true;
    this.apiService
      .postReleaseValues(numeroContrato)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if (error.status != 500) {
            this.openModalError();
            this.isLoading = false;
          }
          return throwError(error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 200 || result.status === 201) {
            this.openModalSuccess();
            this.isLoading = false;
          } else if (result.status === 204) {
            this.openModalError();
            this.isLoading = false;
          } else if (result.status === 500) {
            this.openModalError();
            this.isLoading = false;
          }
          else {
            this.openModalError();
            this.isLoading = false;
          }
        },
      });
  }

  completeReleaseCancel() {
    const numeroContrato = this.releaseValueSelected?.numeroContrato || null;
    this.isLoading = true;
    this.apiService
      .postRefundCancel(numeroContrato)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              'A requisição demorou muito tempo e foi cancelada.'
            );
          } if (error && error.error && error.error.errors && error.error.errors.NumeroContrato) {
            this.errorMessage = `Não foi possível cancelar a desistência.` + ' ' + error.error.errors.NumeroContrato[0];
            this.openModalCancelError();
          } else {
            this.errorMessage = `Não foi possível cancelar a desistência.`;
            this.openModalCancelError();
          }
          if (error.status != 500) {
            this.openModalCancelError();
            this.isLoading = false;
          }
          return throwError(error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 200 || result.status === 201) {
            this.openModalCancelSuccess();
            this.isLoading = false;
          } else if (result.status === 204) {
            this.openModalCancelError();
            this.isLoading = false;
          } else if (result.status === 500) {
            this.openModalCancelError();
            this.isLoading = false;
          }
          else {
            this.openModalCancelError();
            this.isLoading = false;
          }
        },
      });
  }

  getTipoLiberacao(tipoLiberacao: string) {
    switch (tipoLiberacao) {
      case 'Desistencia_Em_Processo':
        return 'Desistência';
      default:
        return '';
    }
  }



  getIconColor(status: boolean) {
    if (status === true) return 'var(--color-functional-positive-1)';
    else {
      return 'var(--color-functional-danger-2)';
    }
  }

  cpfMask(cpf: string) {
    const cleanedCpf = cpf.replace(/\D/g, '');
    const cpfWithMask = cleanedCpf.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      '$1.$2.$3-$4'
    );
    return cpfWithMask;
  }

  getIcon(status: boolean) {
    switch (status) {
      case false:
        return 'cancel';
      default:
        return 'check_circle';
    }
  }

  sendRequest() {
    const idContrato = this.releaseValueSelected?.numeroContrato || null;
    this.isLoading = true;

    this.apiService
      .getReleaseValuesDetails(idContrato)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 204) {
            this.openModalError();
          } else {
            this.dataSource = result.body;
            this.listaRecargas = this.dataSource.recargas;
            this.canceladoAntesDosSeteDias = this.dataSource.canceladoAntesdosSeteDias;
            this.recargas = new MatTableDataSource<Recarga>(this.listaRecargas);
            this.recargas.sort = this.releaseDetailTableSort;
          }
        },
        error: (error) => {
          this.openModalError();
        },
        complete: () => {
          this.isLoading = false;
          this.recargas.sort = this.releaseDetailTableSort;
        },
      });
  }

  getTermoCancelamento() {
    const payLoad = {
      idContrato: this.releaseValueSelected?.numeroContrato,
      termoAssinado: true,
    };
    this.isLoading = true;
    this.apiService
      .getTermoCancelamento(payLoad)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 204) {
            this.openDialogEmptyDocument()
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.downloadDocument(result.body.termoCancelamento, result.body.nomeArquivo);
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  openDialogEmptyDocument() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'O download deste documento só estará disponível após o upload da versão assinada.',
        icon: 'info',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  downloadDocument(document64: string, nomeDocument: string) {
    const linkSource = `data:application/octet-stream;base64,${document64}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = `${nomeDocument}.pdf`;
    downloadLink.click();
    this.isLoading = false;
  }
}
