import { AbstractControl } from '@angular/forms';

export class dateFormat {

  static  formatDate(date: any): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  static dateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (!value) {
      return null;
    }
    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const matches = value.match(dateRegex);
    if (!matches) {
      return { 'invalidDate': true };
    }
    const day = Number(matches[1]);
    const month = Number(matches[2]) - 1;
    const year = Number(matches[3]);
    const date = new Date(year, month, day);
    if (
      date.getFullYear() !== year ||
      date.getMonth() !== month ||
      date.getDate() !== day
    ) {
      return { 'invalidDate': true };
    }
    return null;
  }

  static yearsValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (!value) {
      return null;
    }

    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const matches = value.match(dateRegex);
    if (!matches) {
      return { 'invalidDate': true };
    }
    const day1 = Number(matches[1]);
    const month2 = Number(matches[2]) - 1;
    const year3 = Number(matches[3]);
    const date = new Date(year3, month2, day1);
    if (
      date.getFullYear() !== year3 ||
      date.getMonth() !== month2 ||
      date.getDate() !== day1
    ) {
      return { 'invalidDate': true };
    }

    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth() + 8, currentDate.getDate());
    const newMaxDate = maxDate.toISOString().substring(0, 10);

    const [dayStr, monthStr, yearStr] = value.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    const newDateObj = dateObj.toISOString().substring(0, 10);

    if(newDateObj > newMaxDate){
      return { 'dataValida': true };
    }
    return null;
  }

  static accountingValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (!value) {
      return null;
    }

    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const matches = value.match(dateRegex);
    if (!matches) {
      return { 'invalidDate': true };
    }
    const day1 = Number(matches[1]);
    const month2 = Number(matches[2]) - 1;
    const year3 = Number(matches[3]);
    const date = new Date(year3, month2, day1);
    if (
      date.getFullYear() !== year3 ||
      date.getMonth() !== month2 ||
      date.getDate() !== day1
    ) {
      return { 'invalidDate': true };
    }

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    const newDate = currentDate.toISOString().substring(0, 10);

    const [dayStr, monthStr, yearStr] = value.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    const newDateObj = dateObj.toISOString().substring(0, 10);

    if(newDate < newDateObj){
      return { 'dataValida': true };
    }
    return null;
    }

    static positionValidator(control: AbstractControl): { [key: string]: boolean } | null {
      const value = control.value;
      if (!value) {
        return null;
      }

      const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const matches = value.match(dateRegex);
      if (!matches) {
        return { 'invalidDate': true };
      }
      const day1 = Number(matches[1]);
      const month2 = Number(matches[2]) - 1;
      const year3 = Number(matches[3]);
      const date = new Date(year3, month2, day1);
      if (
        date.getFullYear() !== year3 ||
        date.getMonth() !== month2 ||
        date.getDate() !== day1
      ) {
        return { 'invalidDate': true };
      }

      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate());
      const newDate = currentDate.toISOString().substring(0, 10);

      const [dayStr, monthStr, yearStr] = value.split('/');
      const day = parseInt(dayStr);
      const month = parseInt(monthStr) - 1;
      const year = parseInt(yearStr);
      const dateObj = new Date(year, month, day);
      const newDateObj = dateObj.toISOString().substring(0, 10);

      if(newDate < newDateObj){
        return { 'dataValida': true };
      }
      return null;
      }

    static commissionsValidate(control: AbstractControl): { [key: string]: boolean } | null {
      const value = control.value;
      if (!value) {
        return null;
      }

      const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const matches = value.match(dateRegex);
      if (!matches) {
        return { 'invalidDate': true };
      }
      const day1 = Number(matches[1]);
      const month2 = Number(matches[2]) - 1;
      const year3 = Number(matches[3]);
      const date = new Date(year3, month2, day1);
      if (
        date.getFullYear() !== year3 ||
        date.getMonth() !== month2 ||
        date.getDate() !== day1
      ) {
        return { 'invalidDate': true };
      }
     return null;
    }

    static parameterTariffValidator(control: AbstractControl): { [key: string]: boolean } | null {
      const value = control.value;
      if (!value) {
        return null;
      }

      const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const matches = value.match(dateRegex);
      if (!matches) {
        return { 'invalidDate': true };
      }
      const day1 = Number(matches[1]);
      const month2 = Number(matches[2]) - 1;
      const year3 = Number(matches[3]);
      const date = new Date(year3, month2, day1);
      if (
        date.getFullYear() !== year3 ||
        date.getMonth() !== month2 ||
        date.getDate() !== day1
      ) {
        return { 'invalidDate': true };
      }

      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      const newDate = currentDate.toISOString().substring(0, 10);

      const [dayStr, monthStr, yearStr] = value.split('/');
      const day = parseInt(dayStr);
      const month = parseInt(monthStr) - 1;
      const year = parseInt(yearStr);
      const dateObj = new Date(year, month, day);
      const newDateObj = dateObj.toISOString().substring(0, 10);

      if(newDate > newDateObj){
        return { 'dataValida': true };
      }
      return null;
    }

    static ineligibilityReport(control: AbstractControl): { [key: string]: boolean } | null {
      const value = control.value;
      if (!value) {
        return null;
      }
    
      const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const matches = value.match(dateRegex);
      if (!matches) {
        return { 'invalidDate': true };
      }
      const day1 = Number(matches[1]);
      const month2 = Number(matches[2]) - 1;
      const year3 = Number(matches[3]);
      const date = new Date(year3, month2, day1);
      if (
        date.getFullYear() !== year3 ||
        date.getMonth() !== month2 ||
        date.getDate() !== day1
      ) {
        return { 'invalidDate': true };
      }
    
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate());
      const ninetyDaysFromNow = new Date(currentDate);
      ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() + 90);
    
      if (date > ninetyDaysFromNow) {
        return { 'invalidDate': true };
      }
    
      return null;
    }

}
