<section>
  <header>
    <div class="mat-toolbar mat-toolbar-multiple-rows">
      <mat-toolbar-row class="mat-toolbar-row .mat-toolbar-single-row first-line">
        <div class="container-content">
          <div class="container-brand">
            <a id="logoLibera" href="/"
              ><img
                src="assets/img/liberacred2x.png"
                class="logo-libera"
                alt="logo liberacred"
            /></a>
          </div>
          <yfs-login *ngIf="!userName" (nomeAtualizado)="atualizarNome($event)" [flowSeller]="flowSeller"></yfs-login>
          <div class="flex-content" *ngIf="userName">
            <label class="user-label">{{ userName }} |</label>
            <button class="menu-label" mat-button [disableRipple]="true" [matMenuTriggerFor]="menu">
              {{role}}
              <mat-icon aria-hidden="false"  aria-label="Ícone de expandir" class="material-symbols-outlined expand-icon" fontIcon="expand_more"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="goToArea()"><span class="mdc-list-item">MINHA ÁREA</span></button>
              <button mat-menu-item (click)="logout()">
                <mat-icon aria-hidden="false" aria-label="Sair" class="material-symbols-outlined mdc-list-item-icon"
                fontIcon="logout"></mat-icon>
                <span class="mdc-list-item">SAIR</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row class="second-line" [ngClass]="{'flow': !isHomeRoute }">
          <div class="container-sub-menu">
          <ul class="links" [ngClass]="{'plan': !isHomeRoute }">
            <li class="plan" *ngIf="!showPlan || !showComunication">
              <a data-test="button-whats-liberacred" id="linkLiberacred" href="#Saiba"> O QUE É O LIBERACRED</a>
              <a data-test="button-questions" id="linkPerguntas" href="#Requisitos"> REQUISITOS</a>
            </li>
            <li class="plan-client" *ngIf="showPlan && !isClienteUser() || showComunication">
              <div *appHasAuthorization="registerAllowedPermissions"><a *ngIf="!isClienteUser()" id="linkContratarPlano" class="link-container" (click)="goToSimulation()">CONTRATAR PLANO</a></div>
              <div *appHasAuthorization="consultAllowedPermissions"><a id="linkPlanos" class="link-container" (click)="goToPlans()">PLANOS CONTRATADOS</a></div>
              <div *appHasAuthorization="comunicationAllowedPermissions"><a id="linkContratarPlano" class="link-container" (click)="goToParameters()">PARÂMETROS TÉCNICOS</a></div>
            </li>
          </ul>
        </div>
      </mat-toolbar-row>
      <yfs-menu *ngIf="showMenu"></yfs-menu>
    </div>
  </header>
</section>
