import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DadosBancarios } from 'src/app/models/contracted-plans.interface';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CnpjValidator } from 'src/app/shared/validators/cnpj-validator';
import { CpfValidator } from 'src/app/shared/validators/cpf-validator';
import { map, Observable, startWith } from 'rxjs';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';

interface IBankInfos {
  codBanco: string,
  nome: string
}


@Component({
  selector: 'yfs-bank-data',
  templateUrl: './bank-data.component.html',
  styleUrls: ['./bank-data.component.scss']
})
export class BankDataComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<any>();
  @Output() bankDataChanged = new EventEmitter<void>();
  @Input() cpfSelected: string | undefined = '';
  @Input() userName: string | undefined = '';
  @Input() concessionariaValue: number | undefined;
  @Input() dadosBancariosData: DadosBancarios | undefined;
  @Input() idContrato: number | undefined;
  @Input() isRefundRateFlow: boolean | undefined;

  public beneficiario = new FormControl(this.getName(), [Validators.required]);
  public cpf = new FormControl(this.getCpf() || '', [Validators.required, CpfValidator.cpf]);
  public cnpj = new FormControl(this.getCpf() || '',  [Validators.required, CnpjValidator.cnpj]);
  public banco = new FormControl('', [Validators.required]);
  public agencia = new FormControl('', [Validators.required]);
  public contaCorrente = new FormControl('', [Validators.required]);
  public contaCorrenteDigito = new FormControl('')
  public options = []
  public filteredOptions: Observable<any> | undefined;
  public isLoading = false;
  public numeroBanco: any;
  public terceiro = "nao";
  public tipoPessoa = "pessoaFisica";
  public tipoConta = "corrente";
  public genericMenssage = "Campo obrigatório"
  public bankName = "";
  public bankCod = "";
  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public agenciaMask = [/\d/, /\d/, /\d/, /\d/, /\d/]
  public contaMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  public contaCorrenteDigitoMask = [/\d/, /\d/]
  public cpfClientTemp = "";
  public userNameClientTemp = "";
  public bancoTemp = "";
  public agenciaTemp = "";
  public contaCorrenteTemp = "";
  public contaCorrenteDigitoTemp = "";
  public tipoContaTemp = "";
  public numeroBancoTemp = "";


  constructor(private apiService: ApiHttpService, public dialog: MatDialog) {
    this.beneficiario.valueChanges.subscribe(value => {
      const apenasLetrasRegex = /^[a-zA-Z\s]+$/;
      if (value && !apenasLetrasRegex.test(value)) {
        this.beneficiario.setValue("", { emitEvent: false });
      }
    });
  }

  ngOnInit() {
    this.populaBanksData()
    this.getBanks()
    this.verifyBankDataInit()
  }

  getBack(event: string, newBankData?: any) {
    if (event === 'redirect') {
      this.currentFlowChanged.emit({action: 'redirect', newBankData: newBankData});
    } else {
      this.currentFlowChanged.emit({action: 'close'});
    }
  }

  verifyAccount() {
    const tipoContaNumero = this.dadosBancariosData?.tipoConta;
    if (tipoContaNumero === 1) {
      this.tipoContaTemp = 'corrente';
    } else if (tipoContaNumero === 2) {
      this.tipoContaTemp = 'poupanca';
    } else {
      this.tipoContaTemp = 'corrente';
    }
  }

  formatarNome() {
    let valor = this.beneficiario.value;
    if (valor) {
      valor = valor.replace(/[^a-zA-ZÀ-ÿ\s]/g, '');
      this.beneficiario.setValue(valor);
    }
  }

  verifyBankDataInit() {
    const isBeneficiaryDisabled = this.terceiro === 'nao' || (this.terceiro === 'sim' && this.tipoPessoa === 'concessionaria');
    const isBankDataDisabled = this.terceiro === 'sim' && this.tipoPessoa === 'concessionaria';

    if (isBeneficiaryDisabled) {
      this.disabledUserInformation();
    } else {
      this.beneficiario.enable();
      this.cpf.enable();
      this.cnpj.enable();
    }

    if (isBankDataDisabled) {
      this.disabledAccount();
    } else {
      this.banco.enable();
      this.agencia.enable();
      this.contaCorrente.enable();
      this.contaCorrenteDigito.enable();
    }
  }

  verifyBankData() {
    const isBeneficiaryDisabled = this.terceiro === 'nao' || (this.terceiro === 'sim' && this.tipoPessoa === 'concessionaria');
    const isBankDataDisabled = this.terceiro === 'sim' && this.tipoPessoa === 'concessionaria';

    if (isBeneficiaryDisabled) {
      this.disabledUserInformation();
      this.clearBeneficiaryFields();
    } else {
      this.beneficiario.enable();
      this.cpf.enable();
      this.cnpj.enable();
      if (this.tipoPessoa === 'pessoaFisica') {
        this.clearBeneficiaryFields();
      }
    }

    if (isBankDataDisabled) {
      this.disabledAccount();
      this.clearBankDataFields();
    } else {
      this.banco.enable();
      this.agencia.enable();
      this.contaCorrente.enable();
      this.contaCorrenteDigito.enable();
      if (this.tipoPessoa !== 'pessoaFisica') {
        this.clearBeneficiaryFields();
        this.clearCPF();
      }
    }
    this.setValuesFromData()
  }

  disabledUserInformation() {
    this.beneficiario.disable();
    this.cpf.disable();
    this.cnpj.disable();
  }

  clearBeneficiaryFields() {
    this.cnpj.setValue('');
  }

  clearCPF() {
    this.cpf.setValue('');
  }

  disabledAccount() {
    this.banco.disable();
    this.agencia.disable();
    this.contaCorrente.disable();
    this.contaCorrenteDigito.disable();
  }

  clearBankDataFields() {
    this.banco.setValue('');
    this.agencia.setValue('');
    this.contaCorrente.setValue('');
    this.contaCorrenteDigito.setValue('');
  }

  recoverData() {
    if (this.tipoPessoa !== 'concessionaria') {
      this.beneficiario.setValue(this.userName);
      this.cpf.setValue(this.cpfSelected || '');
      this.agencia.setValue(this.agenciaTemp);
      this.contaCorrente.setValue(this.contaCorrenteTemp);
      this.contaCorrenteDigito.setValue(this.contaCorrenteDigitoTemp);
      this.banco.setValue(this.bancoTemp);
      this.tipoConta = this.tipoContaTemp;
    }

    this.recovery();
  }

  recovery() {
    if (this.terceiro === 'sim') {
      this.clearBankDataFields();
      if (this.tipoPessoa === 'pessoaFisica' || this.tipoPessoa === 'pessoaJuridica' || this.tipoPessoa === 'concessionaria') {
        this.clearBankDataFields();
        this.tipoPessoa = 'pessoaFisica';
        this.beneficiario.setValue(this.userName);
        this.cpf.setValue(this.cpfSelected || '');
        this.agencia.setValue(this.agenciaTemp);
        this.contaCorrente.setValue(this.contaCorrenteTemp);
        this.contaCorrenteDigito.setValue(this.contaCorrenteDigitoTemp);
        this.banco.setValue(this.bancoTemp);
        this.numeroBanco = this.numeroBancoTemp;
      }
    }
  }

  cleanFields() {
      this.beneficiario.setValue('');
      this.cnpj.setValue('');
      this.cpf.setValue('');
      this.agencia.setValue('');
      this.contaCorrente.setValue('');
      this.contaCorrenteDigito.setValue('');
      this.banco.setValue('');
      this.numeroBanco = '';
  }

  verifyAccountType() {
    if (this.tipoPessoa === 'pessoaFisica' && this.terceiro === 'sim') {
      this.cleanFields();
      this.clearBankDataFields();
    }
    if (this.tipoPessoa === 'pessoaJuridica' && this.terceiro === 'sim') {
      this.cleanFields();
      this.clearBankDataFields();
    }
    if (this.tipoPessoa === 'concessionaria') {
      this.cleanFields();
      this.clearBankDataFields();
    }
  }

  handleConcessionariaClick() {
    if (this.terceiro !== 'nao') {
      this.getConcessionariaValue();
    }
  }

  getCategoriaTerceiroDescription() {
    if (this.tipoPessoa === 'pessoaFisica') return 0
    else if (this.tipoPessoa === 'pessoaJuridica') return 1
    else if (this.tipoPessoa === 'concessionaria') return 2
    return null
  }

  setValuesFromData() {
    const terceiroDescription = this.getCategoriaTerceiroDescription()
    if (this.terceiro === 'sim' &&
    this.dadosBancariosData?.terceiro == true &&
    this.dadosBancariosData?.categoriaTerceiro == terceiroDescription) {
      this.beneficiario.setValue(this.dadosBancariosData?.nomeBeneficiario ? this.dadosBancariosData.nomeBeneficiario : '');
      this.cnpj.setValue(this.dadosBancariosData?.cpfCnpj ? this.dadosBancariosData.cpfCnpj : '');
      this.cpf.setValue(this.dadosBancariosData?.cpfCnpj ? this.dadosBancariosData.cpfCnpj : '');
      this.agencia.setValue(this.dadosBancariosData?.agencia ? this.dadosBancariosData.agencia : '');
      this.contaCorrente.setValue(this.dadosBancariosData?.contaCorrente ? this.dadosBancariosData.contaCorrente : '');
      this.contaCorrenteDigito.setValue(this.dadosBancariosData?.digitoContaCorrente ? this.dadosBancariosData.digitoContaCorrente : '');
      this.banco.setValue(this.dadosBancariosData?.nomeBanco ? this.dadosBancariosData.nomeBanco : '');
      this.numeroBanco = this.dadosBancariosData?.numeroBanco ? this.dadosBancariosData.numeroBanco : '';
    }

    if (this.terceiro === 'nao' &&
    this.dadosBancariosData?.terceiro == true) {
      this.clearBankDataFields();
    };
  }

  getConcessionariaValue() {
    this.isLoading = true;
    this.apiService.getConcessionaria(this.concessionariaValue).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          if (result.status === 500 || result.status === 404) {
            this.isLoading = false;
            this.openModalError();
          }
          this.isLoading = false;
          this.beneficiario = new FormControl(result[0].razaoSocial, [Validators.required]);
          this.cnpj = new FormControl(result[0].cnpj || '', [Validators.required, CnpjValidator.cnpj]);
          this.banco = new FormControl(result[0].dadosBancarios[0].nomeBanco, [Validators.required]);
          this.numeroBanco = new FormControl(result[0].dadosBancarios[0].codBanco, [Validators.required]);
          this.bankCod = result[0].dadosBancarios[0].numeroBanco;
          this.bankName = result[0].dadosBancarios[0].nomeBanco;
          this.agencia = new FormControl(result[0].dadosBancarios[0].agencia, [Validators.required]);
          this.contaCorrente = new FormControl(result[0].dadosBancarios[0].contaCorrente, [Validators.required]);
          this.contaCorrenteDigito = new FormControl(result[0].dadosBancarios[0].digitoContaCorrente)
          this.disabledUserInformation();
          this.disabledAccount();
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
          this.openModalError();
        },
      });
  }

  getBanks() {
    this.isLoading = true;
    this.apiService.getBanks().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.options = result;
        },
        error: (error) => {
          this.isLoading = false;
          this.openModalErrorBanks()
          console.log(error);
        },
      });
  }

  getBankData() {
    this.isLoading = true;
    const verificaCategoria = this.tipoPessoa === 'pessoaFisica' ? 0 : this.tipoPessoa === 'pessoaJuridica' ? 1 : 2;
    const cpfCnpjValue = (this.tipoPessoa === 'pessoaFisica' ? this.cpf.value : this.cnpj.value)?.replace(/[^a-zA-Z0-9]/g, '') || '';
    this.apiService.getBankData(this.idContrato, cpfCnpjValue, verificaCategoria).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result && result.length > 0) {
            this.getBack('redirect', result[0])
          } else {
            this.getBack('redirect', this.dadosBancariosData)
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  populaBanksData() {
    this.cpfClientTemp = this.getCpf() || "";
    this.userNameClientTemp = this.getName() || "";
    this.bancoTemp = this.dadosBancariosData?.nomeBanco || "";
    this.numeroBancoTemp = this.dadosBancariosData?.numeroBanco || "";
    this.agenciaTemp = this.dadosBancariosData?.agencia || "";
    this.contaCorrenteTemp = this.dadosBancariosData?.contaCorrente || "";
    this.contaCorrenteDigitoTemp = this.dadosBancariosData?.digitoContaCorrente || "";
    this.beneficiario = new FormControl(this.getName() || '', [
      Validators.required
    ]);
    this.terceiro = this.dadosBancariosData?.terceiro === true ? 'sim' : 'nao'
    this.agencia = new FormControl(this.dadosBancariosData?.agencia || '', [Validators.required]);
    this.contaCorrente = new FormControl(this.dadosBancariosData?.contaCorrente || '', [Validators.required]);
    this.contaCorrenteDigito = new FormControl(this.dadosBancariosData?.digitoContaCorrente || '');
    this.banco = new FormControl(this.dadosBancariosData?.nomeBanco || '', [Validators.required]);
    this.numeroBanco = this.dadosBancariosData?.numeroBanco;
    this.verifyAccount();
    this.tipoConta = this.tipoContaTemp
    if (this.dadosBancariosData?.categoriaTerceiro) {
      this.tipoPessoa = this.dadosBancariosData.categoriaTerceiro === 0 ? 'pessoaFisica' : this.dadosBancariosData.categoriaTerceiro === 1 ? 'pessoaJuridica' : 'concessionaria'
    }
    if (this.tipoPessoa === 'pessoaFisica') this.cpf = new FormControl(this.getCpf() || '', [Validators.required, CpfValidator.cpf]);
    else this.cnpj = new FormControl(this.getCpf() || '', [Validators.required, CnpjValidator.cnpj]);
    this.filteredOptions = this.banco.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  getCpf() {
    if (this.dadosBancariosData && this.dadosBancariosData.cpfCnpj) return this.dadosBancariosData?.cpfCnpj;
    return this.cpfSelected;
  }

  getName() {
    if (this.dadosBancariosData && this.dadosBancariosData.nomeBeneficiario) return this.dadosBancariosData?.nomeBeneficiario;
    return this.userName;
  }

  clearAndOpenOptions() {
    this.filteredOptions = this.banco.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  verifyCpfCnpj() {
    if((this.tipoPessoa === 'pessoaFisica' && this.terceiro === 'sim' && this.cpf.valid && this.beneficiario.valid && this.banco.valid && this.agencia.valid && this.contaCorrente.valid) || this.tipoPessoa === 'pessoaFisica' && this.beneficiario.valid && this.banco.valid && this.agencia.valid && this.contaCorrente.valid) {
      return true
    }

    if((this.tipoPessoa === 'pessoaFisica' && this.terceiro === 'nao' && this.cpf.value && this.beneficiario.value && this.banco.valid && this.agencia.valid && this.contaCorrente.valid)) {
      return true
    }

    if(this.tipoPessoa === 'pessoaJuridica' && this.beneficiario.valid && this.banco.valid && this.agencia.valid && this.contaCorrente.valid && this.cnpj.valid) {
      return true
    }

    if(this.tipoPessoa === 'concessionaria') {
      return true
    }

    return false
  }

  validateBankDetails() {
    this.beneficiario.markAllAsTouched()
    this.cpf.markAllAsTouched()
    this.cnpj.markAllAsTouched()
    this.banco.markAllAsTouched()
    this.agencia.markAllAsTouched()
    this.contaCorrente.markAllAsTouched()

    const bancoValue = this.banco.value
    const bancoFind = this.options.filter((option: IBankInfos) => option.nome == bancoValue) as any

    if (bancoValue && bancoValue.length > 0 && (!bancoFind || bancoFind.length === 0) && bancoValue !== this.bankName) {
      this.banco.setErrors({ 'invalidBank': true });
    }

    const tipoContaNumero = this.tipoConta === 'corrente' ? 1 : 2;
    const verificaCategoria = this.tipoPessoa === 'pessoaFisica' ? 0 : this.tipoPessoa === 'pessoaJuridica' ? 1 : 2;

    if (this.verifyCpfCnpj()) {
      const bankPayload = {
        idDadosBancarios: 0,
        dataCadastro: '',
        idContrato: this.idContrato,
        terceiro: this.terceiro === 'sim' ? true : false,
        tipoConta: tipoContaNumero,
        numeroBanco: bancoFind[0]?.codBanco || "0" + this.bankCod || '',
        nomeBanco: this.banco.value || this.bankName || '',
        agencia: this.agencia.value || '',
        digitoAgencia: null,
        contaCorrente: this.contaCorrente.value || '',
        digitoContaCorrente: this.contaCorrenteDigito.value || null,
        cpfCnpj: (this.tipoPessoa === 'pessoaFisica' ? this.cpf.value : this.cnpj.value)?.replace(/[^a-zA-Z0-9]/g, '') || '',
        nomeBeneficiario: this.beneficiario.value || '',
        categoriaTerceiro: verificaCategoria,
      }

      if (this.dadosBancariosData) {
        bankPayload.idDadosBancarios = this.dadosBancariosData?.idDadosBancarios
        bankPayload.dataCadastro = this.dadosBancariosData?.dataCadastro
        this.performBankDataAction('update', bankPayload)
      }
      else this.performBankDataAction('add', bankPayload)
    }
  }

  performBankDataAction(action: 'update' | 'add', bankPayload: any) {
    this.isLoading = true;

    const apiObservable = action === 'update'
    ? this.apiService.updateBankData(bankPayload)
    : this.apiService.addBankData(bankPayload);

    apiObservable.pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result: { status: number; }) => {
          if (result.status === 500 || result.status === 404) {
            this.isLoading = false;
            this.openModalErrorUpdate();
          }
          this.isLoading = false;
          if (this.isRefundRateFlow) {
            this.getBankData()
          } else {
            this.bankDataChanged.emit();
            this.openModalSuccess()
          }
        },
        error: (error: any) => {
          this.isLoading = false;
          this.openModalErrorUpdate()
          console.log(error);
        },
      });
  }

  openModalClose() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.getBack('close') },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openModalSave() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Tem certeza que deseja salvar as alterações?',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.validateBankDetails() },
        secundaryButtonText: 'SIM, SALVAR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openModalError() {
    this.dialog.open(ModalGenericComponent, {
      width: '406px',
      data: {
        text: `Não foi possível carregar as informações da concessionária. Por favor, tente novamente mais tarde ou entre em contato com o SAC através dos canais:`,
        secondaryText: `<span> Canal de Voz (CRC): 11 2088-7700 </span><span> WhatsApp: 11 2088-7700 </span><span> E-mail: liberacred@yamaha-motor.com.br </span>`,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'primary',
        icon: 'error',
      }
    })
  }

  openModalErrorUpdate() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: 'Não foi possível alterar os dados nesse momento. Tente novamente mais tarde.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openModalSuccess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: 'Os dados foram alterados com sucesso.',
        primaryButtonAction: () => { this.getBack('close'); this.dialog.closeAll() },
        primaryButtonText: 'VOLTAR PARA PLANOS CONTRATADOS',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openModalErrorBanks() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: 'Não foi possível carregar as informações. Tente novamente mais tarde.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();

    return this.options.filter(
      (option: IBankInfos) => option.nome.toLowerCase().includes(filterValue)
        || option.codBanco.toLowerCase().includes(filterValue)
    );
  }

  formatarCPF() {
    let valor = this.cpf.value;
    if (valor) {
      valor = valor.replace(/\D/g, '');
      if (valor.length > 11) {
        valor = valor.slice(0, 11);
      }
      valor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    }
  }

  formatarCNPJ() {
    let valor = this.cnpj.value;
    if (valor) {
      valor = valor.replace(/\D/g, '');
      if (valor.length > 14) {
        valor = valor.slice(0, 14);
      }
      valor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      this.cnpj.setValue(valor);
    }
  }

}
