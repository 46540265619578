<div class="container-title">
    <div class="button-back">
        <yfs-link-back text="Voltar para listagem" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="page-title-content">
        <h1>{{getTitle()}}</h1>
        <div>
            <yfs-button [state]="isEnbledButton()" label="SELECIONAR TEMPLATE" type="primary" [press]="openDialogTemplate"></yfs-button>
            <yfs-button [state]="isEnbledButton()" label="IMPORTAR ARQUIVO .HTML" type="secondary" icon="upload" [press]="openDialogUpload"></yfs-button>
        </div>
    </div>
</div>

<div class="container-content">
    <mat-card class="card-message">
        <mat-card-content>
            <div class="title-card-content">
                <span class="title-card">Dados da minuta</span>
                <yfs-button state="enabled" label="VISUALIZAR" type="secondary" icon="visibility" size="small"
                    (click)="showContent()"></yfs-button>
            </div>

            <span [ngClass]="{'label-message-required': mensagemInvalid}" class="label-message">Digite aqui o texto da
                minuta*:</span>

            <editor id="editorMessage" [init]="tinymeceSettings"></editor>

            <div class="required-message-container" *ngIf="mensagemInvalid">
                <div class="required-message-bar"></div>
                <span>{{typeErrorMessage === 'required' ? 'Preenchimento obrigatório' : 'O campo deve possuir no mínimo
                    20 caracteres.'}}</span>
            </div>

            <div class="fields-content">
                <div>
                    <mat-form-field id="nomenclatura" appearance="fill">
                        <mat-label>Nomenclatura para armazenamento</mat-label>
                        <input [formControl]="nomenclatura" maxlength="150" matInput>
                        <mat-error *ngIf="nomenclatura.hasError('required')">{{genericMenssage}}</mat-error>
                        <mat-error *ngIf="nomenclatura.hasError('maxlength')">só é permitido 150 caracteres</mat-error>
                    </mat-form-field>

                    <mat-form-field id="tipoMinuta" appearance="fill">
                        <mat-label>Tipo de minuta</mat-label>
                        <mat-select data-test="tipoMinuta" [formControl]="tipoMinuta">
                            <mat-option *ngFor="let tipo of tipoMinutaList" [value]="tipo?.idTipoMinuta">
                                <span class="select-label"> {{tipo?.descricao }}</span>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="tipoMinuta.invalid">
                            {{genericMenssage}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Data de início de vigência</mat-label>
                        <input type="hidden" [matDatepicker]="pickerInicio" [min]="minDate" [disabled]="isEnbledButton() == 'enabled' ? false : true"
                            [(ngModel)]="dataInicialLabel" (ngModelChange)="onInputChangeInicial(true)" />
                        <input matInput [textMask]="{ mask: mask }" [min]="minDate" [formControl]="dataInicial"
                            [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'"
                            (ngModelChange)="onInputChangeInicial(false)" />
                        <mat-datepicker-toggle matIconSuffix [for]="pickerInicio"></mat-datepicker-toggle>
                        <mat-datepicker #pickerInicio></mat-datepicker>
                        <mat-error *ngIf="dataInicial.hasError('required')">
                            Campo obrigatório
                        </mat-error>
                        <mat-error *ngIf="dataInicial.hasError('invalidDate')">Data inválida.</mat-error>
                        <mat-error class="date-error" *ngIf="dataInicial.hasError('dataValida')">Data inválida. Coloque
                            uma
                            data futura.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': dateError}">
                        <mat-label>Data final de vigência</mat-label>
                        <input type="hidden" [matDatepicker]="pickerFinal" [min]="minDate" [(ngModel)]="dataFinalLabel" [disabled]="isEnbledButton() == 'enabled' ? false : true"
                            (ngModelChange)="onInputChangeFinal(true)" />
                        <input matInput [textMask]="{ mask: mask }" [min]="minDate" [formControl]="dataFinal"
                            [ngModel]="dataFinalLabel | date : 'dd/MM/yyyy'"
                            (ngModelChange)="onInputChangeFinal(false)" />
                        <mat-datepicker-toggle matIconSuffix [for]="pickerFinal"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFinal></mat-datepicker>
                        <mat-error *ngIf="dataFinal.hasError('required')">
                            Campo obrigatório
                        </mat-error>
                        <mat-error *ngIf="dataFinal.hasError('invalidDate')">Data inválida.</mat-error>
                        <mat-error class="date-error" *ngIf="dataFinal.hasError('dataValida')">Data inválida. Coloque
                            uma
                            data futura.</mat-error>
                        <mat-error *ngIf="dataFinal.hasError('minValueError')">A data final deve ser maior que a data inicial.</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="card-wilds">
        <mat-card-content>
            <div class="title-card-content">
                <span class="title-card">Wildcards</span>
            </div>
            <span class="subtitle-card">
                Clique no wildcard que deseja inserir na mensagem enquanto digita.
            </span>

            <div class="wildcard-content">
                <div class="wildcard" *ngFor="let wildcard of listWildCards" (click)="insertContent(wildcard?.tag)">
                    {{wildcard?.tag}}</div>
            </div>
        </mat-card-content>
    </mat-card>

</div>
<div class="buttons-container">
    <yfs-button state="enabled" label="CANCELAR" type="secondary" (click)="cancelButtonHandle()"></yfs-button>
    <yfs-button [state]="isEnbledButton()" label="SALVAR" type="primary" [press]="handleSaveButtonClick"></yfs-button>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>