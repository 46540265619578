import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IDataModalSummary } from 'src/app/models/summary-data.interface';

@Component({
  selector: 'yfs-modal-summary',
  templateUrl: './modal-summary.component.html',
  styleUrls: ['./modal-summary.component.scss']
})
export class ModalSummaryComponent {
  public dataSource: any;
  public pontualidade: number | undefined;
  public quitacao: number | undefined;
  public restricao: number | undefined;
  public pontuais = 0;
  public totalPontuais = 0;
  public naoPontuais = 0;

  public displayedColumns2: string[] = [
    'recarga',
    'pontual',
    'dataVencimento',
    'dataPagamento',
    'status'
  ];

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private cdref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ModalSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataModalSummary
  ) { }

  @ViewChild('secondTableSort')
  secondTableSort!: MatSort;

  ngOnInit() {
    this.verifySealEligibility();
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    this.dataSource = this.data;
    this.dataSource = new MatTableDataSource(this.data.values?.parcelas);
    this.dataSource.sort = this.secondTableSort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: any) => {
      if (sortHeaderId === 'status') {
        return data.statusParcelaDescricao;
      }
      return data[sortHeaderId];
    };
    this.recoveryData();
    this.cdref.detectChanges();
  }

  verifySealEligibility() {
    this.pontualidade =  this.data.values?.pontualidade;
    this.quitacao = this.data.values?.quitacao;
    this.restricao = this.data.values?.restricaoCredito;
  }



  closeModal(): void {
    this.dialogRef.close();
  }

  handlePrimaryButton() {
    if (this.data.primaryButtonAction === 'close')
      this.closeModal()
    else {
      this.data.primaryButtonAction()
    }
  }

  announceSortChange2(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getPontualidadeIcon(value: boolean) {
    switch (value) {
      case true:
        return 'check';
      case false:
        return 'close';
      case null:
        return 'check_indeterminate_small';
      default:
        return 'check_indeterminate_small';
    }
  }

  getPontualidadeColor(value: boolean) {
    switch (value) {
      case true:
        return 'var(--color-functional-positive-1)';
      case false:
        return 'var(--color-functional-danger-2)';
      default:
        return 'var(--color-neutral-gray-4)';
    }
  }

  getStatusName(status: string) {
    if (status === 'EmAberto') return 'Em Aberto';
    if (status === 'EmCartorio') return 'Em Cartorio';
    return status;
  }

  recoveryData() {
    this.pontuais = (this.data?.values?.parcelas || []).filter((parcela: any) => parcela.pontual).length;
    this.totalPontuais = (this.data?.values?.parcelas || []).length;
    this.naoPontuais = (this.data?.values?.parcelas || []).filter((parcela: any) => !parcela.pontual).length;
  }
}
