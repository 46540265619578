import { ApplicationRef, Component } from '@angular/core';
import { NewAppVersionTrackerService } from './services/new-version-checker.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'liberaCred-spa';

  constructor(
    // appRef: ApplicationRef,
    private newAppVersionTrackerService: NewAppVersionTrackerService,
  ) {
    this.newAppVersionTrackerService.init();
    // appRef.isStable.pipe(filter((stable) => stable)).subscribe(() => console.log('App is stable now'));
  }
}
