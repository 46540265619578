import { Component } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'yfs-modal-account-generation',
  templateUrl: './modal-account-generation.component.html',
  styleUrls: ['./modal-account-generation.component.scss']
})
export class ModalAccountGenerationComponent {

  constructor(
    public dialogRef: MatDialogRef<ModalAccountGenerationComponent>,
    public dialog: MatDialog
    ){}

  close() {
      this.dialogRef.close();
    }

}
