<div class="menu-content">
  <div [style.height]="heightMenu" class="menu" [style.width]="isMenuVisible ? '379px' : '99px'">
    <div class="card-hidden" *ngIf="isMenuVisible">
      <p>Olá, {{userName}}</p>
      <div class="user" *appHasAuthorization="plansAlowedPermissions" (click)="togglePlans(!showPlans)">
        <span>
          <img alt="icone dos planos" src="assets/svg/icon-plano.svg" />
        </span>
        <h1>Planos </h1><mat-icon *ngIf="!showPlans && isMenuVisible" aria-hidden="false"  aria-label="Ícone de expandir" class="material-symbols-outlined expand" fontIcon="expand_more"></mat-icon>
        <mat-icon *ngIf="showPlans && isMenuVisible" aria-hidden="false"  aria-label="Ícone de retrair expansão" class="material-symbols-outlined expand" fontIcon="expand_less"></mat-icon>
      </div>
      <div *appHasAuthorization="plansAlowedPermissions">
      <div class="plans" *ngIf="showPlans && !verifyClienteUser()">
        <span (click)="goToSimulation()" *appHasAuthorization="registerAllowedPermissions">Contratar Plano</span>
        <span (click)="goToContractsPlans()" *appHasAuthorization="consultAllowedPermissions">Planos Contratados</span>
        <span (click)="goToReleaseValues()" *appHasAuthorization="releaseValuesReportAlowedPermissions">Liberação de valores</span>
      </div>
      <div class="plans" *ngIf="showPlans && verifyClienteUser()">
        <span (click)="goToMyPlan()" *appHasAuthorization="registerAllowedPermissions">Plano contratado</span>
      </div>
    </div>
      <div class="user" *appHasAuthorization="generateReportAlowedPermissions" (click)="toggleReports(!showReport)">
        <span>
          <img alt="icone dos relatórios" src="assets/svg/relatorio.svg" />
        </span>
        <h1>Relatórios</h1><mat-icon *ngIf="!showReport && isMenuVisible" aria-hidden="false" aria-label="Ícone de expandir" class="material-symbols-outlined expand" fontIcon="expand_more"></mat-icon>
        <mat-icon *ngIf="showReport && isMenuVisible" aria-hidden="false"  aria-label="Ícone de retrair expansão" class="material-symbols-outlined expand" fontIcon="expand_less"></mat-icon>
      </div>
      <div *appHasAuthorization="generatePositionReportAlowedPermissions">
      <div class="plans" *ngIf="showReport">
        <span (click)="goToPositionReport()" >Posição Contábil</span>
      </div>
      </div>
      <div *appHasAuthorization="generateComissionsReportAlowedPermissions">
        <div class="plans" *ngIf="showReport">
          <span (click)="goToCommissions()" >Comissões</span>
        </div>
      </div>
      <div *appHasAuthorization="generateReleaseValuesReportAlowedPermissions">
        <div class="plans" *ngIf="showReport">
          <span (click)="goToReleaseValuesReport()" >Liberação de valores</span>
        </div>
      </div>
      <div *appHasAuthorization="generateContractExtractReportAlowedPermissions">
        <div class="plans" *ngIf="showReport">
          <span (click)="goToContractExtractReport()" >Extrato do contrato</span>
        </div>
      </div>
      <div *appHasAuthorization="ineligibilityReportAllowedPermissions">
        <div class="plans" *ngIf="showReport">
          <span (click)="goToIneligibilityReport()" >Inelegibilidade</span>
        </div>
      </div>
      <div class="user" *appHasAuthorization="accountingAlowedPermissions" (click)="toggleAccounting(!showAccounting)">
        <span>
          <img alt="icone dos relatórios" src="assets/svg/contabil.svg" />
        </span>
        <h1>Contábil</h1><mat-icon *ngIf="!showAccounting && isMenuVisible" aria-hidden="false" aria-label="Ícone de expandir" class="material-symbols-outlined expand" fontIcon="expand_more"></mat-icon>
        <mat-icon *ngIf="showAccounting && isMenuVisible" aria-hidden="false" aria-label="Ícone de retrair expansão" class="material-symbols-outlined expand" fontIcon="expand_less"></mat-icon>
      </div>
      <div *appHasAuthorization="accountingAlowedPermissions">
      <div class="plans" *ngIf="showAccounting">
        <span (click)="goToAccounting()" >Gerar Arquivo Contábil</span>
      </div>
      </div>
      <div class="groups" *appHasAuthorization="userAllowedPermissions" (click)="toggleUser(!showUser)">
        <span>
          <mat-icon class="groups-icon">groups</mat-icon>
        </span>
        <h1>Usuários</h1><mat-icon *ngIf="!showUser && isMenuVisible" aria-hidden="false" aria-label="Ícone de expandir" class="material-symbols-outlined expand" fontIcon="expand_more"></mat-icon>
        <mat-icon *ngIf="showUser && isMenuVisible" aria-hidden="false" aria-label="Ícone de retrair expansão" class="material-symbols-outlined expand" fontIcon="expand_less"></mat-icon>
      </div>
      <div *appHasAuthorization="userAllowedPermissions">
      <div class="plans" *ngIf="showUser">
        <span (click)="goToUserList()" >Tabela de usuários</span>
      </div>
      </div>
      <div *appHasAuthorization="addInternalUserPermission">
        <div class="plans" *ngIf="showUser">
          <span (click)="goToRegisterInternalUser()" >Cadastrar usuário Interno</span>
        </div>
        </div>
        <div *appHasAuthorization="addExternalUserPermission">
          <div class="plans" *ngIf="showUser">
            <span (click)="goToRegisterExternalUser()" >{{descriptionAddExternal()}}</span>
          </div>
          </div>
      <div class="user" *appHasAuthorization="technicalParametersAllowedPermissions" (click)="toggleParameters(!showParameters)">
        <span>
          <mat-icon aria-hidden="false" aria-label="icone parâmetros técnicos" class="groups-icon material-symbols-outlined" fontIcon="mail"></mat-icon>
        </span>
        <h1>Parâmetros técnicos</h1><mat-icon *ngIf="!showParameters && isMenuVisible" aria-hidden="false" aria-label="Ícone de expandir" class="material-symbols-outlined expand" fontIcon="expand_more"></mat-icon>
        <mat-icon *ngIf="showParameters && isMenuVisible" aria-hidden="false" aria-label="Ícone de retrair expansão" class="material-symbols-outlined expand" fontIcon="expand_less"></mat-icon>
      </div>
      <div>
        <div *appHasAuthorization="comunicationAllowedPermissions">
          <div class="plans" *ngIf="showParameters">
            <span (click)="goToComunication()" >Comunicação</span>
          </div>
        </div>
        <div *appHasAuthorization="productsAllowedPermissions">
          <div class="plans" *ngIf="showParameters">
            <span (click)="goToProducts()">Produtos</span>
          </div>
        </div>
        <div *appHasAuthorization="parameterTariffAllowedPermissions">
          <div class="plans" *ngIf="showParameters">
            <span (click)="goToParameterCommission()">Cadastrar Comissão</span>
          </div>
        </div>
        <div *appHasAuthorization="parameterTariffAllowedPermissions">
          <div class="plans" *ngIf="showParameters">
            <span (click)="goToParameterTariff()">Cadastrar Tarifas</span>
          </div>
        </div>
        <div *appHasAuthorization="tariffHistoricAllowedPermissions">
          <div class="plans" *ngIf="showParameters">
            <span (click)="goToOldTablesTariff()">Consultar Tarifas</span>
          </div>
        </div>
        <div *appHasAuthorization="tariffHistoricAllowedPermissions">
          <div class="plans" *ngIf="showParameters">
            <span (click)="goToOldTablesCommissions()">Consultar Comissões</span>
          </div>
        </div>
        <div *appHasAuthorization="concessionairesAndPartnersAllowedPermissions">
          <div class="plans" *ngIf="showParameters">
            <span (click)="goToConcessionairesAndPartners()">Concessionárias e parceiros</span>
          </div>
        </div>
        <!-- TODO - Deve ser descomentado na release da sprint 30 -->
        <!-- <div *appHasAuthorization="parameterTariffAllowedPermissions">
          <div class="plans" *ngIf="showParameters">
            <span (click)="goToDraftList()">Minutas</span>
          </div>
        </div> -->
      </div>
    </div>
    <div class="card">
      <div class="icon" (click)="toggleMenu()">
        <p>MENU</p>
        <mat-icon *ngIf="!isMenuVisible" aria-hidden="false" aria-label="Ícone de expandir para direita" class="material-symbols-outlined" fontIcon="chevron_right"></mat-icon>
        <mat-icon *ngIf="isMenuVisible" aria-hidden="false" aria-label="Ícone de retrair para esquerda" class="material-symbols-outlined" fontIcon="chevron_left"></mat-icon>
      </div>
      <div *appHasAuthorization="plansAlowedPermissions">
      <span>
        <img class="icons" src="assets/svg/icon-plano.svg" (click)="toggleMenuPlans(!showPlans)" alt="icone dos planos" *ngIf="!isMenuVisible" />
      </span>
      </div>
      <div *appHasAuthorization="generateReportAlowedPermissions">
      <span>
        <img class="icons" src="assets/svg/relatorio.svg" (click)="toggleMenuReports(!showReport)" alt="icone dos relatorios" *ngIf="!isMenuVisible" />
      </span>
    </div>
    <div *appHasAuthorization="accountingAlowedPermissions">
      <span>
        <img class="icons" src="assets/svg/contabil.svg" (click)="toggleMenuAccounting(!showAccounting)" alt="icone contabil" *ngIf="!isMenuVisible" />
      </span>
    </div>
    <div *appHasAuthorization="userAllowedPermissions">
      <span>
        <mat-icon class="icons" *ngIf="!isMenuVisible" (click)="toggleMenuUser(!showUser)" class="groups-icon">groups</mat-icon >
      </span>
    </div>
    <div *appHasAuthorization="technicalParametersAllowedPermissions">
      <span>
        <mat-icon class="icons" aria-hidden="false" (click)="toggleMenuParameters(!showParameters)" *ngIf="!isMenuVisible" aria-label="icone parâmetros técnicos" class="icon-mail material-symbols-outlined"
        fontIcon="mail"></mat-icon>
      </span>
    </div>
    </div>
  </div>
  <div *ngIf="showOverlay" class="overlay-menu"></div>
</div>
