import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateConvert' })
export class DateConvertPipe implements PipeTransform {
    transform(value: any): string {
        if (!value) return '-'
        const dataOriginal = new Date(value);
        const dia = dataOriginal.getUTCDate();
        const mes = dataOriginal.getUTCMonth() + 1;
        const ano = dataOriginal.getUTCFullYear();
        return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
    }
}