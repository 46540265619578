import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'yfs-modal-send-email',
  templateUrl: './modal-send-email.component.html',
  styleUrls: ['./modal-send-email.component.scss']
})
export class ModalSendEmailComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalSendEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }

  sendEmail() {
    this.closeModal()
    this.data.handleSendEmail()
  }
}
