import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yfs-modal-confirm-deaccreditation',
  templateUrl: './modal-confirm-deaccreditation.component.html',
  styleUrls: ['./modal-confirm-deaccreditation.component.scss']
})
export class ModalConfirmDeaccreditationComponent {
  public confirmString = "";
  public showAlertBox = true;

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmDeaccreditationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }

  handleDescredenciarIdPress = () => {
    this.dialogRef.close()
    this.data.decertifyEvent(this.data?.hasPlan);
  }

}
