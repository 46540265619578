<div class="container-title">
    <div class="button-back">
        <yfs-link-back text="Voltar para plano do cliente" (click)="getBack()"></yfs-link-back>
    </div>
    <h1>ENVIAR COMPROVANTE DE QUITAÇÃO</h1>
</div>
<div class="container-content">
    <mat-card style="width: 536px;">
        <mat-card-content>
            <div style="display: flex; flex-direction: column;">
                <div style="display: flex; flex-direction: column;">
                    <span class="title">{{proofPaymentSent ? 'Comprovante enviado' : 'Envie o arquivo do
                        comprovante:'}}</span>
                    <span *ngIf="!proofPaymentSent" class="subtitle">Somente em formatos PDF, JPG ou PNG de até
                        5mb.</span>
                </div>

                <a *ngIf="!proofPaymentSent" (click)="fileInput.click()">Escolher o arquivo</a>

                <input hidden data-test="input-file" (change)="onFileSelected($event)" #fileInput type="file"
                    accept=".pdf, .jpg, .png" id="file">

                <div *ngIf="fileName" class="file-name-label">
                    <span>{{fileName}}</span>
                    <div class="icons-container">
                        <div (click)="openFileNewTab()">
                            <mat-icon aria-hidden="false" aria-label="Icon" class="view-icon material-symbols-outlined"
                                fontIcon="visibility"></mat-icon>
                            <span>VISUALIZAR</span>
                        </div>
                        <mat-icon *ngIf="!proofPaymentSent" (click)="onRemoveFile()" aria-hidden="false"
                            aria-label="Icon" class="close-icon material-symbols-outlined" fontIcon="close"></mat-icon>
                    </div>
                </div>
                <span data-test="error-message-file-size" *ngIf="fileSizeError || invalidFormat" class="error-message">
                    <img src="assets/svg/atention.svg" alt="Ícone de atenção">
                    {{fileSizeError ? 'O arquivo ultrapassou o tamanho máximo de 5MB' :  'O formato do arquivo é inválido'}}
                </span>

                <mat-form-field *ngIf="showInputCalendar" appearance="fill">
                    <mat-label>Data da regularização</mat-label>
                    <input type="hidden" [matDatepicker]="pickerRegularizacao" [max]="maxDate" [(ngModel)]="dataRegularizacaoLabel"
                        (ngModelChange)="onInputChange(true)" />
                    <input matInput [textMask]="{ mask: mask }" [max]="maxDate" [formControl]="dataRegularizacao"
                        [ngModel]="dataRegularizacaoLabel | date : 'dd/MM/yyyy'"
                        (ngModelChange)="onInputChange(false)" />
                    <mat-datepicker-toggle matIconSuffix [for]="pickerRegularizacao"></mat-datepicker-toggle>
                    <mat-datepicker #pickerRegularizacao></mat-datepicker>
                    <mat-error *ngIf="dataRegularizacao.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                    <mat-error class="date-error" *ngIf="dataRegularizacao.hasError('invalidDate') || dataRegularizacao.hasError('dataValida')">Data
                        inválida.</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="showInputDisabled" class="date-field" appearance="fill">
                    <mat-label>Data da regularização</mat-label>
                    <mat-icon aria-hidden="false" aria-label="Ícone de busca"
                        class="clock-icon material-symbols-outlined" fontIcon="nest_clock_farsight_analog"></mat-icon>
                    <input matInput [ngModel]="dataRegularizacaoDisabled" disabled />
                </mat-form-field>

                <div *ngIf="showButtons">
                    <yfs-button style="margin-right: 24px" data-test="button-send" label="CANCELAR" type="secondary"
                        (click)="openModalClose()" class="btn-send" state="enabled"></yfs-button>
                    <yfs-button data-test="button-send" [label]="proofPaymentSent ? 'SOLICITAR CONSULTA' : 'ENVIAR'"
                        type="primary" [press]="handleUploadFile" class="btn-send"
                        [state]="enabledButton()"></yfs-button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
