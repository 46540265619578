import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';

@Component({
  selector: 'yfs-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss']
})
export class AreaComponent implements OnInit {

  constructor (public dialog: MatDialog) {}

  ngOnInit() {
    if (localStorage.getItem("visibleModalUnauthorized") === 'open') {
      this.openModalUnauthorized()
    }
  }

  openModalUnauthorized() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não é possível visualizar essas informações pois você não tem permissões para acessar essa tela.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    }).afterClosed().subscribe(() => this.closeModalHandle())
  }

  closeModalHandle() {
    localStorage.setItem("visibleModalUnauthorized", 'close')
  }

}
