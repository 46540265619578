import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

interface TitleStyles {
  [key: string]: string;
}

@Component({
  selector: 'yfs-modal-preview-draft',
  templateUrl: './modal-preview-draft.component.html',
  styleUrls: ['./modal-preview-draft.component.scss']
})
export class ModalPreviewDraftComponent {

  public dataInicial = '';
  public dataFinal = ''
  public nomenclatura = ''
  public draftType = new FormControl('');
  public htmlString = '';
  public variaveisUnicas: string[] = []
  public draftOptions: any[] = [
    {
      value: 0,
      nome: "Todos"
    },
    {
      value: 1,
      nome: "Termo de Adesão"
    },
    {
      value: 2,
      nome: "Termo de Cancelamento"
    },
  ]

  tagPStyles = `
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`
  tagPreStyles = `
  display: block;
  font-family: monospace;
  white-space-collapse: preserve;
  text-wrap: nowrap;
  margin: 1em 0px;
  font-size: 13px;
`
  globalTitleStyles = `
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

  titleStyles: TitleStyles = {
    h1: `
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
`,
    h2: `
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
`,
    h3: `
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
`,
    h4: `
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
`,
    h5: `
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
`,
    h6: `
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
`
  };
  transformations = [
    { regex: /<pre/g, replacement: '<span style="' + this.tagPreStyles + '"' },
    { regex: /<\/pre>/g, replacement: '</span>' },
    { regex: /<tr/g, replacement: '<tr style="border: 1px solid gray !important;"' },
    { regex: /<td/g, replacement: '<td style="border: 1px solid gray !important;"' },
    { regex: /<p/g, replacement: '<p style="' + this.tagPStyles + '"' },
    ...Object.keys(this.titleStyles).map(tag => ({
      regex: new RegExp(`<${tag}`, 'g'),
      replacement: `<${tag} style="${this.globalTitleStyles} ${this.titleStyles[tag]}"`
    }))
  ];

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewDraftComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    if (this.data.previewContent) {
      const modifiedContent = this.modifyContent(this.data.previewContent);
      this.htmlString = this.data.previewContent;
      this.data.previewContent = this.sanitizer.bypassSecurityTrustHtml(modifiedContent);
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.scrollToTop()
    }, 150)
    this.regexWildcard();
    this.bidingValues();
  }

  scrollToTop() {
    const modalContent = document.querySelector('.mat-mdc-dialog-content');
    if (modalContent) {
      modalContent.scrollTop = 0;
    }
  }

  convertDateToString(data: any) {
    const dataOriginal = new Date(data);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  bidingValues() {
    this.nomenclatura = this.data?.nomenclatura;
    const dataFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    if (dataFormat.test(this.data?.dataVigenciaInicial)) {
      this.dataInicial = this.data?.dataVigenciaInicial;
    } else {
      const dataInicialFormated = this.data?.dataVigenciaInicial ? this.convertDateToString(this.data?.dataVigenciaInicial) : ''
      this.dataInicial = dataInicialFormated;
    }
    if (dataFormat.test(this.data?.dataVigenciaFinal)) {
      this.dataFinal = this.data?.dataVigenciaFinal;
    } else {
      const dataFinalFormated = this.data?.dataVigenciaFinal ? this.convertDateToString(this.data?.dataVigenciaFinal) : ''
      this.dataFinal = dataFinalFormated;
    }
    this.draftType.disable();
    this.draftType.setValue(this.data?.tipoMinuta);
  }

  regexWildcard() {
    if (this.htmlString) {
      const mensagem = this.htmlString;
      const regex = /\[%[^\]]+%]/g;
      const variaveis = mensagem.match(regex);
      this.variaveisUnicas = [...new Set(variaveis)];
    }
  }

  modifyContent(content: string): string {
    let modifiedContent = content;

    this.transformations.forEach(transformation => {
      modifiedContent = modifiedContent.replace(transformation.regex, transformation.replacement);
    });
    return modifiedContent;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openEditTemplate() {
    this.closeModal()
    this.data.openEditTemplate()
  }

}
