export const environment = {
    production: true,
    reCaptchaSiteKey: "6LcVxU4kAAAAAPCWMevdVBQdBBFptJqEklBPQDya",
    urlApi: "https://apicore.liberacredyamaha.com.br/api",
    urlVideo: "https://saliberacrednew.blob.core.windows.net/bsliberacrednew-hml/liberacred.mp4?sp=r&st=2023-02-24T13:40:37Z&se=2026-12-30T21:40:37Z&spr=https&sv=2021-06-08&sr=b&sig=%2F0tB79GJVUPaM%2BSH%2BLOYmwOmKtSDZ%2FSwpA0mjhdmyPk%3D",
    idleConfiguration: {
        idle: 900,
        timeOut: 1,
        ping: 120
    }
}
