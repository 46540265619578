<section>
  <div class="container-content">
    <div class="go-top-button" (click)="goToTop()">
      <img src="assets/img/arrow-up.png" alt="voltar para o topo" style="margin-right: 8px;" />
      <span>Voltar para o topo</span>
    </div>

    <div style="margin-top: 67px" class="about-content">
      <div style="display: flex;">
        <div style="width: 390px;">
          <p class="title-information">Produto do Banco Yamaha Motor do Brasil S.A</p>
          <p class="description-information">CNPJ: 10.371.492/0001-85</p>
        </div>

        <div>
          <p class="title-information">Av. Magalhães de Castro, 4.800 - Ed. Continental Tower - 7º Andar Cj. 71 e 72</p>
          <p class="description-information">05676-120 - Cidade Jardim - São Paulo - SP</p>
        </div>
      </div>
      <div style="display: flex; margin-top: 24px;">
        <div style= "width: 390px;">
          <p class="title-information">SAC</p>
          <p class="description-information">liberacred@yamaha-motor.com.br</p>
          <p class="description-information">(11) 2088-7700</p>
        </div>
        <div>
          <p class="title-information">Ouvidoria</p>
          <p class="description-information">ouvidoria@yamaha-motor.com.br</p>
          <p class="description-information">0800 774-9000</p>
        </div>
      </div>
    </div>


    <div style="margin-top: 80px; text-align: center;">
      <img src="assets/img/liberacred2x.png" height="15" width="138" alt="logo yamaha serviços financeiros">
      <p class="copyright-information"><span>Copyright</span>&copy;<span> Liberacred. Todos os direitos reservados.</span></p>
    </div>
  </div>
</section>
