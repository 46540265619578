export enum EStatus {
    EM_ABERTO = 0,
    ATIVO = 1,
    ELEGIVEL = 2,
    INELEGIVEL = 3,
    CANCELADO = 4,
    DESISTECIA_FINALIZADO = 5,
    LIBERACAO_VALORES_FINALIZADO = 6,
    FINALIZADO_CDC = 7,
    INATIVO = 8,
    ESTORNO_FINALIZADO = 9,
    DESISTENCIA_EM_PROCESSO = 10,
    LIBERACAO_VALORES_CDC_EM_PROCESSO = 11,
    ESTORNO_EM_PROCESSO = 12,
    FINALIZADO_INATIVO = 13,
    ASSINATURA_PENDENTE_TERMO_ADESAO = 14,
    ASSINATURA_PENDENTE_TERMO_CANCELAMENTO = 15,
    LIBERACAO_VALORES_QUITADO_ELEGIVEL_EM_PROCESSO = 16,
    LIBERACAO_VALORES_QUITADO_INELEGIVEL_EM_PROCESSO = 17
  }
