import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort, Sort } from '@angular/material/sort';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ModalClientDetailComponent } from 'src/app/components/modal-client-detail/modal-client-detail.component';
import { MatDialog } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'yfs-list-ineligibility-report',
  templateUrl: './list-ineligibility-report.component.html',
  styleUrls: ['./list-ineligibility-report.component.scss']
})
export class ListIneligibilityReportComponent implements OnInit {
  @ViewChild('mySel3') select3: MatSelect | undefined;
  @Input() listItens: any | undefined;
  @Input() dataInicio: string | null | undefined;
  @Input() dataFim: string | null | undefined;
  @Input() allSelected: boolean | undefined;
  public searchString = new FormControl('');
  public motivo: FormControl = new FormControl('');
  public checkedAllMotivo = false;
  public interteminateMotivo = false;
  public formatoArquivo = 'pdf';
  public pageSizeOptions = [10, 5, 20];
  public isLoading = false;
  public listContratos: any;
  public hasValue = true;
  public selectedMotivos: string[] = []
  public motivoList = [
    {
      value: 1,
      description: 'Quitação',
    },
    {
      value: 2,
      description: 'Pontualidade',
    },
    {
      value: 3,
      description: 'Restrição de crédito',
    }
  ]
  displayedColumns: string[] = ['canalOriginacao', 'canalRelacionamento', 'numeroContrato', 'nomeCliente', 'cpfCliente', 'dataAtivacaoContrato', 'valorPlano', 'saldoReserva', 'motivo'];
  dataSource: any

  @ViewChild('firstTableSort')
  firstTableSort!: MatSort;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  constructor(private _liveAnnouncer: LiveAnnouncer,
    private customPaginator: MatPaginatorIntl,
    private apiService: ApiHttpService,
    public dialog: MatDialog,
    private userService: UserService
  ) {
    customPaginator.itemsPerPageLabel = "Itens por página";
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>(this.listItens);
    this.dataSource.paginator = this.paginator;
    setTimeout(() => {
      this.dataSource.sort = this.firstTableSort;
    }, 100)
    this.dataSource.filterPredicate = this.createFilterPredicate();
    this.customPaginator.getRangeLabel = this.getRangeLabel
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    const totalPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${totalPages}`;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  createFilterPredicate() {
    return (data: any, filter: any) => {
      const { filtroInput, filtroMotivo } = JSON.parse(filter);

      const inputCondition = !filtroInput || Object.values(data).join(' ').toLowerCase().includes(filtroInput);
      const motivoCondition = filtroMotivo.length === 0 || filtroMotivo.includes(data.motivo.toLowerCase());

      return inputCondition && motivoCondition;
    };
  }

  applyFilter() {
    const filtroInput = this.searchString.value?.trim().toLowerCase();
    const filtroMotivo = this.motivo.value ? this.motivo.value.map((motivo: any) => motivo.toLowerCase()) : [];

    this.dataSource.filter = JSON.stringify({ filtroInput, filtroMotivo });
  }

  selectClientPlan(row: any) {
    this.search(row.idContrato);
  }

  search(contrato: number) {
    this.isLoading = true
    this.apiService.getInelibilityReportDetail(contrato).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.openDialogClientDetail(result)
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  selectAllMotivo() {
    if (this.selectedMotivos.length === this.motivoList.length) {
      this.selectedMotivos = [];
      this.checkedAllMotivo = false;
    } else {
      this.selectedMotivos = this.motivoList.map(motivo => motivo.description);
      this.checkedAllMotivo = true;
    }
    this.motivo.setValue(this.selectedMotivos);
    this.applyFilter();
  }

  checkHandleMotivo(descriptionMotivo: string) {
    if (this.selectedMotivos.includes(descriptionMotivo)) {
      this.selectedMotivos = this.selectedMotivos.filter(item => item !== descriptionMotivo);
    } else {
      this.selectedMotivos.push(descriptionMotivo);
    }

    if (this.selectedMotivos.length === this.motivoList.length) {
      this.checkedAllMotivo = true;
    } else {
      this.checkedAllMotivo = false;
    }
    this.motivo.setValue(this.selectedMotivos);
    this.applyFilter();
  }

  openDialogClientDetail(detail: any) {
    this.dialog.open(ModalClientDetailComponent, {
      width: '1070px',
      maxHeight: '800px',
      data: {
        detail: detail
      }
    })
  }

  handleGenerateFile() {
    if (this.formatoArquivo == 'pdf') {
      this.generatePDF();
    } else {
      this.generateExcel()
    }
  }

  prepareDataForPDF(data: any): any[] {
    if (!data || !data.filteredData || !Array.isArray(data.filteredData)) {
      console.error('Dados inválidos para geração de PDF.');
      return [];
    }

    return data.filteredData.map((element: any) => {
      return [
        element.canalOriginacao || '',
        element.canalRelacionamento || '',
        element.numeroContrato || '',
        element.nomeCliente || '',
        element.cpfCliente ? this.formatCPF(element.cpfCliente.toString()) : '',
        element.dataAtivacaoContrato ? this.formatDate(element.dataAtivacaoContrato) : '',
        element.valorPlano ? this.formatValueCurrency(element.valorPlano) : '',
        element.saldoReserva ? this.formatValueCurrency(element.saldoReserva) : '',
        element.motivo || ''
      ];
    });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  formatValueCurrency(valor: number): string {
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  formatCPF(cpf: string): string {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return cpf;
  }

  generatePDF(): void {
    const userName = this.userService.getCurrentUser().Nome;
    const doc = new jsPDF('l', 'mm','a4');
    const data = this.prepareDataForPDF(this.dataSource);
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();

    doc.setFontSize(10);
    var splitTitle = doc.splitTextToSize(`Relatório de Inelegibilidade - Data: de ${this.dataInicio} a ${this.dataFim}, Canal de Originação: Concessionaria, Canal de Relacionamento: ${this.allSelected ? 'Todos' : 'Apenas selecionados'}, Gerado em: ${formattedDate} Usuário: ${userName}`, 280);
    doc.text(splitTitle, 5, 15);
    doc.setFontSize(12);

    autoTable(doc, {
      margin: { left: 5, right: 5 },
      head: [['canal de originacao', 'canal de relacionamento', 'contrato', 'cliente', 'cpf', 'data do contrato', 'valor do plano', 'saldo da reserva', 'motivo']],
      body: data,
      startY: 25,
      headStyles: { fillColor: 'gray' },
      showHead: "firstPage"
    });

    doc.save('Relatório de Inelegibilidade.pdf');
  }

  generateExcel(): void {
    const preparedData = this.prepareDataForExcel(this.dataSource);
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(preparedData as any);
    const columnWidths = [
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 }
    ];

    ws['!cols'] = columnWidths;

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Relatório de Inelegibilidade');

    XLSX.writeFile(wb, 'Relatório de Inelegibilidade.xlsx');
  }

  prepareDataForExcel(data: any) {
    const userName = this.userService.getCurrentUser()?.Nome || '';
    if (!data || !data.filteredData || !Array.isArray(data.filteredData)) {
      console.error('Dados inválidos para geração de Excel.');
      return [];
    }

    const preparedData = [[]];
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();

    preparedData.push([`Relatório de Inelegibilidade - Data: de ${this.dataInicio} a ${this.dataFim}, Canal de Originação: Concessionaria, Canal de Relacionamento: ${this.allSelected ? 'Todos' : 'Apenas selecionados'}, Gerado em: ${formattedDate} Usuário: ${userName}`] as any);
    preparedData.push([]);
    const headerTitle = ['canal de originacao', 'canal de relacionamento', 'contrato', 'cliente', 'cpf', 'data do contrato', 'valor do plano', 'saldo da reserva', 'motivo'];
    preparedData.push(headerTitle as any);

    data.filteredData.forEach((element: any) => {
      preparedData.push([
        element.canalOriginacao || '',
        element.canalRelacionamento || '',
        element.numeroContrato || '',
        element.nomeCliente || '',
        element.cpfCliente ? this.formatCPF(element.cpfCliente.toString()) : '',
        element.dataAtivacaoContrato ? this.formatDate(element.dataAtivacaoContrato) : '',
        element.valorPlano ? this.formatValueCurrency(element.valorPlano) : '',
        element.saldoReserva ? this.formatValueCurrency(element.saldoReserva) : '',
        element.motivo || ''
      ] as any);
    });

    return preparedData;
  }


}
