import { AbstractControl } from '@angular/forms';

export class CpfValidator {
  static cpf(control: AbstractControl): { [key: string]: any } | null {
    const cpf = control.value;

    if (!cpf) {
      return { cpfInvalid: true };
    }

    const cpfNumeros = cpf.replace(/\D/g, '');

    if (cpfNumeros.length !== 11 || !CpfValidator.validarCPF(cpfNumeros)) {
      return { cpfInvalid: true };
    }

    return null;
  }

  static validarCPF(cpf: string): boolean {
    if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      return false;
    }

    let soma = 0;
    let resto: number;

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  }
}
