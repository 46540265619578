import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'yfs-modal-cancel',
  templateUrl: './modal-cancel.component.html',
  styleUrls: ['./modal-cancel.component.scss']
})
export class ModalCancelComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalCancelComponent> ,
    private router: Router
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearStorage() {
    localStorage.removeItem("documentos")
    localStorage.removeItem("dadosPessoais")
    localStorage.removeItem("endereco")
    localStorage.removeItem("contato")
    this.dialogRef.close()
    this.router.navigate(["home"])
  }
}
