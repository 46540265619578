export enum EStatusRequisicao {
  A_RECEBER = 0,
  PENDENTE_2A_LIBERACAO = 1,
  CANCELADO = 2,
  AUTORIZAR_DIRETORIA = 3,
  ENCERRADA_LIQUIDADA = 4,
  ENVIADA_EBANK_COM_CC = 5,
  DEBITAR_ONLINE_CONJUNTO = 6,
  ERRO_ENVIO_SPB_DEBITADA_ONLINE = 7,
  LIBERADA = 8,
  ENVIADA_EBANK_SEM_CC = 9,
  PENDENTE = 10,
  RECEBIDA_SPB = 11,
  ENVIADA_SPB = 12,
  DEVOLVIDA = 13,
}
