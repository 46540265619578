<mat-card [ngStyle]="{'background-color': bgColor}" class="card-selo">
  <div class="status-content">
    <mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols-outlined">{{ statusIcon }}</mat-icon>
      <span class="status">{{ statusText }}</span>
  </div>
  <div class="info-section">
    <div class="info-content">
      <span class="h1">Pontualidade</span>
      <span class="h2">{{ punctualityText }}</span>
    </div>
    <div class="info-content">
      <span class="h1">Quitação</span>
      <span class="h2">{{ dischargeText }}</span>
    </div>
    <div class="info-content">
      <span class="h1">Restrição</span>
      <span class="h2">{{ restrictionText }}</span>
    </div>
  </div>
  <div class="description" *ngIf="showdetailsButton">
    <div *appHasAuthorization="proofPaymentAlowedPermissions">
      <yfs-button data-test="button-send" label="ENVIAR COMPROVANTE DE QUITAÇÃO" type="primary" class="btn-send"
        *ngIf="showProofPaymentButton" (click)="openProofPayment()" state="enabled" icon="upload" color="white"
        size="small" varTextColor="var(--color-functional-danger-2)">
      </yfs-button>
    </div>
    <div (click)="showDetail()">
      <span class="h1-description">Mostrar detalhes</span>
    </div>
  </div>
</mat-card>