import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'yfs-modal-client-detail',
  templateUrl: './modal-client-detail.component.html',
  styleUrls: ['./modal-client-detail.component.scss']
})
export class ModalClientDetailComponent implements OnInit {
  public contractedPlan: any;
  public dataSource2: any = [];
  public displayedColumns2: string[] = [
    'numero',
    'valor',
    'dataVencimento',
    'dataLimitePagamento',
    'dataPagamento',
    'pontual',
    'status',
  ];

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    public dialogRef: MatDialogRef<ModalClientDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  @ViewChild('secondTableSort')
  secondTableSort!: MatSort;


  ngOnInit() {
    this.dataSource2 = new MatTableDataSource(this.data?.detail?.recargas);
  }

  ngAfterViewInit() {
    this.dataSource2.sort = this.secondTableSort;
    this.dataSource2.sortingDataAccessor = (data: any, sortHeaderId: any) => {
      if (sortHeaderId === 'status') {
        return data.statusParcelaDescricao;
      }
      return data[sortHeaderId];
    };
  }

  announceSortChange2(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getPontualidadeColor(value: boolean) {
    switch (value) {
      case true:
        return 'var(--color-functional-positive-1)';
      case false:
        return 'var(--color-functional-danger-2)';
      default:
        return 'var(--color-neutral-gray-4)';
    }
  }

  getPontualidadeIcon(value: boolean) {
    switch (value) {
      case true:
        return 'check';
      case false:
        return 'close';
      case null:
        return 'check_indeterminate_small';
      default:
        return 'check_indeterminate_small';
    }
  }

  getStatusName(status: string) {
    if (status === 'EmAberto') return 'Em Aberto';
    if (status === 'EmCartorio') return 'Em Cartorio';
    return status;
  }
}
