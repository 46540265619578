import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'yfs-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();
  @Input() idProduct: any;
  public nomeProduto = new FormControl('');
  public situacao = new FormControl('', [Validators.required]);
  public valorReferencia = new FormControl({value: '', disabled: true}, [Validators.required]);
  public valorBase = new FormControl('', [Validators.required]);
  public percentualCapitalizacaoMinimo = new FormControl('', [Validators.required]);
  public percentualCapitalizacaoMaximo = new FormControl('', [Validators.required]);
  public percentualCapitalizacaoStandard = new FormControl('', [Validators.required]);
  public majoracao = new FormControl('', [Validators.required])
  public informacoes = new FormControl('');
  public mtCode = new FormControl('', [Validators.required]);
  public majoracaoFinal = new FormControl({value: '', disabled: true}, [Validators.required])
  public tipoCapitalizacao = 'standard';
  public isDisabledTipoCapitalizacao = false;
  public tipoMajoracao = 'standard';
  public isDisabledButton = false;
  public isDisabledTipoMajoracao = false;
  public isLoading = false;
  public fileSizeError = false;
  public imgUpload = '';
  public product: any;
  public srcResult: any;
  public newImage: any;
  public majoracaoResult: any;
  public showMajoracao: boolean = false
  public capitalizacaoStandardMinimo = ''
  public capitalizacaoStandardMaximo = ''
  public listaStatus: any[] = [
    {
      idStatus: 1,
      nome: "Ativo"
    },
    {
      idStatus: 0,
      nome: "Inativo"
    },
    {
      idStatus: 2,
      nome: "Pendente"
    }
  ]

  constructor(private apiService: ApiHttpService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.nomeProduto.disable()
    this.mtCode.disable()
    this.valorBase.disable()
    this.getProduct();

    this.valorBase.valueChanges.subscribe(() => {
      this.atualizarValorBase();
    });

    this.majoracao.valueChanges.subscribe(() => {
      this.atualizarValorBase();
    });
    this.checkValues()
  }

  disabledFilelds() {
    if (this.situacao.value == '3') {
      this.situacao.disable();
      this.mtCode.disable();
      this.valorBase.disable();
      this.valorReferencia.disable();
      this.isDisabledTipoCapitalizacao = true;
      this.percentualCapitalizacaoMinimo.disable()
      this.percentualCapitalizacaoMaximo.disable()
      this.percentualCapitalizacaoStandard.disable()
      this.isDisabledTipoMajoracao = true;
      this.majoracao.disable();
      this.informacoes.disable();
      this.majoracaoFinal.disable();
      this.isDisabledButton = true;
    }
  }

  atualizarValorBase(): void {
    let valorReferenciaNumerico = parseFloat(this.valorBase.value ?? "0");
    let majoracaoNumerica = parseFloat((this.majoracao.value ?? '').replace('%', '') || "0");
    let majoracaoDecimal = majoracaoNumerica / 100;
    let valorBaseCalculado = valorReferenciaNumerico * (1 + majoracaoDecimal);
    this.valorReferencia.setValue(this.formatValueCurrency(valorBaseCalculado));

    this.exibirCalculo();
  }

  exibirCalculo() {
    if(this.tipoCapitalizacao === "customizado") {
      this.showMajoracao = true;
    }

    let valorReferenciaNumerico = parseFloat(this.valorBase.value ?? "0");
    let majoracaoNumerica = parseFloat((this.majoracao.value ?? '').replace('%', '') || "0");
    let valorAdicionalMajoracao = (majoracaoNumerica / 100) * valorReferenciaNumerico;

    this.majoracaoFinal.setValue(this.formatValueCurrency(valorAdicionalMajoracao));
  }

  getBack() {
    this.currentFlowChanged.emit('product');
  }

  handleRadioClick() {
    if (this.tipoCapitalizacao === 'standard') {
      this.percentualCapitalizacaoStandard.disable();
      const percentualStandard = this.product.percentualCapitalizacaoMinimoStandard + "% a " + this.product.percentualCapitalizacaoMaximoStandard + "%"
      this.capitalizacaoStandardMinimo = this.product.percentualCapitalizacaoMinimoStandard;
      this.capitalizacaoStandardMaximo = this.product.percentualCapitalizacaoMaximoStandard;
      this.percentualCapitalizacaoStandard.setValue(percentualStandard);
    }
  }

  handleRadioMajoracaoClick() {
    if (this.tipoMajoracao === 'standard') {
      this.majoracao.disable();
      this.majoracao.setValue(this.formatMajoracaoValueStantard())
    } else {
      this.majoracao.enable();
      this.majoracao.setValue(this.product.percentualMajoracao ? this.product.percentualMajoracao + "%" : '')
    }
  }

  formatMajoracaoValueStantard(): string {
    const percentualCalculado = (this.product.percentualMajoracaoStandard / 100) * this.product.valorBase;
    const valorFormatado = this.formatValueCurrency(percentualCalculado);

    return `${this.product.percentualMajoracaoStandard}% = ${valorFormatado}`;
  }

  formatValueCurrency(valor: number): string {
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onFileSelected(event: any) {
    const inputNode = event.target;
    const selectedFile = inputNode.files[0];

    if (selectedFile) {
      if (selectedFile.size > 5242880) {
        this.fileSizeError = true;
      } else {
        this.fileSizeError = false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        this.srcResult = reader.result;
        this.imgUpload = this.srcResult.replace("data:application/pdf;base64,", '');
        this.newImage = this.imgUpload;
      };

    }
  }

  onRemoveFile() {
    const inputNode = document.querySelector('input[type="file"]') as HTMLInputElement;
    inputNode.value = null ?? '';
    this.fileSizeError = false;
  }

  handleEditClick() {
    this.situacao.markAllAsTouched();
    this.valorReferencia.markAllAsTouched();
    if (this.tipoCapitalizacao === 'customizado') {
      this.percentualCapitalizacaoMaximo.markAllAsTouched();
      this.percentualCapitalizacaoMinimo.markAllAsTouched();
    }
    if (this.tipoMajoracao === 'customizado') {
      this.majoracao.markAllAsTouched();
    }

    if (this.situacao.valid) {
      let tipoCapitalizacaoValid = true;
      let tipoMajoracaoValid = true;
      if (this.tipoCapitalizacao === 'customizado' && (this.percentualCapitalizacaoMinimo.invalid || this.percentualCapitalizacaoMaximo.invalid)) {
        tipoCapitalizacaoValid = false;
      }
      if (this.tipoMajoracao === 'customizado' && this.majoracao.invalid) {
        tipoMajoracaoValid = false;
      }
      if (tipoCapitalizacaoValid && tipoMajoracaoValid ) {
        this.updateProduct();
      }
    }
  }

  formatPercentualMinimo() {
    let currentValue = this.percentualCapitalizacaoMinimo.value?.replace(/[^0-9]/g, '');
    const defaultCurretValue = currentValue;
    currentValue = currentValue += '%';
    if (currentValue == '%') this.percentualCapitalizacaoMinimo.setValue('')
    else
      this.percentualCapitalizacaoMinimo.setValue(currentValue);
    this.validPercentualMinimo(defaultCurretValue);
  }

  validPercentualMinimo(minimoValue?: string) {
    if (!minimoValue) return;
    const minimoInt = parseInt(minimoValue);
    if (minimoInt < 10 || minimoInt > 100) {
      this.percentualCapitalizacaoMinimo.setErrors({ rangeError: true });
    }
    this.validRangeMaxAndMinValues()
  }

  formatPercentualMaximo() {
    let currentValue = this.percentualCapitalizacaoMaximo.value?.replace(/[^0-9]/g, '');
    const defaultCurretValue = currentValue;
    currentValue = currentValue += '%';
    if (currentValue == '%') this.percentualCapitalizacaoMaximo.setValue('')
    else
      this.percentualCapitalizacaoMaximo.setValue(currentValue);
    this.validPercentualMaximo(defaultCurretValue);
  }

  validPercentualMaximo(maximoValue?: string) {
    if (!maximoValue) return;
    const maximoInt = parseInt(maximoValue);
    if (maximoInt > 90) {
      this.percentualCapitalizacaoMaximo.setErrors({ rangeError: true });
    }
    this.validRangeMaxAndMinValues()
  }

  validRangeMaxAndMinValues() {
    if (!this.percentualCapitalizacaoMinimo.errors && !this.percentualCapitalizacaoMaximo.errors) {
      const valueMin = this.percentualCapitalizacaoMinimo.value?.replace('%', '');
      const valueMinInt = parseInt(valueMin || '');
      const valueMax = this.percentualCapitalizacaoMaximo.value?.replace('%', '');
      const valueMaxInt = parseInt(valueMax || '');
      if (valueMinInt >= valueMaxInt ) {
        this.percentualCapitalizacaoMaximo.setErrors({ minValueError: true })
      }
    }
  }

  formatPercentualMajoracao() {
    let currentValue = this.majoracao.value?.replace(/[^0-9]/g, '');
    const defaultCurretValue = currentValue;
    currentValue = currentValue += '%';
    if (currentValue == '%') this.majoracao.setValue('')
    else
      this.majoracao.setValue(currentValue);
    this.validPercentualMajoracao(defaultCurretValue);
  }

  validPercentualMajoracao(majoracaoValue?: string) {
    if (!majoracaoValue) return;
    const majoracaoInt = parseInt(majoracaoValue);
    if (majoracaoInt > 100) {
      this.majoracao.setErrors({ rangeError: true });
    }
  }

  getProduct() {
    this.isLoading = true;
    this.apiService.getProduct(this.idProduct).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.product = result;
          this.bidingValues();
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  parseValueToNumber(value: any) {
    if (!value || value == undefined || typeof value !== 'string') return value;
    return parseInt(value.replace('%', ''));
  }

  updateProduct() {
    this.isLoading = true;
    if (this.newImage) {
      const imageFragment = this.newImage.split(',')
      this.newImage = imageFragment[1];
    }

    let percentualCapitalizacaoMinimoToSend = null;
    let percentualCapitalizacaoMaximoToSend = null;

    if (this.tipoCapitalizacao === 'standard') {
      percentualCapitalizacaoMinimoToSend = this.capitalizacaoStandardMinimo;
      percentualCapitalizacaoMaximoToSend = this.capitalizacaoStandardMaximo;
    } else if (this.tipoCapitalizacao === 'customizado') {
      percentualCapitalizacaoMinimoToSend = this.percentualCapitalizacaoMinimo.value ? this.parseValueToNumber(this.percentualCapitalizacaoMinimo.value) : null;
      percentualCapitalizacaoMaximoToSend = this.percentualCapitalizacaoMaximo.value ? this.parseValueToNumber(this.percentualCapitalizacaoMaximo.value) : null;
    }

    const productUpdate = {
      idProduto: this.product.idProduto,
      situacao: this.situacao.value,
      valorReferencia: parseFloat((this.valorReferencia.value ?? '').replace(/R\$|\s|\.+/g, '').replace(',', '.')),
      valorBase: this.valorBase.value,
      mtCode: parseFloat(this.mtCode.value || ''),
      percentualMajoracaoTipo: this.tipoMajoracao === 'standard' ? 0 : 1,
      percentualMajoracao: this.tipoMajoracao === 'standard' ? this.product.percentualMajoracaoStandard : this.parseValueToNumber(this.majoracao.value),
      percentualCapitalizacaoTipo: this.tipoCapitalizacao === 'standard' ? 0 : 1,
      percentualCapitalizacaoMinimo: percentualCapitalizacaoMinimoToSend,
      percentualCapitalizacaoMaximo: percentualCapitalizacaoMaximoToSend,
      informacoes: this.informacoes.value,
      imagemBase64: this.newImage
    }
    this.apiService.updateProduct(productUpdate).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.openDialogSaveProductSuccess()
        },
        error: error => {
          this.isLoading = false;
          if (error?.error?.Errors && error?.error?.Errors?.length > 0) {
            this.openDialogErrorValue(error?.error.Errors[0])
          } else
          this.openDialogSaveChangesError()
          console.log(error)
        }
      })
  }

  bidingValues() {
    this.nomeProduto.setValue(this.product.modelo);
    this.situacao.setValue(this.product.situacao);
    this.valorBase.setValue(this.product.valorBase);
    this.valorReferencia.setValue(this.product.valorReferencia);
    this.informacoes.setValue(this.product.informacoes);
    this.mtCode.setValue(this.product.mtCode === 0 ? '' : this.product.mtCode);
    this.imgUpload = `data:image/png;base64, ${this.product.imagemBase64}`;
    this.product.percentualCapitalizacaoTipo === 0 ? this.tipoCapitalizacao = "standard" : this.tipoCapitalizacao = "customizado";
    this.product.percentualMajoracaoTipo === 0 ? this.tipoMajoracao = "standard" : this.tipoMajoracao = "customizado";
    this.percentualCapitalizacaoMinimo.setValue(this.product.percentualCapitalizacaoMinimo ? this.product.percentualCapitalizacaoMinimo + "%" : '');
    this.percentualCapitalizacaoMaximo.setValue(this.product.percentualCapitalizacaoMaximo ? this.product.percentualCapitalizacaoMaximo + "%" : '');
    this.handleRadioClick();
    this.handleRadioMajoracaoClick();
    this.disabledFilelds();
  }

  checkValues(){
    this.nomeProduto.markAsTouched();
    this.situacao.markAsTouched();
    this.valorBase.markAsTouched();
    this.valorReferencia.markAsTouched();
    this.informacoes.markAsTouched();
    this.mtCode.markAsTouched();
    this.percentualCapitalizacaoMinimo.markAllAsTouched();
    this.percentualCapitalizacaoMaximo.markAllAsTouched();
  }

  openDialogSaveChanges = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '400px',
      data: {
        text: 'Você tem certeza de que deseja salvar as alterações?',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.handleEditClick() },
        secundaryButtonText: 'SIM, SALVAR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogSaveProductSuccess() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Os dados foram alterados com sucesso.',
        icon: 'success',
        primaryButtonText: 'VOLTAR PARA LISTA DE PRODUTOS',
        primaryButtonVariant: 'primary',
        primaryButtonAction: () => {
          dialogRef.close();
          this.getBack();
        },
      },
    });
  }

  openDialogSaveChangesError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não foi possível alterar os dados nesse momento. Tente novamente mais tarde?',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.handleEditClick() },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogErrorValue(message: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '390px',
      data: {
        icon: 'error',
        text: message,
        primaryButtonAction: 'close',
        primaryButtonVariant: 'primary',
        primaryButtonText: 'FECHAR'
      }
    })
  }

  openDialogExist() {
    this.dialog.open(ModalGenericComponent, {
      width: '425px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.getBack() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  filtrarNumeros(): void {
    let valorAtual = this.mtCode.value ?? '';
    let valorFiltrado = valorAtual?.replace(/[^0-9]/g, '');
    if (parseInt(valorFiltrado) < 1) {
      valorFiltrado = '';
    }
    if (valorAtual !== valorFiltrado) {
      this.mtCode.setValue(valorFiltrado);
    }
  }

}
