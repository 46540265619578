<div class="dialog-content" mat-dialog-content>
  <mat-icon aria-hidden="false" aria-label="Ícone de expandir para direita" class="material-symbols-outlined" fontIcon="info"></mat-icon>

  <div  class="dialog-text-first">
      <span>Ao clicar em ”Enviar”, essa ação não poderá ser desfeita.</span>
  </div>

  <div  class="dialog-text-second">
    <span>Oriente o cliente à conferir também a caixa de spam caso o e-mail não seja localizado na Caixa de Entrada.</span>
  </div>

  <mat-form-field class="inputs" appearance="fill">
    <input data-test="input-email" [formControl]="email" matInput>
  </mat-form-field>

  <div class="dialog-buttons">
    <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="back()"></yfs-button>
    <yfs-button state="enabled" label="ENVIAR" type="primary" (click)="confirm()"></yfs-button>
  </div>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
