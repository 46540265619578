import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.general';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IJwtDecode, IUser } from '../models/user';
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private loggedInUserSubject: BehaviorSubject<any>;
    // loggedInUser$ = this.loggedInUserSubject.asObservable();

    private urlApi = environment.urlApi
    // private urlApi = "https://localhost:5000/api"

    constructor(
        private http: HttpClient
    ) {
        const user = this.getCurrentUser();
        this.loggedInUserSubject = new BehaviorSubject(user)
     }

    public login(login: string | null, password: string | null): Observable<any> {
        return this.http.post<any>(`${this.urlApi}/v2/usuarios/login`, { login, password }).pipe(
            map(response => {
                if (!response || !response.autenticado) return;
                if (response && response.primeiroAcesso) return response;
                sessionStorage.setItem('token', btoa(JSON.stringify(response.token)))
                const user = this.decodeJwt(response.token)
                sessionStorage.setItem('user', btoa(JSON.stringify(user)))
                if (response.parametros) {
                  sessionStorage.setItem('parametros', JSON.stringify(response.parametros))
                }
                this.setLoggedInUser(user)
                return response;
            }
            ));
    }

    public redefinePassword(redefineParameters: any): Observable<any> {
        return this.http.patch<any>(`${this.urlApi}/v1/usuarios/first-access`, redefineParameters)
    }

    public forgotPassword(cpf: any): Observable<any> {
        return this.http.post<any>(`${this.urlApi}/v1/usuarios/forgot-password`, {cpf})
    }

    public forgotPasswordChange(newPassword: string, validatorKey: string): Observable<any> {
        return this.http.post<any>(`${this.urlApi}/v2/usuarios/change-password`, null, { headers: { newPassword, validatorKey } })
    }

    public forgotPasswordConfirmHash(hash: any): Observable<any> {
        return this.http.get<any>(`${this.urlApi}/v1/usuarios/forgot-password/confirm?Token=${hash}`)
    }

    private decodeJwt(token: string) {
        let user: IUser = {
            idUsuario: '',
            Nome: '',
            Role: '',
            PerfilAcesso: '',
            PermissaoAcesso: [],
            Concessionarias: []
        };
        let jwtDecode: IJwtDecode;
        jwtDecode = jwt_decode(token)
        user.Nome = jwtDecode.unique_name;
        user.idUsuario = jwtDecode.nameid;
        user.PerfilAcesso = jwtDecode.role;
        user.PermissaoAcesso = jwtDecode.PermissaoAcesso;
        user.Concessionarias = jwtDecode.Concessionarias;

        return user
    }

    public logout() {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
        window.location.reload();
        this.loggedInUserSubject.next(null)
    }

    public getCurrentUser(): IUser {
        return sessionStorage.getItem('user')
            ? JSON.parse(atob(sessionStorage.getItem('user') || '{}'))
            : null
    }

    public getParameters(): string {
      return sessionStorage.getItem('parametros')
      ? JSON.parse(atob(sessionStorage.getItem('parametros') || '{}'))
      : null
    }

    public getTokenUser(): string {
        return sessionStorage.getItem('token')
            ? JSON.parse(atob(sessionStorage.getItem('token') || '{}'))
            : null;
    }

    public userIsLogged(): boolean {
        return sessionStorage.getItem('token') ? true : false;
    }

    public setLoggedInUser(user: any) {
        this.loggedInUserSubject.next(user);
    }

    public getLoggedUserObservable() {
        return this.loggedInUserSubject.asObservable();
    }

}
