<div class="dialog-content" mat-dialog-content>
    <img alt="Ícone de atenção" src="assets/svg/warning-icon.svg" />

    <div class="alert-box" *ngIf="data?.hasPlan">
            <mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
                fontIcon="info"></mat-icon>
        <span>Existem carteiras de clientes relacionadas a empresa, será necessário indicar uma nova.</span>
    </div>

    <div class="dialog-text">
        <span>Você tem certeza que deseja descredenciar a empresa? Essa ação não poderá ser desfeita.</span>
    </div>

    <div class="separator"></div>

    <div class="dialog-input-content">
        <span>Digite <strong>DESCREDENCIAR</strong> no campo abaixo para confirmar.</span>
        <mat-form-field appearance="fill">
            <mat-label>Digitar palavra</mat-label>
            <input data-test="input-cancelar-plan" [(ngModel)]="confirmString" matInput>
        </mat-form-field>
    </div>


    <div class="dialog-buttons">
        <yfs-button state="enabled" label="NÃO, VOLTAR" type="secondary" (click)="closeModal()"></yfs-button>
        <yfs-button [state]="confirmString == 'DESCREDENCIAR' ? 'enabled' : 'disabled'" label="SIM, DESCREDENCIAR" type="primary" [press]="handleDescredenciarIdPress"></yfs-button>
    </div>
</div>