<div class="container-title">
  <div class="button-back">
    <yfs-link-back [text]="getButtonDescription()" (click)="getBack()"></yfs-link-back>
  </div>
  <h1>{{getTitle()}}</h1>
</div>
<div class="container-content">
  <mat-card *ngIf="!isEditMode">
    <mat-card-content>
      <button *appHasAuthorization="editAllowedPermissions" class="button-icon-edit" (click)="openEditMode()" mat-mini-fab>
        <mat-icon>edit</mat-icon>
      </button>
      <div>
        <span class="title-document">Documento de identificação</span>
        <div class="content-form">
          <div>
            <span class="bold">CPF</span>
            <span>{{ cpfSelected | cpf }}</span>
          </div>
        </div>
      </div>
      <mat-divider class="separator"></mat-divider>
      <div class="content">
        <span class="title-document">Dados pessoais</span>
        <div class="content-form">
          <div>
            <span class="bold">Nome completo</span>
            <span>{{ userPersonalDatas?.nomeCompleto || "-" }}</span>
          </div>
          <div>
            <span class="bold">Data de nascimento</span>
            <span>{{ userPersonalDatas?.dataNascimento | dateConvert }}</span>
          </div>
          <div>
            <span class="bold">Estado civil</span>
            <span>{{ userPersonalDatas?.estadoCivil || "-" }}</span>
          </div>
          <div>
            <span class="bold">Sexo</span>
            <span>{{ getSexoDescription(userPersonalDatas?.sexo) }}</span>
          </div>
          <div>
            <span class="bold">Nome da mãe</span>
            <span>{{ userPersonalDatas?.nomeMae || "-" }}</span>
          </div>
        </div>
      </div>
      <mat-divider class="separator"></mat-divider>
      <div class="content">
        <span class="title-document">Endereço</span>
        <div class="content-form">
          <div>
            <span class="bold">Rua/Avendida/Praça</span>
            <span>{{ userAddress?.logradouro || "-" }}</span>
          </div>
          <div>
            <span class="bold">Número</span>
            <span>{{ userAddress?.numero || "-" }}</span>
          </div>
          <div>
            <span class="bold">Complemento</span>
            <span>{{ userAddress?.complemento || "-" }}</span>
          </div>
          <div>
            <span class="bold">Bairro</span>
            <span>{{ userAddress?.bairro || "-" }}</span>
          </div>
          <div>
            <span class="bold">Cidade</span>
            <span>{{ userAddress?.cidade || "-" }}</span>
          </div>
          <div>
            <span class="bold">Estado</span>
            <span>{{ userAddress?.estado || "-" }}</span>
          </div>
          <div>
            <span class="bold">CEP</span>
            <span>{{ userAddress?.cep | cep }}</span>
          </div>
        </div>
      </div>
      <mat-divider class="separator"></mat-divider>
      <div class="content">
        <span class="title-document">Dados de contato</span>
        <div class="content-form content-form-last">
          <div>
            <span class="bold">Celular com DDD</span>
            <span>{{ userContact?.celularComDdd | celular }}</span>
          </div>
          <div>
            <span class="bold">Telefone com DDD</span>
            <span>{{ userContact?.telefoneResidencialComDdd | telefone }}</span>
          </div>
          <div>
            <span class="bold">E-mail</span>
            <span>{{ userContact?.email || "-" }}</span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="isEditMode">
    <mat-card-content>
      <div>
        <span class="title-document">Documento de identificação</span>
        <div class="content-form-edit">
          <div>
            <mat-form-field appearance="fill">
              <mat-label>CPF</mat-label>
              <input data-test="input-complement-address-edit" [textMask]="{mask: cpfMask }" [(ngModel)]="cpf" disabled matInput />
            </mat-form-field>
          </div>
        </div>
      </div>
        <div class="content-edit">
          <span class="title-document">Dados pessoais</span>
          <div class="content-form-edit">
            <div class="w-500">
              <mat-form-field appearance="fill">
                <mat-label>Nome completo</mat-label>
                <input
                  data-test="input-name-person-data-edit"
                  [formControl]="nomeCompleto"
                  matInput
                />
                <mat-error *ngIf="nomeCompleto.hasError('required')">
                  O campo é obrigatorio
                </mat-error>
                <mat-error *ngIf="nomeCompleto.hasError('invalidName')"
                  >Nome incompleto</mat-error
                >
              </mat-form-field>
            </div>
            <div style="z-index: 1;">
              <mat-form-field  appearance="fill">
                <mat-label>Data de nascimento</mat-label>
                <input
                  type="hidden"
                  [matDatepicker]="picker"
                  [max]="maxDate"
                  [(ngModel)]="dataNascimentoLabel"
                  (ngModelChange)="onInputChange(true)"
                />
                <input
                  data-test="input-name-born-data-edit"
                  matInput
                  [textMask]="{ mask: mask }"
                  [max]="maxDate"
                  [formControl]="dataNascimento"
                  [ngModel]="dataNascimentoLabel | date : 'dd/MM/yyyy'"
                  (input)="onInputChange(false)"
                />

                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="dataNascimento.hasError('required')"
                  >O campo é obrigatorio</mat-error
                >
                <mat-error *ngIf="dataNascimento.hasError('invalidDate')"
                  >A data é inválida</mat-error
                >
                <mat-error *ngIf="dataNascimento.hasError('dataValida')">
                  Idade não permitida.
                  <yfs-tooltip [tooltipText]="'Somente pessoas que completarão 18 anos dentro de 8 meses podem se cadastrar.'" [wrap]="true">
                    <span class="about-span">Saiba mais</span>
                  </yfs-tooltip>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="test2">
              <mat-form-field appearance="fill">
                <mat-label>Estado civil</mat-label>
                <mat-select
                  data-test="select-civil-edit"
                  [formControl]="estadoCivil"
                >
                  <mat-option
                    *ngFor="let enum of listEstadoCivil"
                    [value]="enum"
                    >{{ enum }}</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="estadoCivil.invalid">
                  O campo é obrigatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field  appearance="fill">
                <mat-label>Sexo</mat-label>
                <mat-select
                  data-test="select-biologic-sex-edit"
                  [formControl]="sexo"
                >
                  <mat-option
                    *ngFor="let enum of listSexo | keyvalue"
                    [value]="enum.key"
                    >{{ enum.value }}</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="sexo.invalid">
                  O campo é obrigatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="w-500">
              <mat-form-field  appearance="fill">
                <mat-label>Nome da mãe</mat-label>
                <input
                  data-test="input-mother-edit"
                  [formControl]="nomeMae"
                  matInput
                />
                <mat-error *ngIf="nomeMae.hasError('required')">
                  O campo é obrigatorio
                </mat-error>
                <mat-error *ngIf="nomeMae.hasError('invalidName')"
                  >Nome incompleto</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="content-edit">
          <span class="title-document">Endereço</span>
          <div class="content-form-edit">
            <div>
              <mat-form-field  appearance="fill">
                <mat-label>CEP</mat-label>
                <input
                  data-test="input-cep-edit"
                  [formControl]="cep"
                  matInput
                  [textMask]="{ mask: cepMask }"
                  (focusout)="validateCep($event)"
                />
                <mat-error *ngIf="cep.hasError('required')">
                  O campo é obrigatorio
                </mat-error>
                <mat-error *ngIf="cep.hasError('invalidCep')"
                  >O CEP é inválido</mat-error
                >
              </mat-form-field>
            </div>
            <div class="w-500">
              <mat-form-field  appearance="fill">
                <mat-label>Rua/Avenida/Praça</mat-label>
                <input
                  data-test="input-street-edit"
                  [formControl]="logradouro"
                  matInput
                />
                <mat-error *ngIf="logradouro.invalid">
                  O campo é obrigatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field  appearance="fill">
                <mat-label>Número</mat-label>
                <input
                  data-test="input-number-address-edit"
                  [formControl]="numero"
                  maxlength="4"
                  matInput
                />
                <mat-error *ngIf="numero.hasError('required')">
                  O campo é obrigatorio
                </mat-error>
                <mat-error *ngIf="numero.hasError('maxlength')">
                  Só é permitido 4 caracteres
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field  appearance="fill">
                <mat-label>Complemento</mat-label>
                <input
                  data-test="input-complement-address-edit"
                  [formControl]="complemento"
                  matInput
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field  appearance="fill">
                <mat-label>Bairro</mat-label>
                <input
                  data-test="input-neighborhood-edit"
                  [formControl]="bairro"
                  maxlength="20"
                  matInput
                />
                <mat-error *ngIf="bairro.hasError('required')">
                  O campo é obrigatorio
                </mat-error>
                <mat-error *ngIf="bairro.hasError('maxlength')">
                  Só é permitido 20 caracteres
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field  appearance="fill">
                <mat-label>Cidade</mat-label>
                <input data-test="input-city-edit" [formControl]="cidade" matInput />
                <mat-error *ngIf="cidade.invalid">
                  O campo é obrigatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field  appearance="fill">
                <mat-label>Estado</mat-label>
                <mat-select data-test="select-uf-edit" [formControl]="estado">
                  <mat-option
                    *ngFor="let enum of listUfs | keyvalue"
                    [value]="enum.key"
                    >{{ enum.key }}</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="estado.invalid">
                  O campo é obrigatorio
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="content-edit">
          <span class="title-document">Dados de contato</span>
          <div class="content-form-edit">
            <div>
              <mat-form-field  appearance="fill">
                <mat-label>Celular com DDD</mat-label>
                <input
                  data-test="input-phone-cell-ddd-edit"
                  [formControl]="celularComDdd"
                  [textMask]="{ mask: celularMask }"
                  (focusout)="validateFone()"
                  matInput
                />
                <mat-error *ngIf="celularComDdd.invalid">
                  O campo é obrigatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="input-telefone" appearance="fill">
                <mat-label>Telefone com DDD</mat-label>
                <input
                  data-test="input-phone-ddd-edit"
                  [formControl]="telefoneResidencialComDdd"
                  [textMask]="{ mask: telefoneMask }"
                  (focusout)="validateFone()"
                  matInput
                />
                <mat-error *ngIf="telefoneResidencialComDdd.invalid">
                  O campo é obrigatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="w-500">
              <mat-form-field  appearance="fill">
                <mat-label>E-mail</mat-label>
                <input data-test="input-email-edit" [formControl]="email" matInput />
                <mat-error
                  *ngIf="email.hasError('email') && !email.hasError('required')"
                >
                  Insira um e-mail válido
                </mat-error>
                <mat-error *ngIf="email.invalid && email.hasError('required')">
                  O campo é obrigatorio
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="container-buttons">
          <yfs-button state="enabled" label="CANCELAR" type="secondary" (click)="cancelButtonHandle()"></yfs-button>
          <yfs-button [state]="!nomeCompleto.valid || !dataNascimento.valid || !estadoCivil.valid || !sexo.valid || !nomeMae.valid || !cep.valid || !logradouro.valid || !numero.valid || !complemento.valid || !bairro.valid || !cidade.valid || !estado.valid || !celularComDdd.valid || !telefoneResidencialComDdd.valid || !email.valid ? 'disabled' : 'enabled'" [press]="saveButtonHandle" label="SALVAR" type="primary"></yfs-button>
        </div>
    </mat-card-content>
  </mat-card>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
