<div class="dialog-content" mat-dialog-content>

  <div class="dialog-text">
      <span>Confirme o e-mail cadastrado</span>
  </div>

  <mat-form-field class="inputs" appearance="fill">
    <mat-label>Email</mat-label>
    <input data-test="input-email" [formControl]="email" matInput maxlength="50">
    <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
      Insira um e-mail valido
    </mat-error>
    <mat-error *ngIf="email.invalid && email.hasError('required')">
      O campo é obrigatorio
  </mat-error>
  </mat-form-field>

  <div class="dialog-buttons">
      <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="back()"></yfs-button>
      <yfs-button state="enabled" [label]="buttonLabel" type="primary" [state]="email.hasError('email') || email.hasError('required') ? 'disabled' : 'enabled'" [press]="onButtonClick"></yfs-button>
      <yfs-button state="enabled" label="PRÓXIMO" type="primary" *ngIf="!isEditMode" (click)="next()"></yfs-button>
  </div>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
