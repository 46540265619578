import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalCancelComponent } from 'src/app/components/modal-cancel/modal-cancel.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { NavigationExtras, Router } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserService } from 'src/app/services/user.service';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';

@Component({
  selector: 'yfs-register-concessionaire',
  templateUrl: './register-concessionaire.component.html',
  styleUrls: ['./register-concessionaire.component.scss']
})
export class RegisterConcessionaireComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();
  public listaConcessionarias: any;
  public concessionarias = new FormControl('', Validators.required);
  public selectedConcessionaria: any = null;
  public currentFlow = 'concessionaire';
  public timeoutError = false;
  public genericError = false;
  public isLoading = false;
  public concessionariaSelecionadaId: any;
  public representantes: any[] = [];
  public selectedRepresentante: any;
  public listRepresentantes: any[] = [];
  public listConcessionarias: any[] = [];
  public selectedTestRepresentante: any;
  public selectedTestConcessionaria: any;
  public selectedId: number | null = null;
  public concessionariaControl = new FormControl();
  public isMaster: boolean = false;
  public nomeConcessionaria: string = '';
  public razaoSocialOrigem: string = '';
  public isRepresentanteInicial: any;

  constructor(private apiService: ApiHttpService, public dialog: MatDialog, private router: Router, public userService: UserService) {}

  ngOnInit() {
    this.loadRepresentantesAndConcessionarias();
    this.loadFromLocalStorage();

  }

  loadFromLocalStorage() {
    const storedData = localStorage.getItem('concessionaria');
    if (storedData) {
      const concessionariaData = JSON.parse(atob(storedData));
      this.selectedId = concessionariaData.idConcessionaria;
      this.nomeConcessionaria = concessionariaData.razaoSocial;
      this.concessionariaControl.setValue(this.nomeConcessionaria);

      this.isRepresentanteInicial = !!this.listRepresentantes.find(rep => rep.razaoSocial === this.nomeConcessionaria);
    }
}

  verifyUser() {
    const perfil = this.userService.getCurrentUser().PerfilAcesso;
    const concessionariaData = this.userService.getCurrentUser().Concessionarias;
    this.isMaster = (perfil === "Master");

    if (perfil === "Vendedor") {
      this.concessionariaControl.disable()
      let concessionariasFormatadas = [];
      if (Array.isArray(concessionariaData)) {
        concessionariasFormatadas = concessionariaData.map((obj: any) => {
          return {
            idConcessionaria: obj.IdConcessionaria,
            razaoSocial: obj.RazaoSocial
          };
        });
      } else {
        const obj = JSON.parse(concessionariaData);
        concessionariasFormatadas.push({
          idConcessionaria: obj.IdConcessionaria,
          razaoSocial: obj.RazaoSocial
        });
      }
      this.listaConcessionarias = concessionariasFormatadas;
      this.concessionarias = new FormControl(this.listaConcessionarias[0]);
      this.concessionarias.disable();

      if (!this.selectedId) {
        this.selectedId = this.listaConcessionarias[0].idConcessionaria;
        this.concessionariaControl.setValue(this.listaConcessionarias[0].razaoSocial);
      }

      this.nomeConcessionaria = this.listaConcessionarias[0].razaoSocial;
      return this.listaConcessionarias[0];
    }

    if (perfil === "Vendedor" || perfil === "Vendedor Representante" || perfil === "Master Representante") {
      let concessionariasFormatadas = [];
      if (Array.isArray(concessionariaData)) {
          concessionariasFormatadas = concessionariaData.map((obj: any) => {
              return {
                  idConcessionaria: obj.IdConcessionaria,
                  razaoSocial: obj.RazaoSocial
              };
          });
      } else {
          const obj = JSON.parse(concessionariaData);
          concessionariasFormatadas.push({
              idConcessionaria: obj.IdConcessionaria,
              razaoSocial: obj.RazaoSocial
          });
      }
      this.listaConcessionarias = concessionariasFormatadas;
      this.concessionarias = new FormControl(this.listaConcessionarias[0]);
      this.razaoSocialOrigem = this.listaConcessionarias[0].razaoSocial;

      if (!this.selectedId) {
          this.selectedId = this.listaConcessionarias[0].idConcessionaria;
          this.concessionariaControl.setValue(this.listaConcessionarias[0].razaoSocial);
      }

      this.nomeConcessionaria = this.listaConcessionarias[0].razaoSocial;
      this.isRepresentanteInicial = !!this.listRepresentantes.find(rep => rep.razaoSocial === this.nomeConcessionaria);

      return this.listaConcessionarias[0];
  }

    if (perfil === "Master" ) {
      let concessionariasFormatadas = [];
      if (Array.isArray(concessionariaData)) {
        concessionariasFormatadas = concessionariaData.map((obj: any) => {
          return {
            idConcessionaria: obj.IdConcessionaria,
            razaoSocial: obj.RazaoSocial
          };
        });
      } else {
        const obj = JSON.parse(concessionariaData);
        concessionariasFormatadas.push({
          idConcessionaria: obj.IdConcessionaria,
          razaoSocial: obj.RazaoSocial
        });
      }
      this.listaConcessionarias = concessionariasFormatadas;
      this.concessionarias = new FormControl(this.listaConcessionarias[0]);

      if (!this.selectedId) {
        this.selectedId = this.listaConcessionarias[0].idConcessionaria;
        this.concessionariaControl.setValue(this.listaConcessionarias[0].razaoSocial);
      }
      this.nomeConcessionaria = this.listaConcessionarias[0].razaoSocial;
      return this.listaConcessionarias[0];
    }

    if (perfil === "Produtos" ) {
      let concessionariasFormatadas = [];
      if (Array.isArray(concessionariaData)) {
        concessionariasFormatadas = concessionariaData.map((obj: any) => {
          return {
            idConcessionaria: obj.IdConcessionaria,
            razaoSocial: obj.RazaoSocial
          };
        });
      } else {
        const obj = JSON.parse(concessionariaData);
        concessionariasFormatadas.push({
          idConcessionaria: obj.IdConcessionaria,
          razaoSocial: obj.RazaoSocial
        });
      }
      this.listaConcessionarias = concessionariasFormatadas;
      this.concessionarias = new FormControl(this.listaConcessionarias[0]);

      if (!this.selectedId) {
        this.selectedId = this.listaConcessionarias[0].idConcessionaria;
        this.concessionariaControl.setValue(this.listaConcessionarias[0].razaoSocial);
      }
      this.nomeConcessionaria = this.listaConcessionarias[0].razaoSocial;
      return this.listaConcessionarias[0];
    }
    return null;
  }

  displayFn(concessionaria: any): string {
    return concessionaria ? concessionaria.razaoSocial : '';
  }

  onSelection(event: any) {
    const selectedValue = event.option.value;
    const selectedRepresentante = this.listRepresentantes.find(rep => rep.razaoSocial === selectedValue);
    const selectedConcessionaria = this.listConcessionarias.find(con => con.razaoSocial === selectedValue);
    const perfil = this.userService.getCurrentUser().PerfilAcesso;

    const isChangingToConcessionaria = this.isRepresentanteInicial && selectedConcessionaria;

    if ((perfil === "Parceiro" || perfil === "Vendedor Representante") && isChangingToConcessionaria) {
        this.openDialogError(selectedRepresentante, selectedConcessionaria, selectedValue);
    } else {
        this.updateSelection(selectedRepresentante, selectedConcessionaria, selectedValue);
    }
}

updateSelection(selectedRepresentante: any, selectedConcessionaria: any, selectedValue: string) {
    if (selectedRepresentante) {
        this.selectedId = selectedRepresentante.idConcessionaria;
        this.isRepresentanteInicial = true;
    } else if (selectedConcessionaria) {
        this.selectedId = selectedConcessionaria.idConcessionaria;
        this.isRepresentanteInicial = false;
    } else {
        this.selectedId = null;
    }

    this.concessionariaControl.setValue(selectedValue);
}

  loadRepresentantesAndConcessionarias() {
    this.isLoading = true;
    if (this.listRepresentantes.length === 0 || this.listConcessionarias.length === 0) {
        this.apiService.getCanalRelecionamento().subscribe(
            (result: any) => {
                if (result) {
                  this.listRepresentantes = result.representantes;
                  this.listConcessionarias = result.concessionarias;

                    const selectedConcessionaria = this.verifyUser();
                    if (selectedConcessionaria && !this.selectedId) {
                        this.selectedId = selectedConcessionaria.idConcessionaria;
                        this.concessionariaControl.setValue(selectedConcessionaria.razaoSocial);
                    }
                    this.isLoading = false
                }
            },
            (error) => {
                this.isLoading = false
                console.error('Erro ao obter representantes e concessionárias', error);
            }
        );
    }
}

  onConcessionariaSelected(event: MatAutocompleteSelectedEvent) {
    const concessionaria = event.option.value;
    this.concessionarias.setValue(concessionaria);
  }

  clearAndOpenOptions() {
    this.loadConcessionarias();
  }

  loadConcessionarias() {
    if (this.listaConcessionarias.length === 0) {
     this.verifyUser();
    }
  }

  displayConcessionaria(concessionaria: any): string {
    return concessionaria ? concessionaria.razaoSocial : '';
  }

  goBack() {
    const navigationExtras: NavigationExtras = {
      state: {
        flow: 'productView',
      },
    };
    if (this.currentFlow === 'concessionaire') {
      this.router.navigate(['/simulation'], navigationExtras);
    }
  }

  openDialog() {
    this.dialog.open(ModalCancelComponent, {
      width: '381px'
    });
  }

  nextStep = () => {
    if (this.concessionarias.disabled) {
      this.proceedToNextStep();
    }

    if (this.concessionarias.valid && this.concessionarias.value !== null) {
      this.proceedToNextStep();
    }
  }

  proceedToNextStep() {
    const concessionariaData = {
      idConcessionaria: this.selectedId,
      razaoSocial: this.concessionariaControl.value,
    };
    localStorage.setItem("concessionaria", btoa(JSON.stringify(concessionariaData)));
    this.currentFlowChanged.emit("document");
  }

  openDialogError(selectedRepresentante: any, selectedConcessionaria: any, selectedValue: string) {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
        width: '620px',
        data: {
            icon: 'warning',
            secondaryText: `<span><b>Atenção!</b> Ao realizar a modificação de canal de relacionamento, as ações de pós venda do plano não poderão ser realizadas pelo canal atual<br> ${this.razaoSocialOrigem}.<br>
            <strong>Você não poderá consultar o plano após o fechamento do processo de venda. Tem certeza em seguir com a mudança?</strong></span>`,
            primaryButtonAction: 'close',
            primaryButtonText: 'VOLTAR',
            primaryButtonVariant: 'secondary',
            secundaryButtonText: 'SIM, ALTERAR CANAL DE RELACIONAMENTO',
            secundaryButtonVariant: 'primary',
            secundaryButtonAction: () => { dialogRef.close('confirm'); }
        },
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm') {
            this.updateSelection(selectedRepresentante, selectedConcessionaria, selectedValue);
        } else {
            this.concessionariaControl.setValue(this.nomeConcessionaria);
        }
    });
}
}
