import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { FormControl, Validators } from '@angular/forms';

interface IRelationBackFlow {
  [key: string]: string;
}

@Component({
  selector: 'yfs-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.scss']
})

export class SimulationComponent implements OnInit {
  public invalidName = false;
  public invalidCheck = false;
  public token = "";
  public name = new FormControl("", [Validators.required]);
  public siteKey = environment.reCaptchaSiteKey;
  public currentFlow = "start";
  public relationBackFlow: IRelationBackFlow = { start: "home", category: "start", product: "category", productView: 'product' }
  public productsList: any;
  public idProduct = 0;
  public isLoading = false;
  public retrie = 500;
  public timeoutError = false;
  public RETRIELIMIT = 30000;
  public recaptchaReady = false;
  public back = '';
  public role = '';

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef, private userService: UserService) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      const state = navigation?.extras.state as { flow: string };
      if(state.flow){
        this.currentFlow = state.flow;
      }
    }
  }

  ngOnInit() {
    const user = this.userService.getCurrentUser();
    if (user) {
      this.role = user.PerfilAcesso;
    }
    this.verifyRole();
    if (this.currentFlow === "start" && !this.isLogged()) {
      this.verifyRecapcha();
    }
  }

  verifyRole() {
    if (this.role === 'Vendedor' || this.role === 'Master' || this.role === 'Vendedor Representante' || this.role === 'Master Representante' || this.role === 'Produtos') {
      this.currentFlow = "category";
      this.back = "Voltar para a página inicial";
      this.token = "";
    } else {
      this.currentFlow = "start";
      this.verifyFlow();
    }
  }

  verifyFlow() {
    switch (this.currentFlow) {
      case 'start':
        this.back = "Voltar para a página inicial";
        break;
      case "category":
        this.back = "Voltar para inserção do nome";
        break;
      case "product":
        this.back = "Voltar para a seleção de categoria";
        break;
      case "productView":
        this.back = "Voltar para a seleção de produto";
        break;
      default:
        this.back = "Voltar para a página inicial";
        break;
    }
  }

  verifyRecapcha() {
    this.isLoading = true;
    if ((window as any)['grecaptcha']) {
      this.verifyRecapchaIsReady()
    } else {
      this.handleRetrieRecapcha(() => this.verifyRecapcha())
    }
  }

  verifyRecapchaIsReady() {
    (window as any).grecaptcha.ready(() => {
      this.isLoading = false;
      this.recaptchaReady = true;
      this.changeDetectorRef.detectChanges()
    })
    if (!this.recaptchaReady) {
      this.handleRetrieRecapcha(() => this.verifyRecapchaIsReady())
    }
  }

  handleRetrieRecapcha(func: () => void) {
    if (this.retrie <= this.RETRIELIMIT) {
      setTimeout(() => {
        this.retrie += 500;
        func()
      }, 500);
    } else {
      this.isLoading = false;
      this.timeoutError = true;
    }
  }

  nameIsInvalid(): boolean {
    const value = this.name.value;
    if (value === null || value.trim() === "") {
      this.name.setValue("");
      this.name.markAsTouched();
      return true;
    }
    return false;
  }

  submitForm() {
    this.invalidName = this.nameIsInvalid();
    this.invalidCheck = !this.isLogged() && this.token.length === 0;

    if (!this.invalidName && !this.invalidCheck) {
      this.currentFlow = "category"
      this.token = ""
    }
    this.verifyFlow();
  }

  changeCurrentFlow(event: { flow: string, productsList: any }) {
    this.currentFlow = event.flow;
    this.productsList = event.productsList;
    this.verifyFlow();
  }

  changeCurrentFlowProduct(event: { flow: string, idProduct: number }) {
    this.idProduct = event.idProduct;
    this.currentFlow = event.flow;
    this.verifyFlow();
  }

  getBackHandle() {
    if (this.currentFlow === "start") {
      this.router.navigate(["home"]);
    } else if (this.currentFlow === "category" && (this.role === 'Vendedor' || this.role === 'Master' || this.role === 'Vendedor Representante' || this.role === 'Master Representante')) {
      this.router.navigate(["home"]);
    } else if (this.currentFlow === "product" && (this.role === 'Vendedor' || this.role === 'Master' || this.role === 'Vendedor Representante' || this.role === 'Master Representante')) {
      this.currentFlow = "category";
      this.back = "Voltar para a página inicial";
      this.token = "";
    } else {
      const flowToGo = this.relationBackFlow[this.currentFlow];
      this.currentFlow = flowToGo;
      this.verifyFlow();
    }
    if (this.currentFlow === "start" && !this.isLogged()) {
      this.verifyRecapcha();
    }
  }

  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.token = captchaResponse
      this.invalidCheck = false;
    } else {
      this.token = "";
      this.invalidCheck = true;
    }
  }

  isLogged() {
    return this.userService.userIsLogged();
  }
}
