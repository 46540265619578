import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'yfs-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent {
  
  constructor (private router: Router) {}

  goToHome() {
    this.router.navigate(['home'])
  }

}
