import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalCancelComponent } from 'src/app/components/modal-cancel/modal-cancel.component';
import { IPersonalData } from 'src/app/models/register.interface';
import { dateFormat } from 'src/app/shared/date-format';
import { EEstadoCivil } from 'src/app/shared/enums/estado-civil-enum';

import { ESexo } from 'src/app/shared/enums/sexo-enum';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';



@Component({
  selector: 'yfs-register-personal-data',
  templateUrl: './register-personal-data.component.html',
  styleUrls: ['./register-personal-data.component.scss']
})
export class RegisterPersonalDataComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();

  public nomeCompleto = new FormControl('', [Validators.required, LocalValidators.nomeValidator, Validators.maxLength(120)]);
  public dataNascimento = new FormControl('', [Validators.required, dateFormat.yearsValidator]);
  public estadoCivil = new FormControl('', [Validators.required]);
  public sexo = new FormControl('', [Validators.required]);
  public nomeMae = new FormControl('', [Validators.required, LocalValidators.nomeValidator, Validators.maxLength(120)]);
  public timeoutError = false;
  public genericError = false;
  public isLoading = false;
  public listSexo = ESexo;
  public listEstadoCivil = Object.values(EEstadoCivil);
  public dataLabel = "";
  public maxDate = '';
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public dataValida = true;
  public shiftPressed = false;

  constructor(public dialog: MatDialog) {
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth() + 8, currentDate.getDate());
    this.maxDate = maxDate.toISOString().substring(0, 10);
  }

  ngOnInit() {
    if (localStorage.getItem("dadosPessoais")) {
      let dadosPessoais: IPersonalData;
      dadosPessoais = JSON.parse(atob(localStorage.getItem("dadosPessoais") || '{}'))
      this.bidingValuesPersonal(dadosPessoais)
    }
  }

  goBack() {
    this.currentFlowChanged.emit("document")
  }

  bidingValuesPersonal(personalData: IPersonalData) {
    this.nomeCompleto.setValue(personalData.nomeCompleto ?? '')
    this.dataNascimento.setValue(personalData.dataNascimento ?? '');
    this.estadoCivil.setValue(personalData.estadoCivil ?? '');
    this.sexo.setValue(personalData.sexo ?? '');
    this.nomeMae.setValue(personalData.nomeMae ?? '');
    const dataNascimentoFormated = personalData.dataNascimento ? this.convertDateToString(personalData.dataNascimento) : ''
    this.dataLabel = personalData.dataNascimento ? this.convertDataLabel(dataNascimentoFormated) : '';
    this.dataNascimento.setValue(dataNascimentoFormated);
  }

  convertDataLabel(dateString: any) {
    const [dayStr, monthStr, yearStr] = dateString.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    return dateObj.toString()
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }

  convertDateToString(dataNascimento: any) {
    const dataOriginal = new Date(dataNascimento);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  nextStep() {
    this.nomeCompleto.markAllAsTouched()
    this.dataNascimento.markAllAsTouched()
    this.estadoCivil.markAllAsTouched()
    this.sexo.markAllAsTouched()
    this.nomeMae.markAllAsTouched()

    if (this.nomeCompleto.valid && this.dataNascimento.valid &&
      this.sexo.valid && this.nomeMae.valid) {

      const dataNascimentoValue = this.dataNascimento.value;
      const dataNascimentoFormated = dataNascimentoValue ? this.convertStringToDate(dataNascimentoValue) : undefined

      let dadosPessoais: IPersonalData = {
        nomeCompleto: this.nomeCompleto.value ?? undefined,
        dataNascimento: dataNascimentoFormated,
        estadoCivil: this.estadoCivil.value ?? undefined,
        sexo: this.sexo.value ?? undefined,
        nomeMae: this.nomeMae.value ?? undefined
      };

      localStorage.setItem("dadosPessoais", btoa(JSON.stringify(dadosPessoais)))
      this.currentFlowChanged.emit("address")
    }
  }

  openDialog() {
    this.dialog.open(ModalCancelComponent, {
      width: '381px'
    })
  }

  preventInvalidCharacters(event: KeyboardEvent): void {
    if (event.key === 'Shift') {
      this.shiftPressed = true;
    }

    const isSpecialCharacter = this.shiftPressed && /[!@#$%^&*(){}':"\\|<>?]/.test(event.key) || /[,.;/\[\]]/.test(event.key);

    if (/[0-9]/.test(event.key) || isSpecialCharacter) {
      event.preventDefault();
    }
  }

  handleKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Shift') {
      this.shiftPressed = false;
    }
  }

  handlePaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text') || '';
    const sanitizedText = pastedText.replace(/[0-9!@#$%^&*()[\]{};':"\\|,.<>/?]/g, '');
    this.nomeCompleto.setValue(sanitizedText);
  }

}


