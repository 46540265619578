<yfs-header></yfs-header>
<section class="container-content">
  <div class="button-back">
    <yfs-link-back text="Voltar para tabela de usuários" (click)="getBackHandle()"></yfs-link-back>
  </div>
  <div class="container-title">
    <h1>EDITAR USUÁRIO INTERNO</h1>
  </div>
  <mat-card>
    <mat-card-content>
      <div class="content">
        <mat-form-field class="inputs" appearance="fill">
          <mat-label>Matrícula</mat-label>
          <input data-test="input-name" [formControl]="registration" matInput (input)="sanitizeInput(registration)">
          <mat-error *ngIf="registration.invalid">
            O campo é obrigatório
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputs" appearance="fill">
          <mat-label>Perfil do usuário</mat-label>
          <mat-select data-test="select-userProfile" [formControl]="userProfile">
            <mat-option *ngFor="let item of userProfiles" [value]="item.idPerfilAcesso">{{item.nome}}</mat-option>
          </mat-select>
          <mat-error *ngIf="userProfile.invalid">
            O campo é obrigatório
          </mat-error>
        </mat-form-field>
        <mat-form-field class="name-input" appearance="fill">
          <mat-label>Nome completo</mat-label>
          <input data-test="input-name" [formControl]="fullName" matInput maxlength="100">
          <mat-error *ngIf="fullName.hasError('required')">
            O campo é obrigatório
          </mat-error>
          <mat-error *ngIf="fullName.hasError('maxlength')">
            O limite de caracteres é de 100
          </mat-error>
          <mat-error *ngIf="fullName.hasError('invalidName')">Nome incompleto</mat-error>
        </mat-form-field>
        <mat-form-field class="inputs" appearance="fill">
          <mat-label>E-mail</mat-label>
          <input data-test="input-email" [formControl]="email" matInput maxlength="50">
          <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
            Insira um e-mail válido
          </mat-error>
          <mat-error *ngIf="email.invalid && email.hasError('required')">
            O campo é obrigatorio
          </mat-error>
          <mat-error *ngIf="email.hasError('maxlength')">
            O limite de caracteres é de 50
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputs" appearance="fill">
          <mat-label>Repetir o e-mail</mat-label>
          <input data-test="input-email-confirm" [formControl]="emailConfirm" matInput maxlength="50">
          <mat-error *ngIf="emailConfirm.hasError('emailConfirm') && !emailConfirm.hasError('required')">
            Insira um e-mail válido
          </mat-error>
          <mat-error *ngIf="emailConfirm.invalid && emailConfirm.hasError('required')">
            O campo é obrigatorio
          </mat-error>
          <mat-error *ngIf="emailConfirm.hasError('maxlength')">
            O limite de caracteres é de 50
          </mat-error>
        </mat-form-field>
      </div>
      <div class="container-buttons">
        <div class="content-buttons">
        <yfs-button state="enabled" label="CANCELAR" type="secondary" (click)="openDialogCancel()"></yfs-button>
        <yfs-button state="enabled" label="SALVAR" type="primary" (click)="openDialogSaveUser()"></yfs-button>
      </div>
      </div>
    </mat-card-content>
  </mat-card>
</section>
<yfs-alert-box *ngIf="timeoutError || genericError"></yfs-alert-box>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
