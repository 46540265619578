import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { EEstadoCivil } from 'src/app/shared/enums/estado-civil-enum';
import { EUfs } from 'src/app/shared/enums/profile-access.enum';
import { ESexo } from 'src/app/shared/enums/sexo-enum';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { IAddress, IContact, IPersonalData, IRegister } from 'src/app/models/register.interface';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { MatDialog } from '@angular/material/dialog';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';

@Component({
  selector: 'yfs-client-data',
  templateUrl: './client-data.component.html',
  styleUrls: ['./client-data.component.scss']
})
export class ClientDataComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();
  @Input() cpfSelected: string | undefined;
  @Input() origin: string | undefined;

  public cpf = "";
  public nomeCompleto = new FormControl('', [Validators.required, LocalValidators.nomeValidator]);
  public dataNascimento = new FormControl('', [Validators.required, dateFormat.yearsValidator]);
  public estadoCivil = new FormControl('', [Validators.required]);
  public sexo = new FormControl('', [Validators.required]);
  public nomeMae = new FormControl('', [Validators.required, LocalValidators.nomeValidator]);
  public estado = new FormControl('', [Validators.required]);
  public cep = new FormControl('', [Validators.required, LocalValidators.cepValidator]);
  public cidade = new FormControl('', [Validators.required]);
  public bairro = new FormControl('', [Validators.required, Validators.maxLength(20)]);
  public logradouro = new FormControl('', [Validators.required]);
  public numero = new FormControl('', [Validators.required, Validators.maxLength(4)]);
  public complemento = new FormControl('');
  public celularComDdd = new FormControl('', [Validators.required]);
  public telefoneResidencialComDdd = new FormControl('', [Validators.required]);
  public email = new FormControl('', [Validators.required, Validators.email]);
  public dataNascimentoLabel: Date | undefined;;
  public maxDate = '';
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  public celularMask = ['(', /\d/, /\d/, ')', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public telefoneMask = ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public listEstadoCivil = Object.values(EEstadoCivil);
  public listSexo = ESexo;
  public listUfs = EUfs;
  public userRegister: IRegister | undefined
  public userPersonalDatas: IPersonalData | undefined
  public userAddress: IAddress | undefined
  public userContact: IContact | undefined
  public clientData: any

  public formGroup = new FormGroup({
    nomeCompleto: this.nomeCompleto,
    dataNascimento: this.dataNascimento,
    sexo: this.sexo,
    estadoCivil: this.estadoCivil,
    nomeMae: this.nomeMae,
    estado: this.estado,
    cep: this.cep,
    cidade: this.cidade,
    bairro: this.bairro,
    logradouro: this.logradouro,
    numero: this.numero,
    complemento: this.complemento,
    celularComDdd: this.celularComDdd,
    telefoneResidencialComDdd: this.telefoneResidencialComDdd,
    email: this.email
  })

  public isEditMode = false;
  public isLoading = false;
  public editAllowedPermissions = [EPermissaoAcesso.MANTER_PLANO]
  public validCep = true;

  constructor(private apiService: ApiHttpService, public dialog: MatDialog) {
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth() + 8, currentDate.getDate());
    this.maxDate = maxDate.toISOString().substring(0, 10);
  }

  ngOnInit() {
    this.getUser()
  }

  getBack() {
    if (this.isEditMode) {
      this.isEditMode = false;
    }
    else {
      this.currentFlowChanged.emit('contractedPlans');
    }
  }

  getSexoDescription(sexo?: string) {
    if (sexo === "M") return "Masculino"
    if (sexo === "F") return "Feminino"
    if (sexo === "O") return "Outro"
    return "-"
  }

  getButtonDescription() {
    return this.isEditMode
      ? 'Voltar para dados do cliente'
      : this.origin === 'contractedPlans' ? 'Voltar para planos contratados' : 'Voltar para plano do cliente'
  }

  getTitle() {
    return this.isEditMode ? 'EDITAR DADOS DO CLIENTE' : 'DADOS DO CLIENTE'
  }

  openEditMode() {
    this.isEditMode = true;
  }

  onInputChange(isCalendar: boolean) {
    if (this.dataNascimento.value?.length === 0) {
      this.dataNascimentoLabel = undefined
    }

    if (this.dataNascimento && this.dataNascimento.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataNascimento2 = this.dataNascimento.value
        ? this.getDateByString(this.dataNascimento.value)
        : null;

      if (!isCalendar) {
        this.dataNascimentoLabel = dataNascimento2 || undefined
      }
    }

  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  convertDataLabel(dateString: any) {
    const [dayStr, monthStr, yearStr] = dateString.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    return dateObj
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }

  convertDateToString(dataNascimento: any) {
    const dataOriginal = new Date(dataNascimento);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  bidingValuesAddress(endereco: IAddress) {
    this.estado.setValue(endereco.estado ?? endereco.uf ?? '')
    this.cep.setValue(endereco.cep ?? '');
    this.cidade.setValue(endereco.cidade ?? '');
    this.bairro.setValue(endereco.bairro ?? '');
    this.logradouro.setValue(endereco.logradouro ?? '');
    this.numero.setValue(endereco.numero ?? '');
    this.complemento.setValue(endereco.complemento ?? '');
  }

  bidingValuesPersonal(personalData: IPersonalData) {
    this.nomeCompleto.setValue(personalData.nomeCompleto ?? '')
    this.dataNascimento.setValue(personalData.dataNascimento ?? '');
    this.estadoCivil.setValue(personalData.estadoCivil ? personalData.estadoCivil.toUpperCase() : '');
    this.sexo.setValue(personalData.sexo ?? '');
    this.nomeMae.setValue(personalData.nomeMae ?? '');
    const dataNascimentoFormated = personalData.dataNascimento ? this.convertDateToString(personalData.dataNascimento) : ''
    this.dataNascimentoLabel = personalData.dataNascimento ? this.convertDataLabel(dataNascimentoFormated) : undefined;
    this.dataNascimento.setValue(dataNascimentoFormated);
  }

  bidingValuesContact(contato: IContact) {
    this.celularComDdd.setValue(contato.celularComDdd ? contato.celularComDdd.toString() : null)
    this.telefoneResidencialComDdd.setValue(contato.telefoneResidencialComDdd ? contato.telefoneResidencialComDdd.toString() : null);
    this.email.setValue(contato.email ?? '');
  }

  getUser() {
    this.isLoading = true;
    this.apiService.getUser(this.cpfSelected).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          if (result) {
            this.cpf = this.cpfSelected ?? '';
            this.userRegister = result;
            this.userPersonalDatas = this.userRegister.cliente.dadosPessoais;
            this.userAddress = this.userRegister.cliente.endereco
            this.userContact = this.userRegister.cliente.contato
            this.bidingValuesAddress(this.userAddress)
            this.bidingValuesPersonal(this.userPersonalDatas)
            this.bidingValuesContact(this.userContact)
            this.validateFone()
            this.bairro.markAllAsTouched()
          }
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  validateCep(event: any) {
    const value = event.target.value
    if (value && value.length > 0) {
      const normalize = value.replace(/[^0-9]/g, '');
      if (normalize.length == 8) {
        this.isLoading = true;
        this.apiService.getAddress(normalize).pipe(
          timeout(30000),
          catchError((error) => {
            if (error instanceof TimeoutError) {
              this.isLoading = false;
              return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
            }
            return throwError(() => error)
          })
        )
          .subscribe({
            next: result => {
              if (result) {
                if (result.status == 200 && result.body?.sucesso) {
                  this.validCep = true;
                  this.bidingValuesAddress(result.body?.endereco)
                } else {
                  this.validCep = false;
                  this.openDialogInformative(false);
                  this.cleanValuesFields();
                }
              }
              this.isLoading = false;
            },
            error: error => {
              this.isLoading = false;
              this.validCep = false;
              this.openDialogInformative(true);
              this.cleanValuesFields();
              console.log(error)
            }
          })
      }
    }
  }

  cleanValuesFields() {
    this.cep.setValue('');
    this.estado.setValue('');
    this.cidade.setValue('');
    this.bairro.setValue('');
    this.logradouro.setValue('');
    this.numero.setValue('');
    this.complemento.setValue('');
  }

  openDialogInformative(error: boolean) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: error ? 'Sistema Indisponível. Tente mais tarde.' : 'CEP não encontrado. Consulte o Administrador do sistema',
        icon: error ? 'error' : 'warning',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
        primaryButtonAction: 'close'
      },
    });
  }

  validateFone() {
    if (this.celularComDdd.value) {
      this.telefoneResidencialComDdd.clearValidators();
      this.celularComDdd.setValidators(Validators.required);
      this.telefoneResidencialComDdd.updateValueAndValidity();
    } else if (this.telefoneResidencialComDdd.value) {
      this.celularComDdd.clearValidators();
      this.telefoneResidencialComDdd.setValidators(Validators.required);
      this.celularComDdd.updateValueAndValidity();
    } else {
      this.celularComDdd.setValidators(Validators.required);
      this.telefoneResidencialComDdd.setValidators(Validators.required);
    }
  }

  cancelButtonHandle() {
    this.dialog.open(ModalGenericComponent, {
      width: '432px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.isEditMode = false; },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  saveButtonHandle = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Tem certeza que deseja salvar as alterações?',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.handleFormValidations() },
        secundaryButtonText: 'SIM, SALVAR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openDialogError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: 'Não foi possível alterar os dados nesse momento. Tente novamente mais tarde.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openDialogSucess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: 'Os dados foram alterados com sucesso.',
        primaryButtonAction: () => { this.handleUpdateClientDataSucess() },
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  handleUpdateClientDataSucess() {
    this.isEditMode = false;
    this.dialog.closeAll()
  }

  bidingClientData() {
    const dataNascimentoValue = this.dataNascimento.value;
    const dataNascimentoFormated = dataNascimentoValue ? this.convertStringToDate(dataNascimentoValue) : undefined
    const celularValue = this.celularComDdd.value?.replace(/[^0-9]/g, '');
    const telefoneValue = this.telefoneResidencialComDdd.value?.replace(/[^0-9]/g, '');

    this.clientData = {
      cliente: {
        documentos: {
          cpf: this.cpf,
        },
        dadosPessoais: {
          nomeCompleto: this.nomeCompleto.value ? this.nomeCompleto.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : "",
          dataNascimento: dataNascimentoFormated,
          estadoCivil: this.estadoCivil.value ?? undefined,
          sexo: this.sexo.value ?? undefined,
          nomeMae: this.nomeMae.value ? this.nomeMae.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : "",
        },
        endereco: {
          cep: this.cep.value?.replace("-", "") ?? undefined,
          estado: this.estado.value ?? undefined,
          cidade: this.cidade.value ?? undefined,
          bairro: this.bairro.value ?? undefined,
          logradouro: this.logradouro.value ?? undefined,
          numero: this.numero.value ?? undefined,
          complemento: this.complemento.value ?? undefined
        },
        contato: {
          celularComDdd: celularValue ? celularValue : undefined,
          telefoneResidencialComDdd: telefoneValue ? telefoneValue : undefined,
          email: this.email.value
        }
      }
    }
  }

  handleFormValidations() {
    this.formGroup.markAllAsTouched()
    if (this.formGroup.valid) {
      this.bidingClientData()
      this.updateClientData()
    }
  }

  updateClientData() {
    this.isLoading = true;
    this.apiService.updateClientData(this.clientData).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          this.openDialogError()
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    ).subscribe({
      next: result => {
        console.log(result)
        if (result) {
          this.userPersonalDatas = this.clientData.cliente.dadosPessoais
          this.userAddress = this.clientData.cliente.endereco
          this.userContact = this.clientData.cliente.contato
          this.openDialogSucess()
        }
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
        this.openDialogError()
        console.log(error)
      }
    })
  }

}
