<div class="dialog-content" mat-dialog-content>
  <div class="title-container" style="display: flex; justify-content: space-between; align-items: center;">
    <div class="title-content">
        <span class="title">Confirmação de dados para credenciamento</span>
        <span class="subTitle">Confira os dados antes de credenciar</span>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>Tipo</mat-label>
      <mat-select [formControl]="tipo">
        <mat-option *ngFor="let tipo of listaTipo" [value]="tipo.IdTipo">
          {{ tipo.Nome }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

    <div class="information-content">
        <div>
            <span>Responsável</span>
            <span>{{data?.infos?.responsavel || '-'}}</span>
        </div>
        <div>
            <span>Data:</span>
            <span>{{data?.infos?.data || '-'}}</span>
        </div>
        <div>
            <span>CNPJ</span>
            <span>{{data?.infos?.cnpj | cnpj}}</span>
        </div>
        <div>
            <span>Código</span>
            <span>{{data?.infos?.codigo || '-'}}</span>
        </div>
    </div>
    <div class="information-content">
        <div>
            <span>Razão social</span>
            <span>{{data?.infos?.razaoSocial || '-'}}</span>
        </div>
        <div>
            <span>Data de abertura</span>
            <span>{{data?.infos?.dataAbertura || '-'}}</span>
        </div>
        <div>
            <span>Nome fantasia</span>
            <span>{{data?.infos?.nomeFantasia || '-'}}</span>
        </div>
        <div>
            <span>Porte</span>
            <span>{{data?.infos?.porte || '-'}}</span>
        </div>
    </div>


    <div class="button-container">
        <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="closeModal()"></yfs-button>
        <yfs-button [state]="!tipo.value ? 'disabled' : 'enabled'" label="CREDENCIAR" type="primary" [press]="accredit"></yfs-button>
    </div>
</div>

<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
