<div class="dialog-content" mat-dialog-content>
    <img alt="Ícone de atenção" src="assets/svg/warning-icon.svg" />

    <div *ngIf="numberStep == 1" class="dialog-text">
        <span>Tem certeza que deseja cancelar esse plano?</span>
    </div>

    <div class="dialog-contract-area">
        <span class="contract">Contrato {{data.contractNumber}}</span>
        <span class="name">{{data.clientName}}</span>
    </div>

    <div *ngIf="numberStep == 2" class="dialog-input-content">
        <div class="dialog-input-description">
            <span>Para cancelar o plano, digite <span class="bold">CANCELAR</span> no campo</span>
            <span>abaixo e clique em "CANCELAR PLANO"</span>
        </div>
        <mat-form-field appearance="fill">
            <input data-test="input-cancelar-plan" (input)="checkInput()" [(ngModel)]="cancelarString" matInput>
        </mat-form-field>
    </div>

    <div class="dialog-buttons" [style.marginTop]="numberStep == 1 ? '24px' : '2px'">
        <yfs-button state="enabled" label="MANTER PLANO" type="secondary" (click)="closeModal()"></yfs-button>
        <yfs-button [state]="buttonCancelType" label="CANCELAR PLANO" type="primary" (click)="cancelPlanClick()"></yfs-button>
    </div>
</div>
