<yfs-header></yfs-header>
<section class="container-content" *ngIf="currentFlow == 'parameter'">
    <div class="button-back">
      <yfs-link-back [text]="previousScreen === 'parameters' ? 'Voltar para parâmetros técnicos' : 'Voltar para minha área'" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
        <h1>CADASTRO DE PARÂMETROS DE COMISSÕES</h1>
    </div>
    <mat-card>
        <mat-card-content>
            <div class="category-content">
                <mat-form-field id="categoria" appearance="fill">
                    <mat-label>Categoria</mat-label>
                    <mat-select #perfilAcessoSelect [formControl]="categoria" multiple>
                        <div class="select-all" (click)="selectAllCategoria()">
                            <mat-checkbox [(ngModel)]="checkedAllCategoria"
                                [indeterminate]="selectedCategoria.length > 0 && selectedCategoria.length < listaCategoria.length"
                                color="primary"><span class="select-label">Todos</span></mat-checkbox>
                        </div>
                        <mat-option *ngFor="let categoria of listaCategoria" [value]="categoria.idCategoriaExibicao"
                            (click)="checkHandleCategoria(categoria.idCategoriaExibicao)"><span class="select-label">{{
                                categoria.nomeExibicao }}</span></mat-option>
                    </mat-select>
                    <mat-error *ngIf="categoria.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="inputs" appearance="fill">
                    <mat-label>Data de vigência da tabela</mat-label>
                    <input type="hidden" [matDatepicker]="pickerVigencia" [min]="minDate"
                        [(ngModel)]="dataVigenciaLabel" (ngModelChange)="onInputChange(true)" />
                    <input matInput [textMask]="{ mask: mask }" [min]="minDate" [formControl]="dataVigencia"
                        [ngModel]="dataVigenciaLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChange(false)" />
                    <mat-datepicker-toggle matIconSuffix [for]="pickerVigencia"></mat-datepicker-toggle>
                    <mat-datepicker #pickerVigencia></mat-datepicker>
                    <mat-error *ngIf="dataVigencia.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                    <mat-error class="date-error" *ngIf="dataVigencia.hasError('invalidDate')">Data
                        inválida.</mat-error>
                    <mat-error class="date-error" *ngIf="dataVigencia.hasError('dataValida')">Data inválida. Coloque uma
                        data futura.</mat-error>
                </mat-form-field>
            </div>
            <yfs-button state="enabled" label="TABELAS ANTIGAS" type="secondary" size="small"
            (click)="openOldTables()"></yfs-button>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-content>
            <span class="card-title">Parâmetros de comissão</span>
            <div class="labels-content">
                <span class="label">Parcelas</span>
                <span class="label">Comissão a pagar</span>
                <span class="label">Percentual de estorno da comissão paga</span>
            </div>

            <div *ngFor="let parControles of controlsParcelas; let i = index">
                <div class="tariff-content">
                    <span class="tariff-content-description">Parcela {{ i + 1 }} do programa</span>
                    <div *ngFor="let control of parControles; let j = index">
                        <mat-form-field appearance="fill">
                            <mat-label>%</mat-label>
                            <input [formControl]="control" maxlength="5" (input)="formatParcela(control, parControles[0], parControles[1], i + 1)" matInput>
                            <mat-error *ngIf="control.hasError('required')">
                                O campo é obrigatório
                            </mat-error>
                            <mat-error *ngIf="control.hasError('rangeError')">
                                Percentual deve ser entre 0% e 100%
                            </mat-error>
                            <mat-error *ngIf="control.hasError('minValueError')">
                                <span class="span-error">Valor deve ser menor ou igual a comissão a pagar</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="button-content">
        <yfs-button state="enabled" label="CANCELAR" type="secondary" (click)="openDialogExist()"></yfs-button>
        <yfs-button [state]="checkFields()" label="SALVAR" type="primary" [press]="openDialogConfirmSave"></yfs-button>
        <div class="warning-notification" *ngIf="checkFields() === 'disabled'">
            <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined"
                fontIcon="warning"></mat-icon>
            <span>É necessário preencher todos os campos corretamente para salvar.</span>
        </div>
    </div>
</section>
<yfs-old-tables-commissions *ngIf="currentFlow == 'oldTables'"
    (currentFlowChanged)="changeCurrentFlow($event)" previousFlow="tariffPage"></yfs-old-tables-commissions>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
