import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { dateFormat } from 'src/app/shared/date-format';
import tinymce from 'tinymce/tinymce';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ModalImportFileComponent } from 'src/app/components/modal-import-file/modal-import-file.component';
import { TemplateListingMinutaComponent } from 'src/app/components/template-listing-minuta/template-listing-minuta.component';
import { ModalPreviewDraftComponent } from 'src/app/components/modal-preview-draft/modal-preview-draft.component';

@Component({
  selector: 'yfs-new-draft',
  templateUrl: './new-draft.component.html',
  styleUrls: ['./new-draft.component.scss']
})
export class NewDraftComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();
  @ViewChild(EditorComponent) editor: EditorComponent | undefined;
  @Input() templateData: any;

  public nomenclatura = new FormControl('', [Validators.required]);
  public tipoMinuta = new FormControl('', [Validators.required]);
  public dataInicial = new FormControl('', [Validators.required, dateFormat.parameterTariffValidator]);
  public dataFinal = new FormControl('', [Validators.required, dateFormat.parameterTariffValidator]);
  public dataInicialLabel: Date | undefined;
  public dataFinalLabel: Date | undefined;
  public minDate: Date | undefined;
  public dateError = false;
  public tinymeceSettings: any
  public isLoading = false;
  public listWildCards: any
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public genericMenssage = "Campo obrigatório";
  public typeErrorMessage = '';
  public mensagemInvalid = false;
  public previewContent: any
  public tipoMinutaList: any

  constructor(private apiService: ApiHttpService, public dialog: MatDialog) {
    const currentDate = new Date();
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(currentDate.getDate() + 1);
    this.minDate = tomorrowDate;
  }

  ngOnInit() {
    this.initializeTinyMCE();
    this.getWildCards();
    this.getTipoMinutaList();
  }

  getTitle() {
    return this.templateData ? 'EDITAR MENSAGEM' : 'NOVA MENSAGEM'
  }

  getBack() {
    this.currentFlowChanged.emit('listDraft');
  }

  initializeTinyMCE() {
    this.tinymeceSettings = {
      base_url: '/tinymce',
      suffix: '.min',
      menubar: true,
      height: 700,
      setup: (editor: any) => {
        editor.on('init', () => {
          this.pickMensagem()
        })
      },
      plugins: 'image link table pagebreak preview help lists advlist fullscreen quickbars searchreplace code',
      toolbar: 'undo redo fullscreen | blocks  fontsizeinput | table quicktable searchreplace| ' +
        'strikethrough bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist | lineheight outdent indent | insertfile image link  | pagebreak removeformat | help',
      block_formats: 'Normal=div; Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6; Preformatted=pre',
      font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
      // style_formats: [{title: 'red text', inline: 'span'}],
      toolbar_mode: 'sliding',
      entity_encoding: 'raw',
      pagebreak_split_block: true,
      forced_root_block: 'div',
      promotion: false,
      content_style: 'table {border-collapse: collapse;} table td {padding: 0 2px !important;}',
    };
  }

  pickMensagem() {
    if (this.templateData?.mensagem) {
      tinymce.activeEditor?.insertContent(this.templateData?.mensagem);
      if (this.templateData?.hasContract) {
        tinymce.activeEditor?.mode.set('readonly');
      }
    }
  }

  insertContent(content: string) {
    tinymce.activeEditor?.insertContent(content);
  }

  showContent() {
    const content = this.editor?.editor.getContent();
    this.previewContent = content
    this.openModalPreview();
  }

  onInputChangeInicial(isCalendar: boolean) {
    if (this.dataInicial.value?.length === 0) {
      this.dataInicialLabel = undefined;
    }

    if (this.dataInicial && this.dataInicial.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataInicial2 = this.dataInicial.value ?
        this.getDateByString(this.dataInicial.value) : null;

      if (!isCalendar) {
        this.dataInicialLabel = dataInicial2 || undefined
      }
    }
    this.checkDatesRange();
  }

  onInputChangeFinal(isCalendar: boolean) {
    if (this.dataFinal.value?.length === 0) {
      this.dataFinalLabel = undefined;
    }

    if (this.dataFinal && this.dataFinal.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataFinal2 = this.dataFinal.value ?
        this.getDateByString(this.dataFinal.value) : null;

      if (!isCalendar) {
        this.dataFinalLabel = dataFinal2 || undefined
      }
    }
    setTimeout(() => {
      this.checkDatesRange();
    }, 100)

  }

  checkDatesRange() {
    if (typeof this.dataInicial.value == 'string' && typeof this.dataFinal.value == 'string') {
      const dataInicio = this.dataInicial.value ? new Date(this.getDateByString(this.dataInicial.value)) : null;
      const dataFim = this.dataFinal.value ? new Date(this.getDateByString(this.dataFinal.value)) : null;
      if (dataInicio && dataFim && dataInicio >= dataFim) {
        this.dataFinal.setErrors({ 'minValueError': true })
      } else {
        const errors = { ...this.dataFinal.errors };
        delete errors['minValueError'];
        this.dataFinal.setErrors(Object.keys(errors).length !== 0 ? errors : null);
      }
    }
    if (this.dataFinal.value)
      this.dataFinal.markAllAsTouched()

  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  getWildCards() {
    this.isLoading = true;
    this.apiService.getWildcards().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result) {
            this.listWildCards = result;
            if (this.templateData) {
              this.populaDataEdit('edit');
            }
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  getTipoMinutaList() {
    this.isLoading = true;
    this.apiService.getTipoMinuta().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.tipoMinutaList = result;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  convertDateToString(data: any) {
    const dataOriginal = new Date(data);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  convertDataLabel(dateString: any) {
    const [dayStr, monthStr, yearStr] = dateString.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    return dateObj
  }

  populaDataEdit(flow: string, templateImport?: any) {
    const templateDataBind = flow == 'edit' ? this.templateData : templateImport;
    if (templateDataBind?.hasContract) {
      this.nomenclatura.disable();
      this.tipoMinuta.disable();
      this.dataInicial.disable();
      this.dataFinal.disable();
    }
    this.nomenclatura.setValue(templateDataBind?.nomenclatura)
    this.tipoMinuta.setValue(templateDataBind?.idTipoMinuta)

    const dataInicialFormated = templateDataBind?.dataInicioVigencia ? this.convertDateToString(templateDataBind?.dataInicioVigencia) : ''
    this.dataInicialLabel = templateDataBind?.dataInicioVigencia ? this.convertDataLabel(dataInicialFormated) : undefined;
    this.dataInicial.setValue(dataInicialFormated);

    const dataFinalFormated = templateDataBind?.dataFinalVigencia ? this.convertDateToString(templateDataBind?.dataFinalVigencia) : ''
    this.dataFinalLabel = templateDataBind?.dataFinalVigencia ? this.convertDataLabel(dataFinalFormated) : undefined;
    this.dataFinal.setValue(dataFinalFormated);
  }

  getContentEditor() {
    const content = this.editor?.editor.getContent();
    return content;
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }


  handleSaveButtonClick = () => {
    this.nomenclatura.markAllAsTouched();
    this.tipoMinuta.markAllAsTouched();
    this.dataInicial.markAllAsTouched();
    this.dataFinal.markAllAsTouched();
    const mensagem = this.getContentEditor()
    if (mensagem?.length === 0) {
      this.mensagemInvalid = true;
      this.typeErrorMessage = 'required'
    } else {
      this.mensagemInvalid = false;
      this.typeErrorMessage = '';
    }
    const isValidFields = this.nomenclatura.valid && this.tipoMinuta.valid && this.dataInicial.valid && this.dataFinal.valid && !this.mensagemInvalid
    if (isValidFields) {
      const newTemplate = {
        nomenclatura: this.nomenclatura.value,
        idTipoMinuta: this.tipoMinuta.value,
        dataInicioVigencia: this.dataInicial.value ? this.convertStringToDate(this.dataInicial.value) : undefined,
        dataFinalVigencia: this.dataFinal.value ? this.convertStringToDate(this.dataFinal.value) : undefined,
        template: mensagem
      }
      this.postNewMinuta(newTemplate)

    }
  }

  isEnbledButton() {
    return this.templateData?.hasContract ? 'disabled' : 'enabled';
  }

  postNewMinuta(newMinuta: any) {
    this.isLoading = true;

    const apiObservable = this.templateData
      ? this.apiService.updateMinutaTemplate(newMinuta)
      : this.apiService.postNewMinutaTemplate(newMinuta)

    apiObservable.pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openModalSucess()
        },
        error: (error) => {
          this.isLoading = false;
          this.openModalErrorNewTemplate()
          console.log(error);
        },
      });
  }

  getConfigModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '384px'
    return dialogConfig;
  }

  openModalSucess() {
    const dialogConfig = this.getConfigModal()
    this.dialog.open(ModalGenericComponent, {
      ...dialogConfig,
      data: {
        icon: 'success',
        text: 'Minuta criada com sucesso.',
        primaryButtonAction: () => { this.getBack(), this.dialog.closeAll() },
        primaryButtonText: 'IR PARA LISTA DE MINUTAS',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openDialogUpload = () => {
    this.dialog.open(ModalImportFileComponent, {
      width: '476px',
      data: {
        title: 'Insira aqui o arquivo template:',
        subtitle: 'Somente um arquivo em HTML',
        importFile: this.checkEditorHasValue.bind(this),
      },
    });
  }

  checkEditorHasValue(templateHtml: any, flow: string) {
    const mensagem = this.getContentEditor();
    if (mensagem && mensagem?.length > 0) {
      this.openModalInformative(templateHtml, flow)
    } else {
      this.insertTemplateOnEditor(templateHtml, flow);
    }
  }

  insertTemplateOnEditor(template: any, flow: string) {
    if (flow == 'template') {
      this.populaDataEdit('import', template);
      tinymce.activeEditor?.setContent('');
      tinymce.activeEditor?.insertContent(template.template);
    } else {
      tinymce.activeEditor?.setContent('');
      tinymce.activeEditor?.insertContent(template);
    }
  }

  openDialogTemplate = () => {
    this.dialog.open(TemplateListingMinutaComponent, {
      width: '609px',
      autoFocus: false,
      data: {
        insertTemplateSelectedToEditor: (template: any) => { this.checkEditorHasValue(template, 'template'); }
      }
    })
  }

  cancelButtonHandle() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.getBack(), this.dialog.closeAll() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openModalErrorNewTemplate() {
    const dialogConfig = this.getConfigModal()
    this.dialog.open(ModalGenericComponent, {
      ...dialogConfig,
      data: {
        icon: 'error',
        text: 'Não foi possível cadastrar a minuta nesse momento. Tente novamente mais tarde.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.handleSaveButtonClick(); this.dialog.closeAll() },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary'
      }
    })
  }

  openModalPreview() {
    this.dialog.open(ModalPreviewDraftComponent, {
      height: '900px',
      panelClass: 'preview-draft-dialog',
      data: {
        previewContent: this.previewContent,
        nomenclatura: this.nomenclatura.value,
        dataVigenciaInicial: this.dataInicial.value,
        dataVigenciaFinal: this.dataFinal.value,
        tipoMinuta: this.tipoMinuta.value
      }
    })
  }

  openModalInformative(templateHtml: any, flow: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '400px',
      data: {
        text: 'Deseja substituir o conteúdo atual da minuta?',
        primaryButtonAction: 'close',
        primaryButtonText: 'CANCELAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.insertTemplateOnEditor(templateHtml, flow) },
        secundaryButtonText: 'SIM, SUBSTITUIR',
        secundaryButtonVariant: 'primary',
      }
    })
  }


}
