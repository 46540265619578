<div class="dialog-content" mat-dialog-content>
    <div class="container">
        <span class="title">Selecionar template</span>
        <span class="subTitle">Selecionar uma minuta abaixo para reaproveitar o modelo</span>

        <mat-form-field appearance="fill">
            <mat-label>Busque por nome</mat-label>
            <mat-icon aria-hidden="false" aria-label="Ícone de busca" class="search-icon material-symbols-outlined"
                fontIcon="search"></mat-icon>
            <input [formControl]="nomeTemplate" (focusout)="setPageStart(); this.getTemplates()" matInput>
        </mat-form-field>
    </div>

    <div>
        <table mat-table [dataSource]="dataSource" #secondTable>

            <ng-container matColumnDef="nomeTemplate">
                <th mat-header-cell *matHeaderCellDef>
                    Nome da minuta
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-radio-group [(ngModel)]="selectedTemplate">
                        <mat-radio-button [value]="element"></mat-radio-button>
                    </mat-radio-group> {{element.nomenclatura}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        </table>

        <div class="paginator-content">
            <mat-paginator [pageIndex]="pageIndex" [length]="length" [pageSize]="pageSize" aria-label="Select page"
                [pageSizeOptions]="pageSizeOptions" (page)="handlePageEvent($event)">
            </mat-paginator>
        </div>
    </div>

    <div class="buttons-content">
        <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="closeModal()"></yfs-button>
        <yfs-button [state]="selectedTemplate ?  'enabled' : 'disabled'" label="SELECIONAR TEMPLATE" type="primary"
            [press]="selectTemplate"></yfs-button>
    </div>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>