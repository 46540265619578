<mat-card>
    <mat-card-content>
        <div class="export-content">
            <span class="export-description">Quer exportar o relatório? <span>Escolha o formato do arquivo que será gerado: </span></span>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="formatoArquivo">
                <mat-radio-button value="pdf">PDF</mat-radio-button>
                <mat-radio-button value="xlsx">XLS</mat-radio-button>
            </mat-radio-group>
            <yfs-button state="enabled" label="GERAR ARQUIVO" class="btn-generate" type="primary" (click)="handleGenerateFile()"></yfs-button>
        </div>
    </mat-card-content>
</mat-card>
<mat-card class="table-content">
    <mat-card-content>
        <div class="input-content">
            <mat-form-field appearance="fill">
                <mat-label>Busque por nome, CPF ou contrato</mat-label>
                <mat-icon aria-hidden="false" aria-label="Ícone de busca" class="search-icon material-symbols-outlined"
                    fontIcon="search"></mat-icon>
                <input [formControl]="searchString" (keyup)="applyFilter()" matInput>

            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Motivo</mat-label>
                <mat-select #mySel3 [formControl]="motivo" multiple>
                    <div class="select-all" (click)="selectAllMotivo()">
                        <mat-checkbox [(ngModel)]="checkedAllMotivo" [indeterminate]="selectedMotivos.length > 0 && selectedMotivos.length < motivoList.length"
                            color="primary"><span>Todos</span></mat-checkbox>
                    </div>
                    <mat-option *ngFor="let motivo of motivoList" [value]="motivo.description"
                        (click)="checkHandleMotivo(motivo.description)">{{ motivo.description }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div style="margin-top: 10px;">
            <table *ngIf="hasValue" mat-table [dataSource]="dataSource" #firstTable #firstTableSort="matSort" matSort
                (matSortChange)="announceSortChange($event)">

                <ng-container matColumnDef="canalOriginacao">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by canalOriginacao">
                        Canal de Originação
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.canalOriginacao}} </td>
                </ng-container>

                <ng-container matColumnDef="canalRelacionamento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by canalRelacionamento">
                        Canal de Relacionamento
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.canalRelacionamento}} </td>
                </ng-container>

                <ng-container matColumnDef="numeroContrato">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by numeroContrato">
                        Contrato
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.numeroContrato}} </td>
                </ng-container>

                <ng-container matColumnDef="nomeCliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by nomeCliente">
                        Cliente
                    </th>
                    <td mat-cell *matCellDef="let element" class="truncate-text"> {{element.nomeCliente}} </td>
                </ng-container>

                <ng-container matColumnDef="cpfCliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by cpfCliente">
                        CPF
                    </th>
                    <td mat-cell *matCellDef="let element" class="cpf-row"> {{element.cpfCliente | cpf }} </td>
                </ng-container>

                <ng-container matColumnDef="dataAtivacaoContrato">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by dataAtivacaoContrato">
                        Data do contrato
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.dataAtivacaoContrato | date: 'dd/MM/yyyy' }} </td>
                </ng-container>

                <ng-container matColumnDef="valorPlano">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                        Valor do plano
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.valorPlano | currency }} </td>
                </ng-container>

                <ng-container matColumnDef="saldoReserva">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                        Saldo da reserva
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.saldoReserva | currency }} </td>
                </ng-container>

                <ng-container matColumnDef="motivo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by motivo">
                        Motivo
                    </th>
                    <td mat-cell *matCellDef="let element" class="truncate-text"> {{element.motivo}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row (click)="selectClientPlan(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

<div class="paginator-content">
    <mat-paginator aria-label="Select page" [pageSizeOptions]="pageSizeOptions">
    </mat-paginator>
  </div>