import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { IBankDatas, ICancelPlan } from 'src/app/models/cancel-plan.interface';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { DadosBancarios, IContractedItem } from 'src/app/models/contracted-plans.interface';
import { CpfValidator } from 'src/app/shared/validators/cpf-validator';
import { CnpjValidator } from 'src/app/shared/validators/cnpj-validator';
import { IContact } from 'src/app/models/register.interface';
import { Router } from '@angular/router';

interface IBankInfos {
  codBanco: string,
  nome: string
}

interface Parametro {
  assinaturaEletronicaHabilitada: boolean;
}


@Component({
  selector: 'yfs-cancel-plan',
  templateUrl: './cancel-plan.component.html',
  styleUrls: ['./cancel-plan.component.scss']
})
export class CancelPlanComponent implements OnInit {

  @Input() idContrato: number | undefined;
  @Input() statusContratoNumero: number | undefined;
  @Input() contractedPlan: IContractedItem | undefined;
  @Input() cpfClient: string | undefined = '';
  @Input() userName: string | undefined = '';
  @Input() concessionariaValue: number | undefined;
  @Input() dadosBancariosData: DadosBancarios | undefined;
  @Input() emailCliente: string | undefined = '';
  @Input() telefoneCliente: { tipo: string, ddd: string, numero: string }[] | undefined = [];
  @Output() closeCancelPlan = new EventEmitter();
  @Output() closeReleaseValues = new EventEmitter();
  @Output() chanceStatus = new EventEmitter();
  @Output() currentFlowChanged = new EventEmitter<string>();

  public beneficiario = new FormControl(this.getName(), [Validators.required]);
  public cpf = new FormControl(this.getCpf() || '', [Validators.required, CpfValidator.cpf]);
  public cnpj = new FormControl(this.getCpf() , [Validators.required, CnpjValidator.cnpj]);
  public banco = new FormControl('', [Validators.required]);
  public agencia = new FormControl('', [Validators.required]);
  public contaCorrente = new FormControl('', [Validators.required]);
  public contaCorrenteDigito = new FormControl('')
  public tipoPessoa = "pessoaFisica";
  public numeroBanco: any;
  public terceiro = "nao";
  public tipoConta = "corrente";
  public options = []
  public filteredOptions: Observable<any> | undefined;
  public previwVLenght?= 0;
  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public agenciaMask = [/\d/, /\d/, /\d/, /\d/, /\d/]
  public contaMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  public contaCorrenteDigitoMask = [/\d/, /\d/]
  public genericMenssage = "Campo obrigatório"
  public isLoading = false;
  public valueToReceive = ""
  public concessionariaData =[];
  public bankName = "";
  public bankCod = "";
  public cpfClientTemp = "";
  public userNameClientTemp = "";
  public bancoTemp = "";
  public agenciaTemp = "";
  public contaCorrenteTemp = "";
  public contaCorrenteDigitoTemp = "";
  public tipoContaTemp = "";
  public numeroBancoTemp = "";
  public showSendCard = false
  public showCancel = true
  public termSent = false
  public formaEnvio = 'Whatsapp'
  public infoDataEnvio = ''
  public celular = new FormControl({ value: '', disabled: true });
  public sms = new FormControl({ value: '', disabled: true });
  public email = new FormControl({ value: '', disabled: true }, [
    Validators.email,
    Validators.maxLength(50)
  ]);
  public contact: IContact | undefined
  public dataExpiracaoTermoCancelamento = ''
  public assinaturaEletronicaHabilitada: boolean | null = false;
  public bankError: boolean = false;

  public celularMask = ['(', /\d/, /\d/, ')', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(private router: Router, public dialog: MatDialog, private renderer: Renderer2, private apiService: ApiHttpService) {

    if (this.contractedPlan && this.contractedPlan.emailCliente) {
      this.email.setValue(this.contractedPlan.emailCliente);
    }

    this.beneficiario.valueChanges.subscribe(value => {
      const apenasLetrasRegex = /^[a-zA-Z\s]+$/;
      if (value && !apenasLetrasRegex.test(value)) {
        this.beneficiario.setValue("", { emitEvent: false });
      }
    });
   }

  ngOnInit() {
    this.assinaturaEletronicaHabilitada = this.obterAssinaturaEletronicaHabilitada();
    this.cpfClientTemp = this.getCpf() || "";
    this.userNameClientTemp = this.getName() || "";
    this.bancoTemp = this.dadosBancariosData?.nomeBanco || "";
    this.numeroBancoTemp = this.dadosBancariosData?.numeroBanco || "";
    this.agenciaTemp = this.dadosBancariosData?.agencia || "";
    this.contaCorrenteTemp = this.dadosBancariosData?.contaCorrente || "";
    this.contaCorrenteDigitoTemp = this.dadosBancariosData?.digitoContaCorrente || "";
    this.beneficiario = new FormControl(this.getName() || '', [
      Validators.required
    ]);
    this.terceiro = this.dadosBancariosData?.terceiro === true ? 'sim' : 'nao'
    this.agencia = new FormControl(this.dadosBancariosData?.agencia || '', [Validators.required]);
    this.contaCorrente = new FormControl(this.dadosBancariosData?.contaCorrente || '', [Validators.required]);
    this.contaCorrenteDigito = new FormControl(this.dadosBancariosData?.digitoContaCorrente || '', [Validators.required]);
    this.banco = new FormControl(this.dadosBancariosData?.nomeBanco || '', [Validators.required]);
    this.numeroBanco = this.dadosBancariosData?.numeroBanco;
    this.bankCod = this.dadosBancariosData?.numeroBanco || '';
    this.verifyAccount();
    this.tipoConta = this.tipoContaTemp
    if (this.dadosBancariosData?.categoriaTerceiro) {
      this.tipoPessoa = this.dadosBancariosData.categoriaTerceiro === 0 ? 'pessoaFisica' : this.dadosBancariosData.categoriaTerceiro === 1 ? 'pessoaJuridica' : 'concessionaria'
    }
    if (this.tipoPessoa === 'pessoaFisica') this.cpf = new FormControl(this.getCpf() || '', [Validators.required, CpfValidator.cpf]);
    else this.cnpj = new FormControl(this.getCpf() || '', [Validators.required, CnpjValidator.cnpj]);
    this.filteredOptions = this.banco.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.getBanks()
    this.getValueToReceive()
    this.verifyBankDataInit()
    this.getUser()
  }

  obterAssinaturaEletronicaHabilitada(): boolean | null {
    const parametrosJSON = sessionStorage.getItem('parametros');

    if (parametrosJSON) {
        const parametros: Parametro[] = JSON.parse(parametrosJSON);

        if (parametros.length > 0 && typeof parametros[0].assinaturaEletronicaHabilitada === 'boolean') {
            return parametros[0].assinaturaEletronicaHabilitada;
        }
    }

    return null;
}

  verifyAccount(){
    const tipoContaNumero = this.dadosBancariosData?.tipoConta;
    if (tipoContaNumero === 1) {
      this.tipoContaTemp  = 'corrente';
    } else if (tipoContaNumero === 2) {
      this.tipoContaTemp  = 'poupanca';
    } else {
      this.tipoContaTemp  = 'corrente';
    }
  }

  preventInvalidCharacters(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");

    if (sanitizedValue !== value) {
      inputElement.value = sanitizedValue;
      this.beneficiario.setValue(sanitizedValue, { emitEvent: false });
    }
  }

  recoverData(){
    if(this.tipoPessoa === 'pessoaFisica'){
    this.beneficiario.setValue(this.userNameClientTemp);
    this.cpf.setValue(this.cpfClientTemp);
    this.agencia.setValue(this.agenciaTemp);
    this.contaCorrente.setValue(this.contaCorrenteTemp);
    this.contaCorrenteDigito.setValue(this.contaCorrenteDigitoTemp);
    this.banco.setValue(this.bancoTemp);
    this.tipoConta = this.tipoContaTemp;
    }

    this.recovery();
  }

  recovery(){
    if (this.terceiro === 'sim') {
      this.clearBankDataFields();
      if (this.tipoPessoa === 'pessoaFisica' || this.tipoPessoa === 'pessoaJuridica' || this.tipoPessoa === 'concessionaria') {
        this.clearBankDataFields();
        this.tipoPessoa = 'pessoaFisica';
        this.beneficiario.setValue(this.userNameClientTemp);
        this.cpf.setValue(this.cpfClientTemp);
        this.agencia.setValue(this.agenciaTemp);
        this.contaCorrente.setValue(this.contaCorrenteTemp);
        this.contaCorrenteDigito.setValue(this.contaCorrenteDigitoTemp);
        this.banco.setValue(this.bancoTemp);
        this.numeroBanco = this.numeroBancoTemp;
      }
    }
  }

  clearAndOpenOptions() {
    this.filteredOptions = this.banco.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  getTamanhoMaximo(): number {
    return this.tipoPessoa === 'pessoaFisica' ? 11 : 14;
  }

  verifyBankDataInit() {
    const isBeneficiaryDisabled = this.terceiro === 'nao' || (this.terceiro === 'sim' && this.tipoPessoa === 'concessionaria');
    const isBankDataDisabled = this.terceiro === 'sim' && this.tipoPessoa === 'concessionaria';

    if (isBeneficiaryDisabled) {
      this.disabledUserInformation();
    } else {
      this.beneficiario.enable();
      this.cpf.enable();
      this.cnpj.enable();
    }

    if (isBankDataDisabled) {
      this.disabledAccount();
    } else {
      this.banco.enable();
      this.agencia.enable();
      this.contaCorrente.enable();
      this.contaCorrenteDigito.enable();
    }
  }

  verifyBankData() {
    const isBeneficiaryDisabled = this.terceiro === 'nao' || (this.terceiro === 'sim' && this.tipoPessoa === 'concessionaria');
    const isBankDataDisabled = this.terceiro === 'sim' && this.tipoPessoa === 'concessionaria';

    if (isBeneficiaryDisabled) {
      this.disabledUserInformation();
      this.clearBeneficiaryFields();
    } else {
      this.beneficiario.enable();
      this.cpf.enable();
      this.cnpj.enable();
      if (this.tipoPessoa === 'pessoaFisica') {
        this.clearBeneficiaryFields();
      }
    }

    if (isBankDataDisabled) {
      this.disabledAccount();
      this.clearBankDataFields();
    } else {
      this.banco.enable();
      this.agencia.enable();
      this.contaCorrente.enable();
      this.contaCorrenteDigito.enable();
      if (this.tipoPessoa !== 'pessoaFisica') {
        this.clearBeneficiaryFields();
        this.clearCPF();
      }
    }
  }

  clearBeneficiaryFields() {
    this.cnpj.setValue('');
  }

  clearBankDataFields() {
    this.banco.setValue('');
    this.agencia.setValue('');
    this.contaCorrente.setValue('');
    this.contaCorrenteDigito.setValue('');
  }

  clearCPF() {
    this.cpf.setValue('');
  }

  disabledUserInformation(){
    this.beneficiario.disable();
    this.cpf.disable();
    this.cnpj.disable();
  }

  disabledAccount(){
    this.banco.disable();
    this.agencia.disable();
    this.contaCorrente.disable();
    this.contaCorrenteDigito.disable();
  }

  getBanks() {
    this.isLoading = true;
    this.apiService.getBanks().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.options = result;
          this.bankError = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.openModalErrorBanks()
          this.bankError = true;
          console.log(error);
        },
      });
  }

  getValueToReceive() {
    this.isLoading = true;
    this.apiService.getValueToReceive(this.idContrato).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.valueToReceive = result.valorReceber;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  handleConcessionariaClick() {
    if (this.terceiro !== 'nao') {
      this.getConcessionariaValue();
    }
  }

  verifyAccountType() {
    if(this.tipoPessoa === 'pessoaFisica' && this.terceiro === 'sim') {
      this.cleanFields();
      this.clearBankDataFields();
    }
    if(this.tipoPessoa === 'pessoaJuridica' && this.terceiro === 'sim') {
      this.cleanFields();
      this.clearBankDataFields();
    }
    if (this.tipoPessoa === 'concessionaria') {
      this.cleanFields();
      this.clearBankDataFields();
    }
  }

  cleanFields(){
      this.beneficiario.setValue('');
      this.cnpj.setValue('');
      this.cpf.setValue('');
      this.agencia.setValue('');
      this.contaCorrente.setValue('');
      this.contaCorrenteDigito.setValue('');
      this.banco.setValue('');
      this.numeroBanco = '';
  }

  getConcessionariaValue() {
    this.isLoading = true;
    this.apiService.getConcessionaria(this.concessionariaValue).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          if(result.status === 500 || result.status === 404) {
            this.isLoading = false;
            this.openModalError();
          }
          this.isLoading = false;
          this.beneficiario = new FormControl(result[0].razaoSocial, [Validators.required]);
          this.cnpj = new FormControl(result[0].cnpj || '', [Validators.required, CnpjValidator.cnpj]);
          this.banco = new FormControl(result[0].dadosBancarios[0].nomeBanco), [Validators.required];
          this.numeroBanco = new FormControl(result[0].dadosBancarios[0].codBanco), [Validators.required];
          this.bankCod = "0" + result[0].dadosBancarios[0].numeroBanco;
          this.bankName = result[0].dadosBancarios[0].nomeBanco;
          this.agencia = new FormControl(result[0].dadosBancarios[0].agencia, [Validators.required]);
          this.contaCorrente = new FormControl(result[0].dadosBancarios[0].contaCorrente, [Validators.required]);
          this.contaCorrenteDigito = new FormControl(result[0].dadosBancarios[0].digitoContaCorrente)
          this.disabledUserInformation();
          this.disabledAccount();
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
          this.openModalError();
        },
      });
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();

    return this.options.filter(
      (option: IBankInfos) => option.nome.toLowerCase().includes(filterValue)
        || option.codBanco.toLowerCase().includes(filterValue)
    );
  }

  emitCloseCancelPlan() {
    this.closeCancelPlan.emit();
  }

  openModalClose() {
    const primaryButtonText = this.assinaturaEletronicaHabilitada ? 'VOLTAR E EDITAR' : 'VOLTAR';
    const secundaryButtonText = this.assinaturaEletronicaHabilitada ? 'SIM, SAIR' : 'SIM, FECHAR';
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Se você sair agora, o processo de cancelamento do plano não será concluído. Você tem certeza que deseja fechar?',
        primaryButtonAction: 'close',
        primaryButtonText: primaryButtonText,
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.emitCloseCancelPlan() },
        secundaryButtonText: secundaryButtonText,
        secundaryButtonVariant: 'primary',
      }
    })
}

openModalNext = () => {
  const text = this.assinaturaEletronicaHabilitada ?
      `Tem certeza que deseja continuar o processo de cancelamento com esses dados bancários informados?` :
      `Ao continuar, o processo de desistência do plano será iniciado.
      O cancelamento será efetivado após a conclusão do processamento. Você tem certeza que deseja prosseguir?`;
  const secundaryButtonAction = this.assinaturaEletronicaHabilitada ? () => { this.next() } : () => { this.save() };
  const secundaryButtonText = this.assinaturaEletronicaHabilitada ? 'SIM, CONTINUAR' : 'SIM, PROSSEGUIR';
  this.dialog.open(ModalGenericComponent, {
    width: '384px',
    data: {
      text: text,
      primaryButtonAction: 'close',
      primaryButtonText: 'VOLTAR',
      primaryButtonVariant: 'secondary',
      secundaryButtonAction: secundaryButtonAction,
      secundaryButtonText: secundaryButtonText,
      secundaryButtonVariant: 'primary',
    }
  })
}

openDialogStartProcess() {
  this.dialog.open(ModalGenericComponent, {
    width: '384px',
    data: {
      text: `O processo de desistência foi iniciado. Para finalizar baixe o termo de cancelamento,
      peça a assinatura do cliente e faça o upload do documento.`,
      icon: 'waiting',
      primaryButtonAction: 'close',
      primaryButtonText: 'FECHAR',
      primaryButtonVariant: 'primary',
    },
  });
}

  openModalError() {
    this.dialog.open(ModalGenericComponent, {
      width: '406px',
      data: {
        text: `Não foi possível carregar as informações da concessionária. Por favor, tente novamente mais tarde ou entre em contato com o SAC através dos canais:`,
        secondaryText: `<span> Canal de Voz (CRC): (11) 2088-7700 </span><span> WhatsApp: (11) 2088-7700 </span><span> E-mail: liberacred@yamaha-motor.com.br </span>`,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'primary',
        icon: 'error',
      }
    })
  }

  openModalErrorBanks() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: 'Não foi possível verificar os dados bancários no momento. Por favor, tente novamente mais tarde.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    });
  }

  verifyCpfCnpj() {
    if((this.tipoPessoa === 'pessoaFisica' && this.terceiro === 'sim' && this.cpf.valid && this.beneficiario.valid && this.banco.valid && this.agencia.valid && this.contaCorrente.valid) || this.tipoPessoa === 'pessoaFisica' && this.beneficiario.valid && this.banco.valid && this.agencia.valid && this.contaCorrente.valid) {
      return true
    }

    if((this.tipoPessoa === 'pessoaFisica' && this.terceiro === 'nao' && this.cpf.value && this.beneficiario.value && this.banco.valid && this.agencia.valid && this.contaCorrente.valid)) {
      return true
    }

    if(this.tipoPessoa === 'pessoaJuridica' && this.beneficiario.valid && this.banco.valid && this.agencia.valid && this.contaCorrente.valid && this.cnpj.valid) {
      return true
    }

    if(this.tipoPessoa === 'concessionaria') {
      return true
    }

    return false
  }

  save() {
    this.beneficiario.markAllAsTouched()
    this.cpf.markAllAsTouched()
    this.cnpj.markAllAsTouched()
    this.banco.markAllAsTouched()
    this.agencia.markAllAsTouched()
    this.contaCorrente.markAllAsTouched()

    const bancoValue = this.banco.value
    const bancoFind = this.options.filter((option: IBankInfos) => option.nome == bancoValue) as any

    if (bancoValue && bancoValue.length > 0 && (!bancoFind || bancoFind.length === 0) && bancoValue !== this.bankName) {
      this.banco.setErrors({ 'invalidBank': true });
    }

    const tipoContaNumero = this.tipoConta === 'corrente' ? 1 : 2;
    const verificaCategoria = this.tipoPessoa === 'pessoaFisica' ? 0 : this.tipoPessoa === 'pessoaJuridica' ? 1 : 2;

    if (this.verifyCpfCnpj()) {

      const bankPayload: IBankDatas = {
        idDadosBancarios: this.dadosBancariosData?.idDadosBancarios ? this.dadosBancariosData?.idDadosBancarios : null,
        terceiro: this.terceiro === 'sim' ? true : false,
        tipoConta: tipoContaNumero,
        pessoaJuridica: this.tipoPessoa === 'pessoaJuridica' ? true : false,
        numeroBanco: bancoFind[0]?.codBanco || this.bankCod || '',
        nomeBanco: this.banco.value || this.bankName || '',
        agencia: this.agencia.value || '',
        digitoAgencia: null,
        contaCorrente: this.contaCorrente.value || '',
        digitoContaCorrente: this.contaCorrenteDigito.value || null,
        cpfCnpj: (this.tipoPessoa === 'pessoaFisica' ? this.cpf.value : this.cnpj.value)?.replace(/[^a-zA-Z0-9]/g, '') || '',
        nomeBeneficiario: this.beneficiario.value || '',
        categoriaTerceiro: verificaCategoria,
      }

      const cancelPayload: ICancelPlan = {
        idContrato: this.idContrato,
        statusContratoNumero: this.statusContratoNumero,
        dadosBancarios: bankPayload,
        formaEnvioDocumento: this.formaEnvio,
        infoEnvio: this.infoDataEnvio
      }
      this.cancelPlan(cancelPayload)
    } else {
      this.dialog.closeAll()
    }
  }

  cancelPlan(cancelPlanPayload: ICancelPlan) {
    this.isLoading = true;
    this.apiService.cancelPlan(cancelPlanPayload).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    ).subscribe({
      next: result => {
        if(this.assinaturaEletronicaHabilitada === false){
        this.isLoading = false;
        this.chanceStatus.emit(result.dataCancelamento)
        this.closeCancelPlan.emit()
        this.openDialogStartProcess()
        } else {
          this.isLoading = false;
          this.chanceStatus.emit(result.dataCancelamento)
          this.termSent = true
          this.showSendCard = false;
          this.dataExpiracaoTermoCancelamento = result.dataExpiracaoTermoCancelamento;
          this.openSuccess(result.dataExpiracaoTermoCancelamento)
        }
      },
      error: error => {
        if(this.assinaturaEletronicaHabilitada === true){
          this.isLoading = false;
          this.openDialogError()
          this.showSendCard = true
        } else { this.isLoading = false;
          this.openDialogError()}

        if (error.status
          && error.status == 400
          && error?.error?.errors["DadosBancarios.CpfCnpj"]) {
          if (error?.error?.errors["DadosBancarios.CpfCnpj"][0] === "O cpf ou cnpj informado é inválido.") {
            this.cpf.setErrors({ 'invalidCpf': true })
            this.cnpj.setErrors({ 'invalidCnpj': true })
            this.dialog.closeAll()
          }
        }
        console.log(error)
      }
    })
  }

  openSuccess(data: string) {
    const dateObject = new Date(data);
    const formattedDate = dateObject.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: `Termo de cancelamento enviado com sucesso. Assine-o até ${formattedDate} para efetivar a desistência.`,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openDialogError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: `Não foi possível enviar o termo de cancelamento. Tente novamente mais tarde.`,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  formatarNome() {
    let valor = this.beneficiario.value;
    if (valor) {
      valor = valor.replace(/[^a-zA-ZÀ-ÿ\s]/g, '');
      this.beneficiario.setValue(valor);
    }
  }

  formatarCPF() {
    let valor = this.cpf.value;
    if (valor) {
      valor = valor.replace(/\D/g, '');
      if (valor.length > 11) {
        valor = valor.slice(0, 11);
      }
      valor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    }
  }

  formatarCNPJ() {
    let valor = this.cnpj.value;
    if (valor) {
      valor = valor.replace(/\D/g, '');
      if (valor.length > 14) {
        valor = valor.slice(0, 14);
      }
      valor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      this.cnpj.setValue(valor);
    }
  }

  getCpf() {
    if (this.dadosBancariosData && this.dadosBancariosData.cpfCnpj) return this.dadosBancariosData?.cpfCnpj;
    return this.cpfClient;
  }

  getName() {
    if (this.dadosBancariosData && this.dadosBancariosData.nomeBeneficiario) return this.dadosBancariosData?.nomeBeneficiario;
    return this.userName;
  }

  verifyShipping(){
    switch (this.formaEnvio) {
      case "Email":
    return this.openDialogEmail();
      case "Whatsapp":
    return this.openDialogWhatsapp();
      case "Sms":
    return this.openDialogSms();
  }
}

openDialogEmail() {
  this.infoDataEnvio = this.email.value ?? '';
  this.dialog.open(ModalGenericComponent, {
    width: '384px',
    data: {
      icon: 'warning',
      text: `Tem certeza que deseja enviar o termo de cancelamento para o e-mail abaixo?`,
      highlightText: this.email.value,
      primaryButtonAction: 'close',
      primaryButtonText: 'VOLTAR',
      primaryButtonVariant: 'secondary',
      secundaryButtonText: 'SIM, ENVIAR',
      secundaryButtonVariant: 'primary',
      secundaryButtonAction: () => {this.dialog.closeAll(); this.save()}
    },
  });
}

openDialogWhatsapp() {
  this.infoDataEnvio = this.formatarNumeroTelefone(this.celular.value).replace(/[^\d]/g, "");
  const numeroFormatado = this.formatarNumeroTelefone(this.celular.value);
  this.dialog.open(ModalGenericComponent, {
    width: '384px',
    data: {
      icon: 'warning',
      text: `Tem certeza que deseja enviar o termo de cancelamento para o whatsapp abaixo?`,
      highlightText: numeroFormatado,
      primaryButtonAction: 'close',
      primaryButtonText: 'VOLTAR',
      primaryButtonVariant: 'secondary',
      secundaryButtonText: 'SIM, ENVIAR',
      secundaryButtonVariant: 'primary',
      secundaryButtonAction: () => {this.dialog.closeAll(); this.save()}
    },
  });
}

openDialogSms() {
  this.infoDataEnvio = this.formatarNumeroTelefone(this.celular.value).replace(/[^\d]/g, "");
  const numeroFormatado = this.formatarNumeroTelefone(this.sms.value);
  this.dialog.open(ModalGenericComponent, {
    width: '384px',
    data: {
      icon: 'warning',
      text: `Tem certeza que deseja enviar o termo de cancelamento para o SMS abaixo?`,
      highlightText: numeroFormatado,
      primaryButtonAction: 'close',
      primaryButtonText: 'VOLTAR',
      primaryButtonVariant: 'secondary',
      secundaryButtonText: 'SIM, ENVIAR',
      secundaryButtonVariant: 'primary',
      secundaryButtonAction: () => {this.dialog.closeAll(); this.save()}
    },
  });
}

formatarNumeroTelefone(numero: any): string {
  if (numero) {
    return this.aplicarMascara(numero, ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]);
  } else {
    return '';
  }
}

aplicarMascara(valor: string, mascara: any[]): string {
  const numeros = valor.replace(/\D/g, '');
  const valorArray = numeros.split('');
  const mascaraArray = mascara;

  let resultado = '';
  let j = 0;

  for (let i = 0; i < mascaraArray.length; i++) {
    if (mascaraArray[i] instanceof RegExp) {
      resultado += mascaraArray[i].test(valorArray[j]) ? valorArray[j] : '';
      j++;
    } else {
      resultado += mascaraArray[i];
    }
  }

  return resultado;
}

goToPlan(){
  this.showSendCard = false
  this.showCancel = false
  window.location.reload();
}

next(){
  this.showCancel = false
  this.showSendCard = true
  this.termSent = false
  document.documentElement.scrollTop = 0
}

getUser() {
  this.email.disable()
  this.sms.disable()
  this.celular.disable()
  this.isLoading = true;
  this.apiService.getUser(this.cpfClient).pipe(
    timeout(30000),
    catchError((error) => {
      if (error instanceof TimeoutError) {
        this.isLoading = false;
        return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
      }
      return throwError(() => error)
    })
  )
    .subscribe({
      next: result => {
          if (result) {
            if(result.cliente){
              const emailValue = result.cliente.contato.email ?? null;
              const celularComDdd = result.cliente.contato.celularComDdd ?? null;
              this.email.patchValue(emailValue);
              this.celular.patchValue(celularComDdd ? celularComDdd.toString() : null);
              this.sms.patchValue(celularComDdd ? celularComDdd.toString() : null);
            }
          }
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
        console.log(error)
      }
    })
}

}
