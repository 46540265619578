import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalCancelComponent } from 'src/app/components/modal-cancel/modal-cancel.component';
import { IContact } from 'src/app/models/register.interface';

@Component({
  selector: 'yfs-register-contact',
  templateUrl: './register-contact.component.html',
  styleUrls: ['./register-contact.component.scss']
})
export class RegisterContactComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();

  public celularComDdd = new FormControl('', [Validators.required]);
  public telefoneResidencialComDdd = new FormControl ('');
  public email = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]);
  public timeoutError = false;
  public genericError = false;
  public isLoading = false;

  public celularMask = ['(', /\d/, /\d/, ')', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public telefoneMask = ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  constructor(public dialog: MatDialog) { }
  ngOnInit() {
    if (localStorage.getItem("contato")) {
      let contato: IContact;
      contato = JSON.parse(atob(localStorage.getItem('contato') || '{}'))
      this.bidingValuesContact(contato)
    }
  }


  goBack() {
    this.currentFlowChanged.emit("address");
  }

  bidingValuesContact(contato: IContact) {
    this.celularComDdd.setValue(contato.celularComDdd ? contato.celularComDdd.toString() : null)
    this.telefoneResidencialComDdd.setValue(contato.telefoneResidencialComDdd ? contato.telefoneResidencialComDdd.toString() : null);
    this.email.setValue(contato.email ?? '');
  }

  nextStep() {
    this.celularComDdd.markAllAsTouched()
    this.telefoneResidencialComDdd.markAllAsTouched()
    this.email.markAllAsTouched()

    if (this.celularComDdd.valid && this.email.valid) {

      const celularValue = this.celularComDdd.value?.replace(/[^0-9]/g, '');
      const telefoneValue = this.telefoneResidencialComDdd.value?.replace(/[^0-9]/g, '');

      let contato: IContact = {
        celularComDdd: celularValue ? parseInt(celularValue) : null,
        telefoneResidencialComDdd: telefoneValue ? parseInt(telefoneValue) : null,
        email: this.email.value ?? undefined,
      };

      localStorage.setItem("contato", btoa(JSON.stringify(contato)))
      this.currentFlowChanged.emit("verifyData")
    }
  }

  openDialog() {
    this.dialog.open(ModalCancelComponent, {
      width: '381px'
    })
  }

}
