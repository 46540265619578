<div class="dialog-content" mat-dialog-content>
  <div class="table">
    <table mat-table [dataSource]="dataSource" #secondTable #secondTableSort="matSort" matSort
        (matSortChange)="announceSortChange2($event)">

        <ng-container matColumnDef="nomeTarifa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by number">
                Descritivo
            </th>
            <td mat-cell *matCellDef="let element"> {{element.nomeTarifa}}</td>
        </ng-container>

        <ng-container matColumnDef="dataCadastro">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by number">
              Data
          </th>
          <td mat-cell *matCellDef="let element"> {{element.dataCadastro | date:'dd/MM/yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="valor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by number">
              Valor
          </th>
          <td mat-cell *matCellDef="let element"> {{element.valor | currency }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsResume"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsResume;"></tr>
    </table>
</div>
</div>
