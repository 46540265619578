import { Component, Input } from '@angular/core';

@Component({
  selector: 'yfs-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @Input() tooltipText: string = '';
  @Input() wrap: boolean = false;
  showTooltip: boolean = false;
}
