import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'yfs-home',
  templateUrl: './home.component.html',
  providers: [
    { provide: CarouselConfig, useValue: { interval: 5000, noPause: false, showIndicators: true } }
 ],
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent {

  public urlVideo: string = environment.urlVideo;

  constructor(private router: Router) {}

  goToSimulation() {
    this.router.navigate(["simulation"]);
  }
}
