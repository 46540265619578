<div class="dialog-content" mat-dialog-content>
    <div style="display: flex;">
    <div style="display: flex; flex-direction: column;">
        <div style="display: flex; flex-direction: column;">
            <span class="title">Insira aqui o arquivo com o termo de {{data.type}} assinado</span>
            <span class="subtitle">Somente um arquivo em PDF de até 5mb.</span>
        </div>

        <a (click)="fileInput.click()">Escolher o arquivo</a>
        <input hidden data-test="input-file" (change)="onFileSelected($event)" #fileInput type="file" accept=".pdf"
            id="file">
        <div class="file-name-label">{{fileName}}<span *ngIf="fileName" (click)="onRemoveFile()"><img class="trash-icon"
                    src="assets/svg/trash.svg" alt="Ícone para deletar"></span></div>
        <span data-test="error-message-file-size" *ngIf="fileSizeError" class="error-message"><img
                src="assets/svg/atention.svg" alt="Ícone de atenção"> O arquivo ultrapassou o tamanho máximo de 5MB</span>
    </div>
    <div style="display: flex;">
        <yfs-button data-test="button-send" label="ENVIAR" type="primary" [press]="handleUploadFile" class="btn-send" [state]="!fileName || fileSizeError ? 'disabled' : 'enabled'"></yfs-button>
    </div>
</div>
</div>
