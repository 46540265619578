import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';

@Component({
  selector: 'yfs-seal-eligibility',
  templateUrl: './seal-eligibility.component.html',
  styleUrls: ['./seal-eligibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SealEligibilityComponent implements OnInit, OnChanges {
  protected showdetailsButton: boolean = true;

  @Input()
  set punctuality(value: number | undefined) {
    this.punctualityText = this.convertStatus(value);
    this.cdref.detectChanges();
  }

  @Input()
  set discharge(value: number | undefined) {
    this.dischargeText = this.convertStatus(value);
    this.cdref.detectChanges();
  }

  @Input()
  set restriction(value: number | undefined) {
    this.restrictionText = this.convertStatusRestriction(value);
    this.cdref.detectChanges();
  }

  @Input()
  set showDetailButton(value: boolean | null) {
    this.showdetailsButton = value ?? true;
    this.cdref.detectChanges();
  }

  @Input() showProofPaymentButton: boolean = false;
  @Output() detailClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() proofClick: EventEmitter<void> = new EventEmitter<void>();

  protected punctualityText: string = 'SIM';
  protected dischargeText: string = 'SIM';
  protected restrictionText: string = 'SIM';
  public statusText: string = '';

  public statusIcon: string = '';
  public bgColor: string = '';
  public proofPaymentAlowedPermissions = [EPermissaoAcesso.REGULARIZAR_RESTRICAO_CREDITO]

  constructor(
    private cdref: ChangeDetectorRef) { }


  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let punctuality = changes['punctuality'].currentValue;
        let discharge = changes['discharge'].currentValue;
        let restriction = changes['restriction'].currentValue;
        this.verifyStatusEligibility(punctuality, discharge, restriction)
      }
    }
    this.atualizarCores();
  }

  convertStatus(valor: number | undefined): string {
    switch (valor) {
      case 0:
        return "Em andamento";
      case 1:
        return "Atingida";
      case 2:
        return "Não atingida";
      default:
        return "";
    }
  }

  convertStatusRestriction(valor: number | undefined): string {
    switch (valor) {
      case 0:
        return "Não consultada";
      case 1:
        return "Nada Consta";
      case 2:
        return "Consta Pendência";
      default:
        return "";
    }
  }

  atualizarCores(): void {
    switch (this.statusText) {
      case 'ATIVO':
        this.bgColor = 'var(--color-functional-positive-1)';
        this.statusIcon = 'check_circle';
        break;
      case 'ELEGÍVEL':
        this.bgColor = 'var(--color-primary-3)';
        this.statusIcon = 'thumb_up_outline';
        break;
      case 'INELEGÍVEL':
        this.bgColor = 'var(--color-functional-danger-2)';
        this.statusIcon = 'cancel_outline';
        break;
      default:
        this.bgColor = 'var(--color-functional-positive-1)';
        this.statusIcon = 'check_circle';
        break;
    }
    this.cdref.detectChanges();
  }

  showDetail(): void {
    this.detailClick.emit();
  }

  openProofPayment(): void {
    this.proofClick.emit();
  }


  private verifyStatusEligibility(punctuality :number, discharge:number, restriction:number) {
    if (punctuality === 2 || discharge === 2 || restriction === 2) {
      this.statusText = "INELEGÍVEL";
      return;
    }

    if (punctuality === 1 && discharge === 1 && restriction === 1) {
      this.statusText = "ELEGÍVEL";
      return;
    }

    this.statusText = "ATIVO";
    this.cdref.detectChanges();
  }
}
