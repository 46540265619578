<div class="dialog-content" mat-dialog-content>
    <img alt="Ícone de atenção" src="assets/svg/warning-icon.svg" />

    <div  class="dialog-text">
        <span>O boleto será enviado automaticamente para</span>
        <span>o e-mail vinculado ao contrato atual</span>
    </div>

    <div *ngIf="data.email" class="dialog-contract-area">
        <span class="name">{{data.email}}</span>
    </div>

    <div class="dialog-buttons">
        <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="closeModal()"></yfs-button>
        <yfs-button state="enabled" label="CONTINUAR COM O ENVIO" type="primary" (click)="sendEmail()"></yfs-button>
    </div>
</div>
