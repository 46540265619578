<yfs-header [flowSeller]="true"></yfs-header>
    <section class="container-content">
      <div class="button-back">
        <yfs-link-back [text]="back" (click)="getBackHandle()"></yfs-link-back>
      </div>
      <h1>SIMULAÇÃO</h1>
      <div class="contant-flow">
        <div
          class="container-form"
          *ngIf="currentFlow === 'start' && !timeoutError"
        >
          <mat-card class="form">
            <mat-card-content class="form-content">
              <mat-form-field appearance="fill">
                <mat-label *ngIf="isLogged()">Insira seu nome</mat-label>
                <mat-label *ngIf="!isLogged()">Insira seu nome e marque a caixa de seleção abaixo</mat-label>
                <input data-test="input-name" type="text" [formControl]="name" (keyup.enter)="submitForm()" matInput>
                <mat-error *ngIf="name.invalid && (name.dirty || name.touched)" data-test="warning-label-name">
                  Preencha o campo acima
              </mat-error>
              </mat-form-field>
              <div *ngIf="!isLogged()" class="g-recaptcha recaptcha-card">
                <re-captcha
                  data-size="compact"
                  (resolved)="resolved($event)"
                  [siteKey]="siteKey"
                ></re-captcha>
                <label *ngIf="invalidCheck">O checkbox é obrigatório</label>
              </div>
              <div class="container-button">
                <yfs-button state="enabled" label="PROSSEGUIR" type="primary" (click)="submitForm()" data-test="button-proceed-recaptcha"></yfs-button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <yfs-simulation-category
          *ngIf="currentFlow === 'category'"
          (currentFlowChanged)="changeCurrentFlow($event)"
        ></yfs-simulation-category>
        <yfs-simulation-product-view
          *ngIf="currentFlow === 'productView'"
          [idProduct]="idProduct"
        ></yfs-simulation-product-view>
        <yfs-simulation-products
          *ngIf="currentFlow === 'product'"
          [productsList]="productsList"
          (currentFlowChanged)="changeCurrentFlowProduct($event)"
        ></yfs-simulation-products>

        <yfs-alert-box
          *ngIf="timeoutError"
        ></yfs-alert-box>
      </div>
    </section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
