import { AbstractControl } from '@angular/forms';

export class LocalValidators {

    static cpfValidator(control: AbstractControl): { [key: string]: boolean } | null {

        const value = control.value;
        if (!value) {
            return null;
        }
        const normalize = value.replace(/[^0-9]/g, '')
        if (normalize.length < 11) {
            return { 'invalidCpf': true };
        }
        return null;
    }

    static rgValidator(control: AbstractControl): { [key: string]: boolean } | null {

        const value = control.value;
        if (!value) {
            return null;
        }
        const normalize = value.replace(/[^a-zA-Z0-9]/g, '')
        if (normalize.length > 15) {
            return { 'invalidRg': true };
        }
        return null;
    }

    static cepValidator(control: AbstractControl): { [key: string]: boolean } | null {

        const value = control.value;
        if (!value) {
            return null;
        }
        const normalize = value.replace(/[^0-9]/g, '')
        if (normalize.length < 8) {
            return { 'invalidCep': true };
        }
        return null;
    }

    static nomeValidator(control: AbstractControl): { [key: string]: boolean } | null {

        const value = control.value;
        if (!value) {
            return null;
        }
        const nomeSobrenome = value.split(' ');
        const nome = nomeSobrenome[0]
        const sobreNome = nomeSobrenome[1]
        if (!nome || !sobreNome) {
            return { 'invalidName': true };;
        }
        return null;
    }

    static cpfCpnjValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const value = control.value;
        if (!value) {
            return null;
        }
        const normalize = value.replace(/[^0-9]/g, '')
        if (normalize.length > 11 && normalize.length < 14) {
            return { 'invalidCpfCnpj': true };
        }
        else if (normalize.length < 11) {
            return { 'invalidCpfCnpj': true };
        }
        return null;
    }

}
