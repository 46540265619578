<div class="dialog-content" mat-dialog-content>
  <div class="title"><h1>Resumo de elegibilidade</h1></div>
  <div class="elegibilidade">
      <yfs-seal-eligibility
      [punctuality]="pontualidade"
      [discharge]="quitacao"
      [restriction]="restricao"
      [showDetailButton]="false"
      ></yfs-seal-eligibility>
    <div class="card-content">
        <mat-card class="mini-card">
            <span class="h1">Parcelas pontuais</span>
            <span
                class="h2">{{pontuais}}/{{totalPontuais}}</span>
        </mat-card>
        <mat-card class="mini-card">
            <span class="h1">Parcelas não pontuais</span>
            <span class="h2">{{naoPontuais}}</span>
        </mat-card>
    </div>
</div>

  <div class="table">
    <table mat-table [dataSource]="dataSource" #secondTable #secondTableSort="matSort" matSort
        (matSortChange)="announceSortChange2($event)">

        <ng-container matColumnDef="recarga">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by number">
                Recarga
            </th>
            <td mat-cell *matCellDef="let element"> {{element.recarga}}</td>
        </ng-container>

        <ng-container matColumnDef="pontual">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by pontual">
              Pontualidade
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="getPontualidadeColor(element.pontual)">
            <mat-icon aria-hidden="false"  aria-label="Ícone" class="material-symbols-outlined" [fontIcon]="getPontualidadeIcon(element.pontual)"></mat-icon>
          </td>
      </ng-container>

        <ng-container matColumnDef="dataVencimento">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by dataVencimento">
                Data de vencimento
            </th>
            <td mat-cell *matCellDef="let element"> {{element.dataVencimento}}</td>
        </ng-container>

        <ng-container matColumnDef="dataPagamento">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by dataPagamento">
                Data de pagamento
            </th>
            <td mat-cell *matCellDef="let element"> {{element.dataPagamento}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by statusParcelaDescricao">
                Status
            </th>
            <td mat-cell *matCellDef="let element"> {{getStatusName(element.statusParcelaDescricao)}}
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
    </table>
</div>

  <div class="button-content">
      <yfs-button state="enabled" [label]="data.primaryButtonText" [type]="data.primaryButtonVariant"
          (click)="handlePrimaryButton()" [color]="data.primaryButtonColor ? data.primaryButtonColor : ''"></yfs-button>
  </div>
</div>
