<div class="dialog-content" mat-dialog-content>
    <span class="title">Prévia</span>
    <span class="subtitle">{{data?.templateName}}</span>
    <div class="container-preview">
        <div class="content-preview" [innerHTML]="data.previewContent"></div>
    </div>

    <div class="cards-container">
        <div class="card">
            <span class="title-card">Rotinas</span>
            <div class="wildcard-content">
                <div class="wildcard" *ngIf="data?.rotinas">{{data?.rotinas.nomeExibicao}}</div>
            </div>
        </div>
        <div class="card">
            <span class="title-card">Destinatários</span>
            <div class="wildcard-content">
                <div class="wildcard" *ngFor="let destinatario of data?.destinatarios">{{destinatario?.nome}}</div>
            </div>
        </div>
        <div class="card">
            <span class="title-card">Anexos</span>
            <div class="wildcard-content">
                <div class="wildcard" *ngFor="let anexo of data?.anexos">{{anexo?.nomeExibicao}}</div>
            </div>
        </div>
    </div>

    <yfs-button *ngIf="!data?.openEditTemplate" class="close-button" state="enabled" label="FECHAR" type="primary" (click)="closeModal()"></yfs-button>

    <div *ngIf="data?.openEditTemplate" class="button-container">
        <yfs-button  state="enabled" label="FECHAR" type="secondary" (click)="closeModal()"></yfs-button>
        <yfs-button  state="enabled" label="EDITAR TEMPLATE" type="primary" (click)="openEditTemplate()"></yfs-button>
    </div>
</div>