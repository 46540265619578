import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDocumentationComponent } from '../modal-documentation/modal-documentation.component';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';
import { DataService } from 'src/app/services/data-service';

@Component({
  selector: 'yfs-modal-email',
  templateUrl: './modal-email.component.html',
  styleUrls: ['./modal-email.component.scss']
})
export class ModalEmailComponent {

  public dataList: any[] = [];
  public buttonLabel = 'EDITAR E-MAIL';
  public isEditMode = false;
  public isLoading = false;
  public emailCliente: string = '';
  public selectedIds: number[] = [];
  public contrato: string = '';
  public cpf: string = '';
  public email: FormControl = new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email, Validators.maxLength(50)]);

  constructor(
    public dialogRef: MatDialogRef<ModalEmailComponent>,
    public dialog: MatDialog,
    private apiService: ApiHttpService,
    private dataService: DataService
  ) {

   }

   ngOnInit() {
    this.dataList = this.dataService.getDataList();
    this.emailCliente = this.dataService.getEmail();
    this.selectedIds = this.dataService.getSelectedIds() || [];
    this.contrato = this.dataService.getContrato();
    this.cpf = this.dataService.getCPF();
    this.email = new FormControl({ value: this.emailCliente, disabled: true }, [Validators.required, Validators.email, Validators.maxLength(50)]);
  }

   back() {
    this.dialogRef.close();

    const dialogRef = this.dialog.open(ModalDocumentationComponent, {
      width: '440px',
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }


  onButtonClick = () => {
    if (this.isEditMode) {
      this.save();
    } else {
      this.edit();
    }
  }

  edit() {
    this.email.enable();
    this.isEditMode = true;
    this.buttonLabel = 'SALVAR';
  }

  save() {
    this.email.disable();
    this.buttonLabel = 'EDITAR E-MAIL';
    this.isEditMode = false;
    this.isLoading = true;
    this.apiService
      .postAtualizarEmail(this.cpf, this.email.value)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result: any) => {
          this.emailCliente = result.body.email;
          this.dataService.setData(this.dataList, this.emailCliente, this.selectedIds, this.contrato, this.cpf);
          this.isLoading = false;
          this.openDialogSucessUpload('E-mail atualizado com sucesso');
        },
        error: (error: any) => {
          this.isLoading = false;
          console.log(error);
          this.openDialogErrorDocument();
        },
      });
  }

  openDialogSucessUpload(menssage: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: menssage,
        icon: 'success',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogErrorDocument() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não foi possível atualizar o e-mail, tente novamente mais tarde.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  next() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: '526px',
    });
  }

}
