import { Component, ViewChild } from '@angular/core';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { FormControl } from '@angular/forms';
import { dateFormat } from 'src/app/shared/date-format';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { EStatus } from 'src/app/shared/enums/status.enum';
import { IContractedItem, IContractedPlans } from 'src/app/models/contracted-plans.interface';
import { MatDialog } from '@angular/material/dialog';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { UserService } from 'src/app/services/user.service';

export interface StatusOption {
  label: string;
  value: number;
}

interface Documento {
  nome: string;
  assinado: boolean;
  dataExpiracao: string | null;
  tipoAssinatura: string;
}

@Component({
  selector: 'yfs-contracted-plans',
  templateUrl: './contracted-plans.component.html',
  styleUrls: ['./contracted-plans.component.scss']
})
export class ContractedPlansComponent {

  @ViewChild('mySel') select: MatSelect | undefined;

  public dataConsulta = new FormControl('', [dateFormat.dateValidator]);
  public searchString = new FormControl('');
  public status = new FormControl('');
  public concessionaria = new FormControl();
  public dataConsultaLabel: Date | undefined
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public statusOptions: StatusOption[] | undefined;
  public length = 50;
  public pageSize = 10;
  public pageIndex = 0;
  public pageSizeOptions = [5, 10, 20];
  public isLoading = false;
  public listContractedPlans: IContractedPlans[] | undefined
  public contratosIniciados = undefined;
  public contratosFinalizados = undefined;
  public currentFlow = "contractedPlans"
  public contractedPlanSelected: IContractedItem | undefined
  public hasValue = true;
  public statusControl = "";
  public cpfSelected: string | undefined
  public checkedAll = true;
  public interteminate = false;
  public concessionarias: any[] = [];
  public listaConcessionarias: any[] = []
  public selectedConcessionarias: number[] = []
  public idsConcessionarias: number[] = []
  public termoAdesaoAssinado = true;
  public checked = false;

  displayedColumns: string[] = ['numeroContrato', 'nomeCliente', 'cpf', 'dataContratacao', 'statusContrato'];
  dataSource: any

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private apiService: ApiHttpService,
    private customPaginator: MatPaginatorIntl,
    public dialog: MatDialog,
    private router: Router,
    private userService: UserService
  ) {
    customPaginator.itemsPerPageLabel = "Itens por página";
    this.statusOptions = [
      {
        label: 'Em Aberto',
        value: EStatus.EM_ABERTO
      },
      {
        label: 'Estorno finalizado',
        value: EStatus.ESTORNO_FINALIZADO
      },
      {
        label: 'Estorno em processo',
        value: EStatus.ESTORNO_EM_PROCESSO
      }, 
      {
        label: 'Elegível',
        value: EStatus.ELEGIVEL
      },
      {
        label: 'Ativo',
        value: EStatus.ATIVO
      },
      {
        label: 'Finalizado',
        value: EStatus.FINALIZADO_CDC
      },
      {
        label: 'Inelegível',
        value: EStatus.INELEGIVEL
      },
      {
        label: 'Cancelado',
        value: EStatus.CANCELADO
      },
      {
        label: 'Inativo',
        value: EStatus.INATIVO
      },
      {
        label: 'Inatividade finalizada',
        value: EStatus.FINALIZADO_INATIVO
      },
      {
        label: 'Desistência em andamento',
        value: EStatus.DESISTENCIA_EM_PROCESSO
      },
      {
        label: 'Desistência finalizado',
        value: EStatus.DESISTECIA_FINALIZADO
      },
      {
        label: 'Aguardando assinatura do termo de adesão',
        value: EStatus.ASSINATURA_PENDENTE_TERMO_ADESAO
      },
      {
        label: 'Aguardando assinatura do termo de cancelamento',
        value: EStatus.ASSINATURA_PENDENTE_TERMO_CANCELAMENTO
      },
      {
        label: 'Liberação de valores CDC',
        value: EStatus.LIBERACAO_VALORES_CDC_EM_PROCESSO
      },
      {
        label: 'Liberação de valores QUITADO ELEGÍVEL',
        value: EStatus.LIBERACAO_VALORES_QUITADO_ELEGIVEL_EM_PROCESSO
      },
      {
        label: 'Liberação de valores QUITADO INELEGÍVEL',
        value: EStatus.LIBERACAO_VALORES_QUITADO_INELEGIVEL_EM_PROCESSO
      }
    ]
  }

  ngOnInit() {
    this.concessionariaData();
  }

  parseJsonString(jsonString: any) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Erro ao fazer parsing do JSON:", error);
      return null;
    }
  }

  @ViewChild('firstTableSort')
  firstTableSort!: MatSort;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  selectClientPlan(row: any) {
    this.contractedPlanSelected = row;
    this.currentFlow = "clientPlan"
  }

  setPageStart() {
    this.pageIndex = 0;
    this.search()
  }

  concessionariaData() {
    const user = this.userService.getCurrentUser();
    if (user) {
      this.concessionarias = user.Concessionarias;
    }

    if (!Array.isArray(this.concessionarias)) {
      this.concessionarias = [this.concessionarias];
    }

    const concessionariasFormatadas = this.concessionarias.map(this.parseJsonString);
    this.listaConcessionarias = concessionariasFormatadas;
    this.selectedConcessionarias = this.listaConcessionarias.map(concessionaria => concessionaria.IdConcessionaria);
    this.concessionaria.setValue(this.selectedConcessionarias);
    this.idsConcessionarias = this.listaConcessionarias.map(concessionaria => concessionaria.IdConcessionaria);
  }

  checkHandle(IdConcessionaria: number) {
    if (this.selectedConcessionarias.includes(IdConcessionaria)) {
      this.selectedConcessionarias = this.selectedConcessionarias.filter(item => item !== IdConcessionaria);
    } else {
      this.selectedConcessionarias.push(IdConcessionaria);
    }

    this.idsConcessionarias = this.selectedConcessionarias.slice();

    if (this.selectedConcessionarias.length === this.listaConcessionarias.length) {
      this.checkedAll = true;
    } else {
      this.checkedAll = false;
    }
    this.concessionaria.setValue(this.selectedConcessionarias);
    this.search();
  }

  selectAllArquivo() {
    if (this.selectedConcessionarias.length === this.listaConcessionarias.length) {
      this.selectedConcessionarias = [];
      this.idsConcessionarias = [];
      this.checkedAll = false;
    } else {
      this.selectedConcessionarias = this.listaConcessionarias.map(concessionaria => concessionaria.IdConcessionaria);
      this.idsConcessionarias = this.selectedConcessionarias.slice();
      this.checkedAll = true;
    }
    this.concessionaria.setValue(this.selectedConcessionarias);
    this.search();
  }


  search() {
    const serachValue = this.searchString.value ?? ""
    let dateValue = this.dataConsulta.value ?? ""
    const statusValue = this.status.value ?? ""
    let searchIsValid = true;
    let assinaturaPendente = this.checked

    if (dateValue && this.dataConsulta.invalid) searchIsValid = false;
    if (dateValue && this.dataConsulta.valid)
      dateValue = this.convertStringToDate(dateValue)

    if (searchIsValid) {
      this.isLoading = true
      this.apiService.getContractedPlans(serachValue, dateValue, statusValue, assinaturaPendente, this.idsConcessionarias, this.pageIndex + 1, this.pageSize).pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
          }
          return throwError(() => error)
        })
      )
        .subscribe({
          next: result => {
            this.isLoading = false;
            this.listContractedPlans = result.planosContratados.items;
            this.contratosIniciados = result.contratosIniciadosMesCorrente;
            this.contratosFinalizados = result.contratosFinalizadosMesCorrente;
            this.dataSource = new MatTableDataSource<IContractedPlans>(this.listContractedPlans);
            if (this.listContractedPlans && this.listContractedPlans.length === 0) this.hasValue = false
            else this.hasValue = true;
            this.dataSource.sort = this.firstTableSort;
            this.length = result.planosContratados.totalCount
            this.customPaginator.getRangeLabel = this.getRangeLabel
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: any) => {
              if (sortHeaderId === 'statusContrato') {
                return data.statusContratoDescricao;
              }
              return data[sortHeaderId];
            };
            this.isLoading = false;
          },
          error: error => {
            this.isLoading = false;
            console.log(error)
          }
        })
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.search()
  }

  getIcon(status: number) {
    switch (status) {
      case 0:
      case 10:
        return { icon: "nest_clock_farsight_analog_outline", style: "outlined" };
      case 1:
        return { icon: "check_circle_outline", style: "outlined" };
      case 2:
        return { icon: "check_circle", style: "filled" };
      case 3:
        return { icon: "warning", style: "outlined" };
      case 4:
        return { icon: "cancel", style: "outlined" };
      case 7:
        return { icon: "check_circle", style: "outlined" };
      case 5:
        return { icon: "check_circle", style: "filled" };
      case 6:
      case 9:
      case 11:
      case 12:
      case 16:
      case 17:
        return { icon: "attach_money", style: "outlined" };
      case 8:
      case 13:
        return { icon: "block", style: "outlined" };
      case 14:
      case 15:
        return { icon: "signature", style: "outlined" };
      default:
        return { icon: "", style: "" };
    }
  }

  getIconColor(status: number) {
    switch (status) {
      case 0:
        return "var(--color-functional-positive-1)";
      case 10:
        return "var(--color-complementary-yellow-1)";
      case 1:
        return "var(--color-primary-2)";
      case 2:
        return "var(--color-primary-3)";
      case 3:
      case 11:
      case 12:
      case 14:
      case 15:
      case 16:
      case 17:
        return "var(--color-functional-warning-1)";
      case 7:
        return "var(--color-neutral-gray-4)";
      default:
        return "var(--color-neutral-gray-4)";
    }
  }

  getStatusDescription(status: number) {
    switch (status) {
      case 0:
        return "Em Aberto";
      case 1:
        return "Ativo";
      case 2:
        return "Elegível";
      case 3:
        return "Inelegível";
      case 4:
        return "Cancelado"
      case 5:
        return "Desistência finalizado"
      case 6:
        return "Liberação de valores finalizado"
      case 7:
        return "Finalizado"
      case 8:
        return "Inativo"
      case 9:
        return "Estorno finalizado"
      case 10:
        return "Desistência em andamento"
      case 11:
        return "Liberação de valores CDC"
      case 12:
        return "Estorno em processo"
      case 13:
        return "Inatividade finalizada"
      case 14:
        return 'Aguardando assinatura do termo de adesão';
      case 15:
        return 'Aguardando assinatura do termo de cancelamento';
      case 16:
        return 'Liberação de valores QUITADO ELEGÍVEL';
      case 17:
        return 'Liberação de valores QUITADO INELEGÍVEL';
      default:
        return ""
    }
  }

  getTooltipText(element: any): string {
    if (element.documentos && element.documentos.length > 0) {
      const termoAdesao = element.documentos.find((doc: Documento) => doc.nome === 'TermoAdesao');

      if (termoAdesao) {
        if (!termoAdesao.assinado) {
          if (termoAdesao.tipoAssinatura === 'Manual') {
            return `Documentos pendentes de assinatura: • Termo de adesão ao Liberacred`;
          } else if (termoAdesao.tipoAssinatura === 'Eletrônica') {
            return `Documentos pendentes de assinatura: • Termo de adesão ao Liberacred Assinar até: ${this.formatDate(termoAdesao.dataExpiracao)}`;
          }
        }
      }

      const termoCancelamento = element.documentos.find((doc: Documento) => doc.nome === 'TermoCancelamento');
      if (termoCancelamento && !termoCancelamento.assinado) {
        return `Documentos pendentes de assinatura: • Termo de cancelamento <br> Assinar até: ${this.formatDate(termoCancelamento.dataExpiracao)}`;
      }
    }

    return 'Nenhum documento pendente.';
  }

  formatDate(dateStr: string | null): string {
    if (!dateStr) {
      return 'Data não informada';
    }

    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  shouldShowIcon(element: any): boolean {
    if (element.documentos && element.documentos.length > 0) {
      return element.documentos.some((doc: any) => !doc.assinado);
    }
    return false;
  }

  isTermoAdesaoAssinado(element: any): boolean {
    return element && element.termoAdesaoAssinado === false;
  }

  isTermoCancelamentoAssinado(element: any): boolean {
    return element && element.dataExpiracaoTermoCancelamento === null;
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    const totalPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${totalPages}`;
  }

  changeCurrentFlow(flow: string) {
    this.search()
    this.currentFlow = flow;
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }

  getBack() {
    this.router.navigate(['area']);
  }

  openEditScreen(row: any) {
    this.cpfSelected = row.cpf;
    this.currentFlow = 'clientData'
  }

}
