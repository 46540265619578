<div class="dialog-content" mat-dialog-content>
  <div class="dialog-text">
    <span>Quais documentos deseja reenviar?</span>
  </div>

  <div class="checkbox" *ngFor="let data of dataList">
    <mat-checkbox color="primary" [(ngModel)]="data.checked" (change)="toggleCheckbox(data.idTermoLiberacred)">
      <span>{{ data.descricao }}</span>
    </mat-checkbox>
  </div>

  <div class="dialog-buttons">
    <yfs-button state="enabled" label="FECHAR" type="secondary" (click)="closeModal()"></yfs-button>
    <yfs-button state="enabled" label="PRÓXIMO" type="primary" [state]="checkSelecionado() ? 'enabled' : 'disabled'" [press]="next"></yfs-button>
  </div>
</div>
