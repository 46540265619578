import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { conformToMask } from 'angular2-text-mask';
import { ReleaseValueItem, ReleaseValueResponse } from 'src/app/models/release-values.interface';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { ELiberacao } from 'src/app/shared/enums/tipo-liberacao-enum';

export interface TipoLiberacaoOption {
  label: string;
  value: number;
}

@Component({
  selector: 'yfs-release-values',
  templateUrl: './release-values.component.html',
  styleUrls: ['./release-values.component.scss']
})
export class ReleaseValuesComponent implements AfterViewInit{

  public searchString = new FormControl('');
  public currentFlow = "releaseValues"
  public pageIndex = 0;
  public isLoading = false;
  public hasValue = true;
  public releaseValueSelected: ReleaseValueItem | undefined
  public tipoLiberacaoOptions: TipoLiberacaoOption[] | undefined;
  public tipoLiberacaoControl = "";
  public tipoLiberacao = new FormControl('');
  public listaDados: ReleaseValueResponse[] | undefined

  dataSource: any

  displayedColumns: string[] = ['numeroContrato', 'nomeCliente', 'canalVenda', 'dataSolicitacaoLiberacaoValor', 'tipoLiberacao', 'valor'];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _liveAnnouncer: LiveAnnouncer,
    private apiService: ApiHttpService,
  ) {
    this.tipoLiberacaoOptions = [
      {
        label: 'Desistência',
        value: ELiberacao.CONTRATO
      },
      {
        label: 'CDC',
        value: ELiberacao.CDC
      },
      {
        label: 'Plano Quitado - Elegível',
        value: ELiberacao.QUITADO_ELEGIVEL
      },
      {
        label: 'Plano Quitado - Inelegível',
        value: ELiberacao.QUITADO_INELEGIVEL
      },
    ]
   }

   @ViewChild('releaseTableSort') releaseTableSort = new MatSort();

   ngAfterViewInit() {
    this.search()
  }

  setPageStart() {
    this.pageIndex = 0;
    this.search()
  }

  calcularDiasAtras(data: number): string {
    if (data === 0) {
      return `Solicitado hoje`;
    }
    if (data === 1) {
      return `1 dia atrás`;
    }
    else {
      return `${data} dias atrás`;
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  selectClientPlan(row: any) {
    this.releaseValueSelected = row;
    this.currentFlow = "releaseValuesDetails"
  }

  changeCurrentFlow(flow: string) {
    this.search()
    this.currentFlow = flow;
  }

  getBack() {
    this.router.navigate(['area']);
  }

  search() {
    const searchValue = this.searchString.value ?? ""
    const tipoLiberacaoValue = this.tipoLiberacao.value ?? ""

    this.isLoading = true
    this.apiService.getReleaseValues(searchValue, tipoLiberacaoValue).pipe().subscribe((response) => {
      this.isLoading = false
      this.listaDados = response
      this.dataSource = new MatTableDataSource<ReleaseValueResponse>(this.listaDados);
      this.dataSource.sort = this.releaseTableSort;
    })
  }

}
