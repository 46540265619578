import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'telefone' })
export class TelefonePipe implements PipeTransform {
    transform(value: string|number|null|undefined): string {
        if (!value)  return '-'
        const telefone = value.toString()
        const ddd = telefone.substring(0, 2)
        const primeiraParte = telefone.substring(2, 6)
        const segundaParte = telefone.substring(6, 10)
        return `${ddd} ${primeiraParte}-${segundaParte}`
    }
}
