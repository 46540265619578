import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { ModalCancelComponent } from 'src/app/components/modal-cancel/modal-cancel.component';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { IAddress } from 'src/app/models/register.interface';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { EUfs } from 'src/app/shared/enums/profile-access.enum';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';

@Component({
  selector: 'yfs-register-address',
  templateUrl: './register-address.component.html',
  styleUrls: ['./register-address.component.scss']
})
export class RegisterAddressComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();

  public estado = new FormControl('', [Validators.required]);
  public cep = new FormControl('', [Validators.required, LocalValidators.cepValidator]);
  public cidade = new FormControl('', [Validators.required, Validators.maxLength(60)]);
  public bairro = new FormControl('', [Validators.required, Validators.maxLength(20)]);
  public logradouro = new FormControl('',  [Validators.required, Validators.maxLength(60)]);
  public numero = new FormControl('', [Validators.required, Validators.maxLength(5)]);
  public complemento = new FormControl('', [Validators.maxLength(40)]);
  public timeoutError = false;
  public genericError = false;
  public isLoading = false;
  public listUfs = EUfs;
  public validCep = true;

  public cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

  constructor(private apiService: ApiHttpService, public dialog: MatDialog) { }

  ngOnInit() {
    if (localStorage.getItem("endereco")) {
      let endereco: IAddress;
      endereco = JSON.parse(atob(localStorage.getItem('endereco') || '{}'))
      this.bidingValuesAddress(endereco)
    }
    this.cidade.disable();
    this.estado.disable();
  }

  goBack() {
    this.currentFlowChanged.emit("personalData");
  }

  formatFieldControl(control: any) {
    const controlValue = control.value;
    control.setValue(controlValue.replace(/[^a-zA-Z0-9\s]/g, ''))
  }

  validateCep(event: any) {
    const value = event.target.value
    if (value && value.length > 0) {
      const normalize = value.replace(/[^0-9]/g, '');
      if (normalize.length == 8) {
        this.isLoading = true;
        this.apiService.getAddress(normalize).pipe(
          timeout(30000),
          catchError((error) => {
            if (error instanceof TimeoutError) {
              this.isLoading = false;
              return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
            }
            return throwError(() => error)
          })
        )
          .subscribe({
            next: result => {
              if (result) {
                if (result.status == 200 && result.body?.sucesso) {
                  this.validCep = true;
                  this.bidingValuesAddress(result.body?.endereco)
                  this.bairro.markAllAsTouched();
                } else {
                  this.validCep = false;
                  this.openDialogInformative(false);
                  this.cleanValuesFields();
                }
              }
              this.isLoading = false;
            },
            error: error => {
              this.isLoading = false;
              this.validCep = false;
              this.openDialogInformative(true);
              this.cleanValuesFields();
              console.log(error)
            }
          })
      }
    }
  }

  bidingValuesAddress(endereco: IAddress) {
    this.estado.setValue(endereco.estado ?? endereco.uf ?? '')
    this.cep.setValue(endereco.cep ?? '');
    this.cidade.setValue(endereco.cidade ?? '');
    this.bairro.setValue(endereco.bairro ?? '');
    this.logradouro.setValue(endereco.logradouro ?? '');
    this.numero.setValue(endereco.numero ?? '');
    this.complemento.setValue(endereco.complemento ?? '');
  }

  cleanValuesFields() {
    this.cep.setValue('');
    this.estado.setValue('');
    this.cidade.setValue('');
    this.bairro.setValue('');
    this.logradouro.setValue('');
    this.numero.setValue('');
    this.complemento.setValue('');
  }

  nextStep = () => {
    this.cep.markAllAsTouched()
    this.bairro.markAllAsTouched()
    this.logradouro.markAllAsTouched()
    this.numero.markAllAsTouched()
    this.complemento.markAsTouched()

    if (this.cep.valid && this.bairro.valid && this.logradouro.valid && this.numero.valid) {

      let endereco: IAddress = {
        cep: this.cep.value?.replace("-", "") ?? undefined,
        estado: this.estado.value ?? undefined,
        cidade: this.cidade.value ?? undefined,
        bairro: this.bairro.value ?? undefined,
        logradouro: this.logradouro.value ?? undefined,
        numero: this.numero.value ?? undefined,
        complemento: this.complemento.value ?? undefined
      };

      localStorage.setItem("endereco", btoa(JSON.stringify(endereco)))
      this.currentFlowChanged.emit("contact")
    }
  }

  openDialog() {
    this.dialog.open(ModalCancelComponent, {
      width: '381px'
    })
  }

  openDialogInformative(error: boolean) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: error ? 'Sistema Indisponível. Tente mais tarde.' : 'CEP não encontrado. Consulte o Administrador do sistema',
        icon: error ? 'error' : 'warning',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
        primaryButtonAction: 'close'
      },
    });
  }

}
