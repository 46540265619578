import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface IDataModalGeneric {
  clientName: string;
  contractNumber: string;
  handleCancelPlan: any;
}


@Component({
  selector: 'yfs-modal-cancel-plan',
  templateUrl: './modal-cancel-plan.component.html',
  styleUrls: ['./modal-cancel-plan.component.scss']
})
export class ModalCancelPlanComponent {

  public numberStep = 1;
  public cancelarString = ""
  public buttonCancelType = 'enabled';

  constructor(
    public dialogRef: MatDialogRef<ModalCancelPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataModalGeneric
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }

  checkInput() {
    if (this.cancelarString === 'CANCELAR') {
      this.buttonCancelType = 'enabled';
    }
    else {
      this.buttonCancelType = 'disabled';
    }
  }

  cancelPlanClick() {
    if (this.numberStep == 1) {
      this.buttonCancelType = 'disabled';
      this.numberStep = 2
    }
    if (this.buttonCancelType === 'enabled') {
      this.closeModal()
      this.data.handleCancelPlan()
    }
  }

}
