import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';

@Component({
  selector: 'yfs-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {
  public previousScreen: string = '';
  public nomeProduto = new FormControl('');
  public categoria = new FormControl();
  public status = new FormControl();
  public currentFlow = 'product';
  public hasValue = true;
  public isLoading = false;
  public listaProdutos = [];
  public length = 50;
  public pageSize = 10;
  public pageIndex = 0;
  public pageSizeOptions = [5, 10, 20];
  public selectedCategoria: number[] = [];
  public idsCategoria: number[] = [];
  public checkedAllCategoria = false;
  public selectedStatus: number[] = [];
  public idsStatus: number[] = [];
  public checkedAllStatus = false;
  public idProductSelected = 0;
  public displayedColumns: string[] = ["modelo", "categoria", "valorReferencia", "situacao", "acoes"];
  public dataSource: any;
  public listaCategoria: any;
  public listaStatus: any[] = [
    {
      idStatus: 1,
      nome: "Ativo"
    },
    {
      idStatus: 0,
      nome: "Inativo"
    },
    {
      idStatus: 2,
      nome: "Pendente"
    },
    {
      idStatus: 3,
      nome: "Descontinuado"
    }
  ]

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private apiService: ApiHttpService,
    private customPaginator: MatPaginatorIntl,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.getCategories();
    this.searchProducts();
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { previousScreen: string };
    this.previousScreen = state?.previousScreen || '';
  }

  @ViewChild('firstTableSort')
  firstTableSort!: MatSort;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  setPageStart() {
    this.pageIndex = 0;
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    const totalPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${totalPages}`;
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.searchProducts()
  }

  getBack() {
    if (this.previousScreen === 'parameters') {
      this.router.navigate(['parameters']);
    } else {
      this.router.navigate(['area']);
    }
  }

  openEditProduct(idProduto: number) {
    this.idProductSelected = idProduto;
    this.currentFlow = 'editProduct';
  }

  changeCurrentFlow(flow: string) {
    this.searchProducts()
    this.currentFlow = flow;
  }

  selectAllCategoria() {
    if (this.selectedCategoria.length === this.listaCategoria.length) {
      this.selectedCategoria = [];
      this.idsCategoria = [];
      this.checkedAllCategoria = false;
    } else {
      this.selectedCategoria = this.listaCategoria.map((categoria: any) => categoria.idCategoriaExibicao);
      this.idsCategoria = this.selectedCategoria.slice();
      this.checkedAllCategoria = true;
    }
    this.categoria.setValue(this.selectedCategoria);
    this.setPageStart();
    this.searchProducts();
  }

  checkHandleCategoria(idCategoria: number) {
    if (this.selectedCategoria.includes(idCategoria)) {
      this.selectedCategoria = this.selectedCategoria.filter(item => item !== idCategoria);
    } else {
      this.selectedCategoria.push(idCategoria);
    }

    this.idsCategoria = this.selectedCategoria.slice();

    if (this.selectedCategoria.length === this.listaCategoria.length) {
      this.checkedAllCategoria = true;
    } else {
      this.checkedAllCategoria = false;
    }
    this.categoria.setValue(this.selectedCategoria);
    this.setPageStart();
    this.searchProducts();
  }

  selectAllStatus() {
    if (this.selectedStatus.length === this.listaStatus.length) {
      this.selectedStatus = [];
      this.idsStatus = [];
      this.checkedAllStatus = false;
    } else {
      this.selectedStatus = this.listaStatus.map(status => status.idStatus);
      this.idsStatus = this.selectedStatus.slice();
      this.checkedAllStatus = true;
    }
    this.status.setValue(this.selectedStatus);
    this.setPageStart();
    this.searchProducts();
  }

  checkHandleStatus(idStatus: number) {
    if (this.selectedStatus.includes(idStatus)) {
      this.selectedStatus = this.selectedStatus.filter(item => item !== idStatus);
    } else {
      this.selectedStatus.push(idStatus);
    }

    this.idsStatus = this.selectedStatus.slice();

    if (this.selectedStatus.length === this.listaStatus.length) {
      this.checkedAllStatus = true;
    } else {
      this.checkedAllStatus = false;
    }
    this.status.setValue(this.selectedStatus);
    this.setPageStart();
    this.searchProducts();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getIconColor(status: string) {
    if (status === 'ATIVO') return "var(--color-functional-positive-1)"
    if (status === 'INATIVO') return "var(--color-functional-danger-2)"
    if (status === 'PENDENTE') return "var(--color-complementary-yellow-1)"
    if (status === 'DESCONTINUADO') return "var(--color-neutral-gray-4)"
    return "var(--color-neutral-gray-4)"
  }

  getIcon(status: string) {
    switch (status) {
      case 'ATIVO':
        return "check_circle";
      case 'INATIVO':
        return "cancel";
      case 'PENDENTE':
        return "nest_clock_farsight_analog";
      case 'DESCONTINUADO':
        return "block";
      default:
        return ""
    }
  }

  getStatusDescription(status: string) {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  searchProducts() {
    const searchValue = this.nomeProduto.value ?? ""

    this.isLoading = true;
    this.apiService.getProducts(searchValue, this.idsCategoria, this.idsStatus, this.pageIndex + 1, this.pageSize).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.listaProdutos = result.items;
          this.dataSource = new MatTableDataSource<any>(this.listaProdutos);
          this.dataSource.sort = this.firstTableSort;
          if (this.listaProdutos && this.listaProdutos.length === 0) this.hasValue = false
          else this.hasValue = true;
          this.length = result.totalCount
          this.customPaginator.getRangeLabel = this.getRangeLabel
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  getCategories() {
    this.isLoading = true;
    this.apiService.getCategory().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.listaCategoria = result;
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  openModalNext() {
    this.dialog.open(ModalGenericComponent, {
      width: '393px',
      data: {
        text: 'Você tem certeza de que deseja atualizar os produtos? Esta operação não poderá ser desfeita e leva alguns minutos.',
        primaryButtonAction: 'close',
        primaryButtonText: 'NÃO QUERO',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.updateProducts() },
        secundaryButtonText: 'SIM, ATUALIZAR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openDialogUpdateSucess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Os dados foram atualizados com sucesso.',
        icon: 'success',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR PARA LISTA DE PRODUTOS',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogErrorDocument() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não foi possível atualizar os produtos nesse momento. Tente novamente mais tarde.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.updateProducts(); this.dialog.closeAll() },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary'
      },
    });
  }

  openDialogErrorWait(message: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: message,
        icon: 'wait-blue',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openModalWait() {
    this.dialog.open(ModalGenericComponent, {
      width: '393px',
      data: {
        text: 'Processo foi enviado para execução. Por favor, aguarde até que seja concluído.',
        icon: 'wait-blue',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  updateProducts() {
    this.isLoading = true;
    this.apiService.integrationMtcode().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          if(result.status === 204) {
            this.isLoading = false;
            this.openModalWait()
          }
        },
        error: error => {
          this.isLoading = false;
          if (error?.error?.Errors && Array.isArray(error.error.Errors) && error.error.Errors.length > 0) {
            this.isLoading = false;
            this.openDialogErrorWait(error.error.Errors[0]);
        } else {
            this.isLoading = false;
            this.openDialogErrorDocument();
        }
        }
      })
  }

}
