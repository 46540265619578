import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';

@Component({
  selector: 'yfs-edit-internal-user',
  templateUrl: './edit-internal-user.component.html',
  styleUrls: ['./edit-internal-user.component.scss']
})

export class EditInternalUserComponent {
  public fullName = new FormControl('', [Validators.required, LocalValidators.nomeValidator, Validators.maxLength(100)]);
  public email = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]);
  public emailConfirm = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]);
  public registration = new FormControl('', [Validators.required, Validators.maxLength(100)]);
  public userProfile = new FormControl('', [Validators.required]);
  public timeoutError = false;
  public genericError = false;
  public isLoading = false;
  public registrations = []
  public idUsuario = 0;

  public userProfiles: any;

  constructor(private router: Router, public dialog: MatDialog, private route: ActivatedRoute, private apiService: ApiHttpService) {
    this.route.paramMap.subscribe(params => {
      const login = params.get('login');
      if (login){
        this.getUserData(login);
      }
    });
    this.getPerfilAcesso();
  }

  getBackHandle() {
    this.router.navigate(['/user-management']);
  }

  sanitizeInput(control: FormControl) {
    let inputValue = control.value;
    inputValue = inputValue.replace(/[^\w\d]/g, '');
    control.setValue(inputValue, { emitEvent: false });
  }

  getUserData(login: string) {
    this.isLoading = true;
    const page = 1;
    const pageSize = 10;
    this.apiService.getUserEdit(login, page, pageSize).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(error);
      })
    )
    .subscribe({
      next: (result) => {
        this.isLoading = false;
        if(result.items){
          this.idUsuario = result.items[0].idUsuario;
          this.fullName.setValue(result.items[0].nome);
          this.email.setValue(result.items[0].email);
          this.emailConfirm.setValue(result.items[0].email);
          this.registration.setValue(result.items[0].login);
          this.userProfile.setValue(result.items[0].idPerfilAcesso);
          this.fullName.disable();
          this.email.disable();
          this.emailConfirm.disable();
          this.registration.disable();
        }
     }
  });
  }

  openDialogSaveUser() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Salvar alterações do usuário Interno?',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.sendRequest() },
        secundaryButtonText: 'SIM, SALVAR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogSaveUserSuccess() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Alterações salvas com sucesso.',
        icon: 'success',
        primaryButtonText: 'IR PARA A LISTA DE USUÁRIOS',
        primaryButtonVariant: 'primary',
        primaryButtonAction: () => {
          dialogRef.close();
          this.goToUserList();
        },
      },
    });
  }

  openDialogSaveUserError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Houve um problema ao tentar salvar as alterações do usuário.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.sendRequest() },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogCancel() {
    this.dialog.open(ModalGenericComponent, {
      width: '424px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.goToUserList() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  verifyData() {
    this.fullName.markAsTouched();
    this.email.markAsTouched();
    this.emailConfirm.markAsTouched();
    this.registration.markAsTouched();
    this.userProfile.markAsTouched();
  }

  sendRequest() {
    const usuario = {
      idPerfilAcesso: this.userProfile.value,
      idUsuario: this.idUsuario,
    };
    this.isLoading = true;
    this.apiService
      .patchEditUser(usuario)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if(error.status == 400 || error.status == 404 || error.status == 500){
            this.openDialogSaveUserError()
            this.isLoading = false;
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 200 || result.status == 204){
            this.isLoading = false;
            this.openDialogSaveUserSuccess();
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.openDialogSaveUserError();
          console.log(error);
        },
      });
  }

  
  goToUserList() {
    this.router.navigate(['/user-management']);
  }

  getPerfilAcesso() {
    this.isLoading = true;
    this.apiService.getPerfilAcesso().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result && result.length > 0) {
            this.userProfiles = result.filter((el: { interno: any; }) => el.interno);
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

}
