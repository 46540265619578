import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IDataModalGeneric {
  text: string;
  textBold?: string;
  secondaryText?: string;
  icon: string;
  primaryButtonAction: any;
  primaryButtonText: string;
  primaryButtonVariant: string;
  primaryButtonColor?: string;
  secundaryButtonAction?: any;
  secundaryButtonText?: string;
  secundaryButtonVariant?: string;
  secundaryButtonColor?: string;
  highlightText?: string;
  highlightTextInner?: string;
}

@Component({
  selector: 'yfs-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss']
})
export class ModalGenericComponent {

  constructor(
    public dialogRef: MatDialogRef<ModalGenericComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataModalGeneric
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }

  handlePrimaryButton() {
    if (this.data.primaryButtonAction === 'close')
      this.closeModal()
    else {
      this.data.primaryButtonAction()
    }
  }

  handleSecundaryButton() {
    if (this.data.secundaryButtonAction === 'close')
      this.closeModal()
    else {
      this.closeModal()
      this.data.secundaryButtonAction()
    }
  }

  getIcon() {
    let urlIcon = 'assets/svg/'
    switch (this.data.icon) {
      case 'warning':
        return urlIcon + 'warning-icon.svg';
      case 'waiting':
        return urlIcon + 'waiting-icon.svg';
      case 'wait-blue':
        return urlIcon + 'wait-blue.svg';
      case 'error':
        return urlIcon + 'error-icon.svg';
      case 'info':
        return urlIcon + 'info-icon.svg';
      case 'success':
        return urlIcon + 'success.svg';
      default:
        return urlIcon + 'warning-icon.svg'
    }
  }

}
