<div class="dialog-content" mat-dialog-content>
    <div class="container-content">
        <div class="flex-column">
            <div class="flex-column">
                <span class="title">{{data.title}}</span>
                <span class="subtitle">{{data.subtitle}}</span>
            </div>

            <a (click)="fileInput.click()">Escolher o arquivo</a>
            <input hidden data-test="input-file" (change)="onFileSelected($event)" #fileInput type="file" accept=".html"
                id="file">
            <div class="file-name-label">{{fileName}}<span *ngIf="fileName" (click)="onRemoveFile()"><img
                        class="trash-icon" src="assets/svg/trash.svg" alt="Ícone para deletar"></span></div>
            <span data-test="error-message-file-size" *ngIf="fileSizeError" class="error-message"><img
                    src="assets/svg/atention.svg" alt="Ícone de atenção"> O arquivo ultrapassou o tamanho máximo de
                5MB</span>
        </div>
        <div style="display: flex;">
            <yfs-button data-test="button-send" label="ENVIAR" type="primary" [press]="handleUploadFile"
                class="btn-send" [state]="!fileName || fileSizeError ? 'disabled' : 'enabled'"></yfs-button>
        </div>
    </div>
</div>