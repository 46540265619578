import { Component } from '@angular/core';

@Component({
  selector: 'yfs-privacy-message',
  templateUrl: './privacy-message.component.html',
  styleUrls: ['./privacy-message.component.scss']
})
export class PrivacyMessageComponent {

  public showComponent = true;

  ngOnInit() {
    const isFirstVisit = localStorage.getItem('isFirstVisit');
    if (!isFirstVisit) {
      localStorage.setItem('isFirstVisit', 'true');
    }
    this.showComponent = !isFirstVisit;
  }

  close() {
    localStorage.setItem('isFirstVisit', 'true');
    this.showComponent = false;
  }
}
