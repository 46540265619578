import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';

@Component({
  selector: 'yfs-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent {
  public comunicationAllowedPermissions = [EPermissaoAcesso.CADASTRAR_MENSAGEM]
  public parameterTariffAllowedPermissions = [EPermissaoAcesso.LISTAR_PARAMETROS]
  public tariffHistoricAllowedPermissions = [EPermissaoAcesso.HISTORICO_PARAMETROS]
  public productsAllowedPermissions = [EPermissaoAcesso.LISTAR_PRODUTOS]
  public concessionairesAndPartnersAllowedPermissions = [EPermissaoAcesso.CREDENCIAMENTO_CONCESSIONARIA]
  public previousScreen: string = '';

  constructor(private router: Router) {}

  goToComunication() {
    this.previousScreen = 'parameters';
    this.router.navigate(["communication"], { state: { previousScreen: 'parameters' } });
  }

  goToProducts() {
    this.previousScreen = 'parameters';
    this.router.navigate(["products"], { state: { previousScreen: 'parameters' } });
  }

  goToParameterTariff() {
    this.previousScreen = 'parameters';
    this.router.navigate(["parameter-tariff"], { state: { previousScreen: 'parameters' } });
  }

  goToParameterCommission() {
    this.previousScreen = 'parameters';
    this.router.navigate(["parameter-commission"], { state: { previousScreen: 'parameters' } });
  }

  goToOldTablesTariff() {
    this.previousScreen = 'parameters';
    this.router.navigate(["old-tables-tariff"], { state: { previousScreen: 'parameters' } });
  }

  goToOldTablesCommissions() {
    this.previousScreen = 'parameters';
    this.router.navigate(["old-tables-commissions"], { state: { previousScreen: 'parameters' } });
  }

  goToConcessionairesAndPartners() {
    this.previousScreen = 'parameters';
    this.router.navigate(["concessionaires-and-partners"], { state: { previousScreen: 'parameters' } });
  }


  getBack() {
    this.router.navigate(['area']);
  }
}
