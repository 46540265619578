import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { filter } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';

@Component({
  selector: 'yfs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit{
 @Input() flowSeller: boolean = false;

 public userName: string | null= ''
 public role: string | null= ''

 public showPlan = false;
 public consultAllowedPermissions = [EPermissaoAcesso.CONSULTAR_PLANO]
 public registerAllowedPermissions = [EPermissaoAcesso.CONTRATAR_PLANO]
 public comunicationAllowedPermissions = [EPermissaoAcesso.CADASTRAR_MENSAGEM]
 public showMenu = true;
 public isHomeRoute = false;
 public showComunication = false;

 constructor(private userService: UserService, private router: Router){}

 ngOnInit(){
  this.isLogged();
  this.isHome();
  const user = this.userService.getCurrentUser()
  if (user && user.Nome) {
    this.userName = user.Nome;
    this.role = user.PerfilAcesso
  }
 }

 isLogged() {
  if(this.userService.userIsLogged()) {
    this.showMenu = true;
    this.showPlan = true;
    this.showComunication = true;
  } else {
    this.showMenu = false;
    this.showPlan = false;
    this.showComunication = false;
  }
 }

 isClienteUser(): boolean {
  const user = this.userService.getCurrentUser();
  return user.PerfilAcesso === 'Cliente';
}

 isHome() {
  const state: RouterStateSnapshot = this.router.routerState.snapshot;
  const stateUrl = state.url;

  if (stateUrl === '/' || stateUrl === '/home') {
    this.showPlan = false;
    this.showMenu = false;
    this.showComunication = false;
    this.isHomeRoute = true;
  }else{
    if (this.userService.userIsLogged()) {
      this.showPlan = true;
      this.showComunication = true;
    }
  }
}

 logout() {
  this.userService.logout()
  this.userName = null;
  this.role = null;
  this.router.navigate(["home"])
 }

 atualizarNome(result: any) {
  this.userName = result.nome;
  this.role = result.role
 }

 goToAccounting() {
   this.router.navigate(["accounting"]);
 }

 goToPositionReport(){
    this.router.navigate(["position-report"]);
 }

 goToCommissions() {
    this.router.navigate(["commissions"]);
 }

 goToArea() {
    this.router.navigate(["area"]);
 }

 goToSimulation() {
  this.router.navigate(["simulation"]);
  this.reloadOnNavigationEnd();
 }

 goToComunication() {
  this.router.navigate(["communication"])
  }

  goToParameters() {
    this.router.navigate(["parameters"])
  }

 goToPlans() {
  if(this.role === 'Cliente') {
    this.router.navigate(["customer-area"])
  } else {
    this.router.navigate(["planos-contratados"])
  }
  this.reloadOnNavigationEnd();
 }

 reloadOnNavigationEnd(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
        window.location.reload();
    });
  }

}
