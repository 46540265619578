import { Component, Input } from '@angular/core';

@Component({
  selector: 'yfs-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  public isPressed = false;
  @Input() state: string = '';
  @Input() label: string = '';
  @Input() size: string = '';
  @Input() icon: string = '';
  @Input() type: string = '';
  @Input() color: string = '';
  @Input() varTextColor: string = '';
  @Input() press: any;
  @Input() fullWidth: boolean = false;
  @Input() loadingIndicator: boolean = false;

  handleClick() {
    this.isPressed = true;

    setTimeout(() => {
      this.isPressed = false;
    }, 100);
    
    if (this.press) {
      this.press()
    }
  }

  getTextColor() {
    return this.varTextColor ? `${this.varTextColor} !important` : '';
  }

}


