<mat-card class="card">
    <mat-card-content class="card-content">
        <img src="assets/svg/error-icon.svg" alt="logo modal alert" />
        <span>Estamos com problemas para exibir a página no momento.</span>
        <span>Tente recarregar ou volte mais tarde.</span>
        <span class="mt-24">Caso o erro persista, entre em contato com o nosso SAC:</span>
        <span class="mt-8">Canal de Voz (CRC): (11) 2088-7700</span>
        <span>WhatsApp: (11) 2088-7700</span>
        <span>E-mail: liberacred@yamaha-motor.com.br</span>
        <div class="mt-24">
            <yfs-button state="enabled" label="IR PARA O INÍCIO" type="primary" (click)="goToHome()"></yfs-button>
        </div>
    </mat-card-content>
</mat-card>
