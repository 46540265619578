<div class="container-title">
    <div class="button-back">
      <yfs-link-back text="Voltar para cadastro de mensagens de e-mail" (click)="getBack()"></yfs-link-back>
    </div>
    <h1>{{getTitle()}}</h1>
</div>

<mat-card class="card-name">
    <mat-card-content>
        <mat-form-field id="nome" appearance="fill">
            <mat-label>Nome</mat-label>
            <input [formControl]="nome" maxlength="150" matInput>
            <mat-error *ngIf="nome.hasError('required')">{{genericMenssage}}</mat-error>
            <mat-error *ngIf="nome.hasError('maxlength')">só é permitido 150 caracteres</mat-error>
        </mat-form-field>

        <mat-form-field id="descricao" appearance="fill">
            <mat-label>Descrição</mat-label>
            <input [formControl]="descricao" maxlength="200" matInput>
            <mat-error *ngIf="descricao.hasError('required')">{{genericMenssage}}</mat-error>
            <mat-error *ngIf="descricao.hasError('maxlength')">só é permitido 200 caracteres</mat-error>
        </mat-form-field>
    </mat-card-content>
</mat-card>

<div class="container-content">
    <mat-card class="card-message">
        <mat-card-content>
            <div class="title-card-content">
                <span class="title-card">Campos editáveis do e-mail</span>
                <yfs-button state="enabled" label="VER PRÉVIA" type="secondary" icon="visibility"
                    size="small" (click)="showContent()"></yfs-button>
            </div>

            <mat-form-field id="assunto" appearance="fill">
                <mat-label>Assunto</mat-label>
                <input [formControl]="assunto" id="assunto2" (click)="setElementSelected('assunto')" maxlength="100" matInput>
                <mat-error *ngIf="assunto.hasError('required')">{{genericMenssage}}</mat-error>
                <mat-error *ngIf="assunto.hasError('maxlength')">só é permitido 100 caracteres</mat-error>
            </mat-form-field>

            <span [ngClass]="{'label-message-required': mensagemInvalid}" class="label-message">Mensagem*:</span>

            <editor id="editorMessage" [init]="tinymeceSettings"></editor>

            <div class="required-message-container" *ngIf="mensagemInvalid">
                <div class="required-message-bar"></div>
                <span>{{typeErrorMessage === 'required' ? 'Preenchimento obrigatório' : 'O campo deve possuir no mínimo 20 caracteres.'}}</span>
            </div>

            <mat-form-field id="arquivoAnexado" appearance="fill">
                <mat-label>Arquivo anexado</mat-label>
                <mat-select #mySel [formControl]="arquivoAnexado" multiple>
                  <div class="select-all" (click)="selectAllArquivo()">
                    <mat-checkbox
                      [(ngModel)]="checkedAllArquivo"
                      [indeterminate]="interteminateArquivo"
                      color="primary"
                      (change)="selectAllArquivo()"
                      ><span class="select-label">Todos</span></mat-checkbox
                    >
                  </div>
                  <mat-option
                    *ngFor="let arquivo of arquivoAnexadoList"
                    [value]="arquivo"
                    (click)="checkHandle()"
                    ><span class="select-label">{{ arquivo?.nomeExibicao }}</span></mat-option
                  >
                </mat-select>
              </mat-form-field>
        </mat-card-content>
    </mat-card>

    <mat-card class="card-wilds">
        <mat-card-content>
            <div class="title-card-content">
                <span class="title-card">Wildcards</span>
            </div>
            <span class="subtitle-card">
                Clique no wildcard que deseja inserir na mensagem enquanto digita.
            </span>

            <div class="wildcard-content">
                <div class="wildcard" *ngFor="let wildcard of listWildCards" (click)="insertContent(wildcard?.tag)">{{wildcard?.tag}}</div>
            </div>
        </mat-card-content>
    </mat-card>

</div>
<mat-card  class="card-rotina">
    <mat-card-content>
        <span class="title-card">Rotina e Destinatários</span>
        <div class="card-rotina-content">
            <mat-form-field class="card-rotina-fields" appearance="fill">
                <mat-label>Selecione uma rotina</mat-label>
                <mat-select
                  data-test="rotina"
                  [formControl]="rotina"
                >
                  <mat-option
                    *ngFor="let rotina of listRotina"
                    [value]="rotina"
                    ><span class="select-label">{{ rotina?.nomeExibicao }}</span></mat-option
                  >
                </mat-select>
                <mat-error *ngIf="rotina.invalid">
                    {{genericMenssage}}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="card-rotina-fields" id="destinatario" appearance="fill">
                <mat-label>Selecione os destinatários</mat-label>
                <mat-select #mySel2 [formControl]="destinatario" multiple>
                  <div class="select-all" (click)="selectAllDestinatario()">
                    <mat-checkbox
                      [(ngModel)]="checkedAllDestinatario"
                      [indeterminate]="interteminateDestinatario"
                      color="primary"
                      (change)="selectAllDestinatario()"
                      ><span class="select-label">Todos</span></mat-checkbox
                    >
                  </div>
                  <mat-option
                    *ngFor="let destinatario of destinatarioList"
                    [value]="destinatario"
                    (click)="checkHandleDestinatario()"
                    ><span class="select-label">{{ destinatario?.nome }}</span></mat-option
                  >
                </mat-select>
                <mat-error *ngIf="destinatario.invalid">
                    {{genericMenssage}}
                </mat-error>
              </mat-form-field>
        </div>
    </mat-card-content>
</mat-card>
<div class="button-content">
    <yfs-button state="enabled" label="CANCELAR" type="secondary" (click)="cancelButtonHandle()"></yfs-button>
    <yfs-button state="enabled" label="SALVAR TEMPLATE" type="primary" (click)="handleSaveButtonClick()"></yfs-button>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
