import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from '../../../shared/date-format'
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EUfs } from 'src/app/shared/enums/profile-access.enum';
import { IClient, IDocuments } from 'src/app/models/register.interface';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';
import { ModalCancelComponent } from 'src/app/components/modal-cancel/modal-cancel.component';
import { NavigationExtras, Router } from '@angular/router';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { CpfValidator } from 'src/app/shared/validators/cpf-validator';

@Component({
  selector: 'yfs-register-documents',
  templateUrl: './register-documents.component.html',
  styleUrls: ['./register-documents.component.scss']
})
export class RegisterDocumentsComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();

  public cpf = new FormControl('', [Validators.required, CpfValidator.cpf]);
  public rg = new FormControl('');
  public uf = new FormControl('');
  public orgaoExpedidor = new FormControl('');
  public dataExpedicao = new FormControl('', [dateFormat.dateValidator]);
  public dataExpedicaoLabel = ""
  public cpfValido = false;
  public rgValid = false;
  public timeoutError = false;
  public genericError = false;
  public isLoading = false;
  public listUfs = EUfs;
  public currentFlow = 'document';
  public hideRg = false;
  public canProceed = false;

  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public rgMask = [/([0-9A-Za-z])?/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /([0-9A-Za-z])?/];
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  constructor(private apiService: ApiHttpService, public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem("documentos")) {
      let documentos: IDocuments;
      documentos = JSON.parse(atob(localStorage.getItem('documentos') || '{}'))
      this.bidingValuesDocuments(documentos)
      this.cpfValido = true;
    }
    this.checkValidInputs()
  }

  goBack() {
    this.currentFlowChanged.emit("concessionaire")
  }

  checkValidInputs() {
    if (!this.cpfValido) {
      this.rg.disable();
      this.uf.disable();
      this.orgaoExpedidor.disable();
      this.dataExpedicao.disable();
    }
    else {
      this.rg.enable();
      this.uf.enable();
      this.orgaoExpedidor.enable();
      this.dataExpedicao.enable();
    }
  }

  validateRg(event: any) {
    const value = event.target.value
    const normalize = value.replace(/[^a-zA-Z0-9]/g, '');
    if (normalize.length > 0) {
      this.addValidationRgDependents()
    }
    else {
      this.rgValid = false;
      this.orgaoExpedidor.clearValidators()
      this.orgaoExpedidor.reset()

      this.dataExpedicao.clearValidators()
      this.dataExpedicao.setValidators(dateFormat.dateValidator)
      this.dataExpedicao.reset()

      this.uf.clearValidators()
      this.uf.reset()
      this.updateValueAndValidity()
    }
  }

  addValidationRgDependents() {
    this.rgValid = true;
    this.rg.setValidators(LocalValidators.rgValidator)
    this.orgaoExpedidor.setValidators(Validators.required)
    this.dataExpedicao.setValidators([Validators.required, dateFormat.dateValidator])
    this.uf.setValidators(Validators.required)
    this.updateValueAndValidity()
  }

  updateValueAndValidity() {
    this.rg.updateValueAndValidity()
    this.orgaoExpedidor.updateValueAndValidity()
    this.dataExpedicao.updateValueAndValidity()
    this.uf.updateValueAndValidity()
  }

  validateCpf(event: any) {
    const value = event.target.value
    if (value && value.length > 0) {
      const normalize = value.replace(/[^0-9]/g, '');
      if (normalize.length == 11) {
        this.isLoading = true;
        this.apiService.validateCpf(normalize).pipe(
          timeout(30000),
          catchError((error) => {
            if (error instanceof TimeoutError) {
              this.isLoading = false;
              this.canProceed = false;
              return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
            }
            this.canProceed = false;
            return throwError(() => error)
          })
        )
        .subscribe({
          next: result => {
            if (result) {
              if (result.clienteCadastrado) {
                this.cleanStorageDatas()
                this.setStorageDatas(result.cliente)
                this.bidingValuesDocuments(result.cliente.documentos)
              } else {
                this.resetForm()
                this.cleanStorageDatas()
              }
              this.cpfValido = true;
              this.canProceed = true;
            } else {
              this.canProceed = false;
            }
            this.checkValidInputs()
            this.isLoading = false;
          },
          error: error => {
            this.isLoading = false;
            this.canProceed = false;
            if (error.status && error.status == 400 && error?.error?.errors?.Cpf) {
              if (error?.error?.errors?.Cpf[0] === "CPF inválido") {
                this.cpf.setErrors({ 'invalidCpf': true })
              }
              if (error?.error?.errors?.Cpf[0] === "Identificamos que o CPF já possui um contrato em aberto no LiberaCred.") {
                this.openDialogCpf()
                this.cpf.setErrors({ 'cpfUsed': true })
              }
            } else {
              this.cleanStorageDatas()
              this.checkValidInputs()
              this.cpfValido = true;
            }
            console.log(error)
          }
        });
      }
    }
  }

  resetForm() {
    this.rg.reset()
    this.uf.reset()
    this.orgaoExpedidor.reset()
    this.dataExpedicao.reset()
  }

  cleanStorageDatas() {
    localStorage.removeItem("documentos")
    localStorage.removeItem("dadosPessoais")
    localStorage.removeItem("endereco")
    localStorage.removeItem("contato")
  }

  setStorageDatas(client: IClient) {
    localStorage.setItem("documentos", btoa(JSON.stringify(client.documentos)))
    localStorage.setItem("dadosPessoais", btoa(JSON.stringify(client.dadosPessoais)))
    localStorage.setItem("endereco", btoa(JSON.stringify(client.endereco)))
    localStorage.setItem("contato", btoa(JSON.stringify(client.contato)))
  }

  bidingValuesDocuments(documents: IDocuments) {
    this.cpf.setValue(documents.cpf.toString())
    this.rg.setValue(documents.rg?.numero ?? '');
    this.uf.setValue(documents.rg?.uf ?? '');
    this.orgaoExpedidor.setValue(documents.rg?.orgaoExpedidor ?? '');
    const dataExpedicaoFormated = documents.rg?.dataExpedicao ? this.convertDateToString(documents.rg?.dataExpedicao) : ''
    this.dataExpedicaoLabel = documents.rg?.dataExpedicao ? this.convertExpedicaoLabel(dataExpedicaoFormated) : '';
    this.dataExpedicao.setValue(dataExpedicaoFormated);
    if(this.cpf.value){
      this.canProceed = true;
    }
  }

  openDialog() {
    this.dialog.open(ModalCancelComponent, {
      width: '381px'
    })
  }

  openDialogCpf() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '326px',
      data: {
        text: 'Identificamos que o CPF já possui um contrato em aberto no Liberacred. Acesse o perfil do cliente.',
        primaryButtonAction: () => {
          this.goToPlans();
          dialogRef.close();
        },
        primaryButtonText: 'ACESSAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  convertExpedicaoLabel(dateString: any) {
    const [dayStr, monthStr, yearStr] = dateString.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    return dateObj.toString()
  }

  nextStep() {
    this.uf.markAllAsTouched()
    this.cpf.markAllAsTouched()
    this.rg.markAllAsTouched()
    this.orgaoExpedidor.markAllAsTouched()
    this.dataExpedicao.markAllAsTouched()

    if (this.rg.value) {
      this.addValidationRgDependents()
    }
    if (this.cpf.valid && this.rg.valid &&
      this.uf.valid && this.orgaoExpedidor.valid && this.dataExpedicao.valid) {
      const cpfValue = this.cpf.value?.replace(/[^0-9]/g, '')
      const dataExpedicaoValue = this.dataExpedicao.value;
      const dataExpedicaoFormated = dataExpedicaoValue ? this.convertStringToDate(dataExpedicaoValue) : undefined

      let documento: IDocuments = {
        cpf: cpfValue ? cpfValue : "",
        rg: {
          numero: this.rg.value ?? undefined,
          uf: this.uf.value ?? undefined,
          orgaoExpedidor: this.orgaoExpedidor.value ?? undefined,
          dataExpedicao: dataExpedicaoFormated
        }
      };

      localStorage.setItem("documentos", btoa(JSON.stringify(documento)))
      this.currentFlowChanged.emit("personalData")
    }
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }

  convertDateToString(dataExpedicao: any) {
    const dataOriginal = new Date(dataExpedicao);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  goToPlans() {
    this.router.navigate(["planos-contratados"])
   }

}
