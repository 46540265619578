<div class="dialog-content" mat-dialog-content>
    <img alt="Ícone de atenção" src="assets/svg/info-icon.svg" />

    <span class="title-text">
        Boleto expirado
    </span>

    <div  class="dialog-text">
        Um novo boleto será gerado com <strong>vencimento de 30 dias.</strong> Clique no botão {{ getText() }}
    </div>

    <div *ngIf="data.type == 'enviar' && data.email" class="dialog-contract-area">
        <span class="name">{{data.email}}</span>
    </div>

    <div class="dialog-buttons">
        <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="closeModal()"></yfs-button>
        <yfs-button state="enabled" [label]="getTextButton()" type="primary" (click)="handleClickButton()"></yfs-button>
    </div>
</div>
