<div class="dialog-content" mat-dialog-content>
  <span class="title">Prévia</span>
  <span class="subtitle">Dados da minuta</span>
  <div class="container-preview">
      <div class="content-preview" [innerHTML]="data.previewContent"></div>
  </div>

  <mat-card-content class="card-content">
    <div class="row">
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>Nomenclatura para armazenamento</mat-label>
        <input [ngModel]="nomenclatura" matInput disabled>
      </mat-form-field>

      <mat-form-field class="date-field" appearance="fill">
        <mat-label>Data de início de vigência</mat-label>
        <mat-icon aria-hidden="false" aria-label="Ícone de busca" class="calendar-icon material-symbols-outlined"
                fontIcon="event"></mat-icon>
        <input matInput  [ngModel]="dataInicial" disabled/>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field id="tipoLiberacao" appearance="fill">
        <mat-label>Tipo da liberação</mat-label>
        <mat-select [formControl]="draftType">
          <mat-option *ngFor="let draft of draftOptions" [value]="draft.value">{{draft.nome}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Data final de vigência</mat-label>
        <mat-icon aria-hidden="false" aria-label="Ícone de busca" class="calendar-icon material-symbols-outlined"
                fontIcon="event"></mat-icon>
        <input matInput  [ngModel]="dataFinal" disabled/>
      </mat-form-field>
    </div>
  </mat-card-content>

  <div class="cards-container">
    <div class="card">
      <span class="title-card">Wildcards</span>
      <div class="wildcard-content">
          <div *ngFor="let variavel of variaveisUnicas" class="wildcard">
              {{ variavel }}
          </div>
      </div>
  </div>
  </div>

  <yfs-button *ngIf="!data?.openEditTemplate" class="close-button" state="enabled" label="FECHAR" type="primary" (click)="closeModal()"></yfs-button>

  <div *ngIf="data?.openEditTemplate" class="button-container">
      <yfs-button  state="enabled" label="FECHAR" type="secondary" (click)="closeModal()"></yfs-button>
      <yfs-button  state="enabled" label="EDITAR TEMPLATE" type="primary" (click)="openEditTemplate()"></yfs-button>
  </div>
</div>
