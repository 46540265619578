import { Injectable } from "@angular/core";

@Injectable()
export class DataService {
  private dataList: any[] = [];
  private email: string = '';
  private selectedIds: number[] = [];
  private contrato: string = '';
  private cpf: string = '';

  setData(dataList: any[], email: any, selectedIds: any[], contrato: any, cpf: any) {
    this.dataList = dataList;
    this.email = email;
    this.selectedIds = selectedIds;
    this.contrato = contrato;
    this.cpf = cpf;
  }

  getDataList() {
    return this.dataList;
  }

  getEmail() {
    return this.email;
  }

  getSelectedIds() {
    return this.selectedIds;
  }

  getContrato() {
    return this.contrato;
  }

  getCPF() {
    return this.cpf;
  }
}
