import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';

@Component({
  selector: 'yfs-plan-extract',
  templateUrl: './plan-extract.component.html',
  styleUrls: ['./plan-extract.component.scss']
})
export class PlanExtractComponent {
  @Input() idContrato: number | undefined;
  @Input() cpfClient: string | undefined = '';
  @Input() userName: string | undefined = '';
  @Input() previousFlow: string = "";
  @Output() closePlanExtract = new EventEmitter();

  public currentDate = new Date();
  public dataSource = new MatTableDataSource<any>([]);
  public dataInicialLabel: Date | undefined;
  public dataFinalLabel: Date | undefined;
  public dataInicial = new FormControl('', [dateFormat.commissionsValidate]);
  public dataFinal = new FormControl('', [dateFormat.commissionsValidate]);
  public maxDateInicial = new Date();
  public maxDateFinal = new Date();
  public isLoading = false;
  public emptyDocument = false;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public showTable = false;
  public pageSizeOptions = [10, 5, 20];
  public displayedColumnsResume: string[] = [
    'dataPagamento', 'dataVencimento', 'descricao', 'numeroParcela', 'valor',
  ];
  private changesDetected = false;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private cdref: ChangeDetectorRef,
    private apiService: ApiHttpService,
    private customPaginator: MatPaginatorIntl
  ) {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));
    this.dataInicialLabel = thirtyDaysAgo;
    this.currentDate.setDate(this.currentDate.getDate());
    this.dataFinalLabel = this.currentDate;
    customPaginator.itemsPerPageLabel = "Itens por página";
  }

  @ViewChild(MatSort, { static: false }) firstTableSort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.getData();
  }

  ngAfterViewChecked(): void {
    if (this.changesDetected) {
      this.applyDefaultSorting();
      this.changesDetected = false;
    }
  }

  getBack() {
    this.closePlanExtract.emit();
  }

onInputChange(isCalendar: boolean) {
  if (this.dataInicial.value?.length === 0) {
    this.dataInicialLabel = undefined;
  }
  if (this.dataFinal.value?.length === 0) {
    this.dataFinalLabel = undefined;
  }
  if (
    this.dataInicial &&
    this.dataInicial.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8 &&
    this.dataFinal &&
    this.dataFinal.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8
  ) {
    const dataInicio = this.dataInicial.value
      ? this.initialFormatDate(this.dataInicial.value)
      : null;

    const dataFim = this.dataFinal.value
      ? this.initialFormatDate(this.dataFinal.value)
      : null;

    if (!isCalendar) {
      this.dataInicialLabel = dataInicio || undefined;
      this.dataFinalLabel = dataFim || undefined;
    }
  }
}

initialFormatDate(dateString: string) {
  const [day, month, year] = dateString.split('/');
  return new Date(Number(year), Number(month) - 1, Number(day));
}


  getData = () => {
    this.isLoading = true;
    const dataInicialValue = this.formatDateToSend(this.dataInicial.value);
    const dataFinalValue = this.formatDateToSend(this.dataFinal.value);

    const idContrato = this.idContrato;
    this.apiService.getExtract(dataInicialValue, dataFinalValue, idContrato).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    ).subscribe({
      next: (result) => {
        this.processData(result);
      },
      error: (error) => {
        this.isLoading = false;
        this.emptyDocument = true;
        console.error('Error fetching data:', error);
      },
    });
  }

  formatDateToSend = (data: any): string => {
    const partes = data.split('/');
    const novaData = `${partes[2]}-${partes[1]}-${partes[0]}`;
    return novaData;
  };

  processData(result: any) {
    if (result.status === 204) {
      this.emptyDocument = true;
      this.isLoading = false;
    } else if (result.status === 200) {
      this.dataSource = new MatTableDataSource<any>(result.body);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.firstTableSort;
      this.changesDetected = true;
      this.showTable = true;
      this.emptyDocument = false;
    }
    this.isLoading = false;
    this.cdref.detectChanges();
  }

  private applyDefaultSorting(): void {
    if (this.firstTableSort) {
      const defaultSortColumn = this.displayedColumnsResume[0];
      if (this.displayedColumnsResume.includes(defaultSortColumn)) {
        this.firstTableSort.active = defaultSortColumn;
        this.firstTableSort.direction = 'desc';
        this.dataSource.sort = this.firstTableSort;
      }
    }
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    const totalPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} of ${totalPages}`;
  }

  announceSortChange(sortState: Sort) {
    const direction = sortState.direction === 'asc' ? 'ascending' : 'descending';
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${direction}`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
