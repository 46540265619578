<div class="dialog-content" mat-dialog-content>
    <div class="container">
        <span class="title">{{data?.title}}</span>
        <span class="subTitle">{{data?.subTitle}} <strong>Busque ou selecione a nova concessionária abaixo.</strong></span>

        <mat-form-field appearance="fill">
            <mat-label>Busque por nome ou CNPJ</mat-label>
            <mat-icon aria-hidden="false" aria-label="Ícone de busca" class="search-icon material-symbols-outlined"
                fontIcon="search"></mat-icon>
            <input [(ngModel)]="search" (keyup)="applyFilter()"  matInput>
        </mat-form-field>
    </div>

    <div>
        <table mat-table [dataSource]="dataSource" #firstTable #firstTableSort="matSort" matSort>

            <ng-container matColumnDef="razaoSocial">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by razaoSocial">
                    <strong>Concessionária</strong>
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-radio-group [(ngModel)]="selectedConcessionaria">
                        <mat-radio-button [value]="element"></mat-radio-button>
                    </mat-radio-group> {{element.razaoSocial}}
                </td>
            </ng-container>

            <ng-container matColumnDef="cnpj">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by cnpj">
                    <strong>CNPJ</strong>
                </th>
                <td mat-cell *matCellDef="let element"> <strong>{{element.cnpj | cnpj}}</strong> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div class="paginator-content">
            <mat-paginator aria-label="Select page" [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>
        </div>
        
    </div>
    <div class="buttons-content">
        <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="closeModal()"></yfs-button>
        <yfs-button [state]="selectedConcessionaria ?  'enabled' : 'disabled'" label="INDICAR CONCESSIONÁRIA" type="primary"
            [press]="selectConcessionaria"></yfs-button>
    </div>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>