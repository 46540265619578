<yfs-header></yfs-header>
<section class="container-content" *ngIf="currentFlow == 'product'">
    <div class="button-back">
      <yfs-link-back [text]="previousScreen === 'parameters' ? 'Voltar para parâmetros técnicos' : 'Voltar para minha área'" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
        <h1>PRODUTOS</h1>
        <yfs-button class="button-att" state="enabled" label="ATUALIZAR PRODUTOS" (click)="openModalNext()" type="secondary"></yfs-button>
    </div>
    <mat-card>
        <mat-card-content>
            <div class="inputs-content">
                <mat-form-field id="nome" appearance="fill">
                    <mat-label>Produto</mat-label>
                    <mat-icon aria-hidden="false" aria-label="Ícone de busca"
                        class="search-icon material-symbols-outlined" fontIcon="search"></mat-icon>
                    <input [formControl]="nomeProduto" (focusout)="setPageStart(); this.searchProducts()" matInput>
                </mat-form-field>

                <mat-form-field id="categoria" appearance="fill">
                    <mat-label>Categoria</mat-label>
                    <mat-select #perfilAcessoSelect [formControl]="categoria" multiple>
                        <div class="select-all" (click)="selectAllCategoria()">
                            <mat-checkbox [(ngModel)]="checkedAllCategoria"
                                [indeterminate]="selectedCategoria.length > 0 && selectedCategoria.length < listaCategoria.length"
                                color="primary"><span class="select-label">Todos</span></mat-checkbox>
                        </div>
                        <mat-option *ngFor="let categoria of listaCategoria"
                            [value]="categoria.idCategoriaExibicao" (click)="checkHandleCategoria(categoria.idCategoriaExibicao)"><span class="select-label">{{
                                categoria.nomeExibicao }}</span></mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field id="status" appearance="fill">
                    <mat-label>Status</mat-label>
                    <mat-select #perfilAcessoSelect [formControl]="status" multiple>
                        <div class="select-all" (click)="selectAllStatus()">
                            <mat-checkbox [(ngModel)]="checkedAllStatus"
                                [indeterminate]="selectedStatus.length > 0 && selectedStatus.length < listaStatus.length"
                                color="primary"><span class="select-label">Todos</span></mat-checkbox>
                        </div>
                        <mat-option *ngFor="let status of listaStatus" [value]="status.idStatus" (click)="checkHandleStatus(status.idStatus)"><span
                                class="select-label">{{
                                status.nome }}</span></mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <table *ngIf="hasValue" mat-table [dataSource]="dataSource" #firstTable #firstTableSort="matSort"
                    matSort (matSortChange)="announceSortChange($event)">

                    <ng-container matColumnDef="modelo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by modelo">
                            Produto
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.modelo}} </td>
                    </ng-container>

                    <ng-container matColumnDef="categoria">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by categoria">
                            Categoria
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.categoria}} </td>
                    </ng-container>

                    <ng-container matColumnDef="valorReferencia">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by valorReferencia">
                            Valor Referência
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.valorReferencia | currency}} </td>
                    </ng-container>

                    <ng-container matColumnDef="situacao">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by situacao">
                            Situação
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="td-icon ">
                                <mat-icon aria-hidden="false" aria-label="Ícone de situacao"
                                    [style.color]="getIconColor(element.situacao)" class="material-symbols-outlined"
                                    [fontIcon]="getIcon(element.situacao)"></mat-icon>
                                <span>{{getStatusDescription(element.situacao)}}</span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="acoes">
                        <th mat-header-cell *matHeaderCellDef>
                            Ações
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon aria-hidden="false" aria-label="Ícone do cliente"
                                class="icon-edit material-symbols-outlined" fontIcon="edit" (click)="openEditProduct(element.idProduto)"></mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div *ngIf="!hasValue" class="content-empty">
                    <img src="assets/svg/empty-document.svg" alt="documento vazio" height="64" width="64">
                    <div>
                        <span>Nenhum resultado encontrado. Tente novamente</span>
                        <span>com outros filtros.</span>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="paginator-content">
        <mat-paginator [pageIndex]="pageIndex" [length]="length" [pageSize]="pageSize" aria-label="Select page"
          [pageSizeOptions]="pageSizeOptions" (page)="handlePageEvent($event)">
        </mat-paginator>
      </div>
</section>
<yfs-edit-product *ngIf="currentFlow == 'editProduct'" [idProduct]="idProductSelected" (currentFlowChanged)="changeCurrentFlow($event)"></yfs-edit-product>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
