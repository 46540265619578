import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'yfs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  goToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
