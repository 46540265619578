<mat-card>
  <mat-card-content>
<span class="title-document">Contato</span>
<div class="container-content">
    <div class="content">
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>Celular com DDD</mat-label>
        <input data-test="input-phone-cell-ddd"  [formControl]="celularComDdd" [textMask]="{mask: celularMask }" matInput>
        <mat-error *ngIf="celularComDdd.invalid">
          O campo é obrigatorio
      </mat-error>
      </mat-form-field>
      <mat-form-field class="input-telefone" appearance="fill">
        <mat-label>Telefone residencial com DDD</mat-label>
        <input data-test="input-phone-ddd" [formControl]="telefoneResidencialComDdd" [textMask]="{mask: telefoneMask }" matInput>
        <mat-error *ngIf="telefoneResidencialComDdd.invalid">
          O campo é obrigatorio
      </mat-error>
      </mat-form-field>
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>E-mail</mat-label>
        <input data-test="input-email" [formControl]="email" matInput maxlength="50">
        <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
          Insira um e-mail válido
        </mat-error>
        <mat-error *ngIf="email.invalid && email.hasError('required')">
          O campo é obrigatorio
      </mat-error>
      <mat-error *ngIf="email.hasError('maxlength')">
        O limite de caracteres é de 50
      </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="!timeoutError && !genericError" class="container-buttons">
      <div class="divisor-content">
          <yfs-button state="enabled" label="CANCELAR" type="tertiary" (click)="openDialog()"></yfs-button>
          <span class="remaining-number">5 de 5</span>
      </div>
      <div class="divisor-content-next">
        <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="goBack()"></yfs-button>
        <yfs-button state="enabled" label="PROSSEGUIR" type="primary" (click)="nextStep()"></yfs-button>
      </div>
  </div>
</div>
</mat-card-content>
</mat-card>
<yfs-alert-box *ngIf="timeoutError || genericError"></yfs-alert-box>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
