import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { EStatus } from 'src/app/shared/enums/status.enum';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';

export interface StatusOption {
  label: string;
  value: number;
}

@Component({
  selector: 'yfs-position-report',
  templateUrl: './position-report.component.html',
  styleUrls: ['./position-report.component.scss'],
})
export class PositionReportComponent {
  public cpf = new FormControl('', [LocalValidators.cpfValidator]);
  public currentDate = new Date();
  public isLoading = false;
  public timeoutError = false;
  public success: boolean = false;
  public generationError = true;
  public statusOptions: StatusOption[] | undefined;
  public dataLabelBase: Date;
  public maxDate: Date;
  public status: FormControl = new FormControl();
  public dateError = false;
  public emptyDocument = false;
  public file: any;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public dataBase = new FormControl('', [
    Validators.required,
    dateFormat.positionValidator,
  ]);
  public contrato = new FormControl('');
  public newCurrentDate = new Date();

  constructor(
    private apiService: ApiHttpService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.currentDate.setDate(this.currentDate.getDate() - 1);
    this.dataLabelBase = this.currentDate;
    this.maxDate = this.newCurrentDate;
    this.statusOptions = [
      {
        label: 'Em Aberto',
        value: EStatus.EM_ABERTO
      },
      {
        label: 'Estorno finalizado',
        value: EStatus.ESTORNO_FINALIZADO
      },
      {
        label: 'Ativo',
        value: EStatus.ATIVO
      },
      {
        label: 'Finalizado',
        value: EStatus.FINALIZADO_CDC
      },
      {
        label: 'Ineligível',
        value: EStatus.INELEGIVEL
      },
      {
        label: 'Cancelado',
        value: EStatus.CANCELADO
      },
      {
        label: 'Inativo',
        value: EStatus.INATIVO
      },
      {
        label: 'Desistência em andamento',
        value: EStatus.DESISTENCIA_EM_PROCESSO
      }
    ]
  }

  getStatusLabel(value: EStatus): any {
    if(this.statusOptions){
      const statusOption = this.statusOptions.find(option => option.value === value);
      return statusOption && statusOption.label ? statusOption.label : '';
    }
  }

  checkValidInputs() {
    if (this.dataBase.value && this.contrato.value) {
      this.cpf.disable();
      this.status.disable();
    } else if (!this.cpf.value) {
      this.cpf.enable();
      this.contrato.enable();
      this.status.enable();
    }

    if (this.dataBase.value && this.cpf.value) {
      this.contrato.disable();
      this.status.disable();
    }

    if (this.dataBase.value && this.status.value) {
      this.contrato.disable();
      this.cpf.disable();
    }

    if (this.status.value.length <= 0) {
      this.cpf.enable();
      this.contrato.enable();
    }
  }

  openDialog() {
    this.dialog.open(ModalGenericComponent, {
      width: '393px',
      data: {
        text: 'Ao gerar um novo arquivo, o atual será apagado. Tem certeza que deseja continuar?',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => {
          this.success = false;
          this.contrato.reset();
          this.cpf.reset();
          this.status.reset();
          this.enableInputs();
          this.generationError = false;
          this.emptyDocument = false;
        },
        secundaryButtonText: 'SIM, GERAR NOVO ARQUIVO',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  sendRequest = () => {
    this.isLoading = true;
    if (this.dataBase.value) {
      this.dataBase.disable();
      this.contrato.disable();
      this.cpf.disable();
      this.status.disable();
      const [dia, mes, ano] = this.dataBase.value.split('/');
      const datadataBaseObj = new Date(Number(ano), Number(mes) - 1, Number(dia));
      const dataBaseFormatada = this.formatDate(datadataBaseObj);
      const cpfValue = this.cpf.value ? this.cpf.value.replace(/[.-]/g, '') : '';
      this.apiService
        .getPositionReport(dataBaseFormatada, this.contrato.value as string, cpfValue, this.status.value)
        .pipe(
          timeout(30000),
          catchError((error) => {
            if (error instanceof TimeoutError) {
              this.isLoading = false;
              this.timeoutError = true;
              this.generationError = true;
              this.enableInputs();
              return throwError(
                () => 'A requisição demorou muito tempo e foi cancelada.'
              );
            }
            return throwError(() => error);
          })
        )
        .subscribe({
          next: (response: HttpResponse<Blob>) => {
            if (response.status === 204) {
              this.isLoading = false;
              this.emptyDocument = true;
              this.enableInputs();
            }
            else {
              this.isLoading = false;
              if (response.body) {
                const fileName = `Relatório de Posição.xlsx`;
                const file = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = URL.createObjectURL(file);
                this.file = { name: fileName, url: fileURL };
                this.success = true;
              } else {
                this.success = false;
                this.isLoading = false;
                this.generationError = true;
                this.emptyDocument = false;
                this.enableInputs();
              }
            }
          },
          error: (error) => {
            this.isLoading = false;
            if (!this.timeoutError) {
              this.success = false;
            }
            this.generationError = true;
            this.emptyDocument = false;
            this.enableInputs();
          },
        });
    } else {
      this.isLoading = false;
      this.generationError = true;
      this.emptyDocument = false;
      this.enableInputs();
    }
  }

  onInputChange() {
    this.generationError = false;
    const dataInicio = this.dataBase.value
      ? new Date(this.initialFormatDate(this.dataBase.value))
      : null;
    if (dataInicio) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
  }

  enableInputs() {
    this.dataBase.enable();
    this.contrato.enable();
    this.cpf.enable();
    this.status.enable();
  }

  initialFormatDate(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  getBack() {
    this.router.navigate(['area']);
  }

  downloadFile(): void {
    const fileName = this.file.name;
    const fileURL = this.file.url;
    const downloadLink = document.createElement('a');
    downloadLink.href = fileURL;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
