import { Component, Input } from '@angular/core';

@Component({
  selector: 'yfs-link-back',
  templateUrl: './link-back.component.html',
  styleUrls: ['./link-back.component.scss']
})
export class LinkBackComponent {
  @Input() text: string = ''
}
