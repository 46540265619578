import { Component, ElementRef, HostListener, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterStateSnapshot } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';
import { ModalPasswordChangeComponent } from '../modal-password-change/modal-password-change.component';

@Component({
  selector: 'yfs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  @Input() flowSeller: boolean = false;
  @Output() nomeAtualizado = new EventEmitter<{ nome: string, role: string }>();
  @HostListener('document:click', ['$event'])

  clickout(event: any) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      this.show = true
    } else {
      this.show = false
      this.showError = false
    }
  }

  public login = new FormControl('', [Validators.required]);
  public senha = new FormControl('', [Validators.required]);
  public hide = true;
  public validForm = true;
  public userData = '';
  public show = false;
  public isLoading = false;
  public listIsEmpty = false;
  public timeoutError = false;
  public genericError = false;
  public showError = false;

  constructor(
    private userService: UserService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private router: Router,
    private userIdle: UserIdleService
  ) { }

  onLoginChange() {
    this.showError = false;
  }

  authentication() {
    this.isLoading = true;
    if (this.login.invalid) {
      this.showError = true;
    }
    this.userService.login(this.login.value, this.senha.value).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          this.timeoutError = true;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    ).subscribe({
      next: result => {
        if (result && result.autenticado) {
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
          if (result.primeiroAcesso) {
            this.openDialogPassword()
          } else {
            const user = this.userService.getCurrentUser()
            this.atualizaNome(user.Nome, user.PerfilAcesso)
            this.removeCachedValuesRegister()
            this.startTimeoutIdle()
            const state: RouterStateSnapshot = this.router.routerState.snapshot;
            const stateUrl = state.url;
            if (user.PerfilAcesso === 'Cliente') {
              this.router.navigate(["customer-area"]);
            } else if (stateUrl !== '/simulation' && stateUrl !== '/cadastro') {
              this.router.navigate(["area"]);
            }
          }
        } else {
          this.isLoading = false;
          this.showError = true;
          this.changeDetectorRef.detectChanges();
        }
      },
      error: error => {
        this.isLoading = false;
        if (error?.status === 403) {
          this.openDialog()
          this.changeDetectorRef.detectChanges();
          return
        }
        if (error?.status === 404) {
          if (error?.error?.detail && error?.error?.detail == 'Por motivos de segurança, por favor redefinir sua senha para regularizar seu acesso.') {
            this.openDialogResetPassword()
          }
        }
        this.showError = true;
        this.changeDetectorRef.detectChanges();
        if (this.timeoutError === false) this.genericError = true;
        console.log(error)
      }
    })
  }

  startTimeoutIdle() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(count => console.log(count));
    this.userIdle.onTimeout().subscribe(() => this.redirectToHomeOnTimeout());
  }

  redirectToHomeOnTimeout() {
    this.userIdle.resetTimer();
    this.userService.logout();
    this.router.navigate(["home"])
  }

  redirectToForgotPassword() {
    this.router.navigate(["forgot-password"])
  }

  removeCachedValuesRegister() {
    localStorage.removeItem("documentos");
    localStorage.removeItem("produto");
    localStorage.removeItem("dadosPessoais");
    localStorage.removeItem("endereco");
    localStorage.removeItem("contato");
  }

  openDialog() {
    this.dialog.open(ModalGenericComponent, {
      width: '393px',
      data: {
        text: `Para fazer o login você precisa estar vinculado à uma concessionária credenciada no Liberacred.
        Entre em contato com a área comercial da Yamaha.`,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  showLogin() {
    this.show = !this.show
  }

  atualizaNome(nome: string, role: string) {
    this.nomeAtualizado.emit({ nome: nome, role: role })
  }

  openDialogPassword() {
    this.dialog.open(ModalPasswordChangeComponent, {
      width: '786px',
      data: {
        redefineFunction: (currentPassword: string, newPassword: string) => { this.redefinePassword(currentPassword, newPassword) },
        currentPassword: this.senha.value
      }
    })
  }

  openDialogRedefineSuccess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: `Senha alterada com sucesso.
        Realize novamente o login para ter acesso
        à todas as informações do seu contrato.`,
        icon: 'success',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogRedefineError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: `Algo deu errado e não conseguimos criar sua nova senha. Tente outra vez ou volte mais tarde.`,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => {this.dialog.closeAll(); this.openDialogPassword()}
      },
    });
  }

  redefinePassword(currentPassword: string, newPassword: string) {
    this.isLoading = true;
    const redefineParameters = {
      login: this.login.value,
      currentPassword: currentPassword,
      newPassword: newPassword
    }
    this.userService.redefinePassword(redefineParameters).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.openDialogRedefineSuccess()
          this.changeDetectorRef.detectChanges();
        },
        error: (error: any) => {
          this.isLoading = false;
          console.log(error);
          this.openDialogRedefineError()
          this.changeDetectorRef.detectChanges()
        },
      });
  }

  openDialogResetPassword() {
    this.dialog.open(ModalGenericComponent, {
      width: '393px',
      data: {
        text: 'Por motivos de segurança, por favor redefinir sua senha para regularizar seu acesso.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.redirectToForgotPassword() },
        secundaryButtonText: 'REDEFINIR SENHA',
        secundaryButtonVariant: 'primary',
      }
    })
  }

}
