import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { EditorComponent } from '@tinymce/tinymce-angular';
import tinymce from 'tinymce/tinymce';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalPreviewMessageComponent } from 'src/app/components/modal-preview-message/modal-preview-message.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';

@Component({
  selector: 'yfs-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss']
})
export class NewMessageComponent implements OnInit {

  @ViewChild('mySel') select: MatSelect | undefined;
  @ViewChild('mySel2') selectDestinatario: MatSelect | undefined;
  @ViewChild(EditorComponent) editor: EditorComponent | undefined;
  @Output() currentFlowChanged = new EventEmitter<string>();
  @Input() templateData: any;

  public assunto = new FormControl('', [Validators.required, Validators.maxLength(100)]);
  public nome = new FormControl('', [Validators.required, Validators.maxLength(150)]);
  public descricao = new FormControl('', [Validators.required, Validators.maxLength(200)]);
  public arquivoAnexado = new FormControl<any[]>([]);
  public rotina = new FormControl('', [Validators.required]);
  public destinatario = new FormControl<any[]>([], [Validators.required]);
  public listRotina: any
  public destinatarioList: any
  public arquivoAnexadoList: any
  public listWildCards: any
  public genericMenssage = "Campo obrigatório"
  public tinymeceSettings: any
  public checkedAllArquivo = false;
  public interteminateArquivo = false;
  public checkedAllDestinatario = false;
  public interteminateDestinatario = false;
  public previewContent: any
  public isLoading = false;
  public mensagemInvalid = false;
  public elementSelected = 'none'
  public typeErrorMessage = ''

  constructor(private apiService: ApiHttpService, public dialog: MatDialog) { }

  ngOnInit() {
    this.initializeTinyMCE()
    this.getMessageContents()
  }

  initializeTinyMCE() {
    this.tinymeceSettings = {
      base_url: '/tinymce',
      suffix: '.min',
      menubar: false,
      height: 600,
      setup: (editor: any) => {
        editor.on('click', () => {
          this.setElementSelected('editor');
        });
        editor.on('init', () => {
          this.pickMensagem()
        })
      },
      plugins: 'image link table template pagebreak preview help ',
      toolbar: 'undo redo | blocks | ' +
        'bold italic backcolor forecolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'pagebreak  | insertfile image link template | table | removeformat | help',
      entity_encoding: 'raw',
    };
  }

  getTitle() {
    return this.templateData ? 'EDITAR MENSAGEM' : 'NOVA MENSAGEM'
  }
  getBack() {
    this.currentFlowChanged.emit('communication');
  }

  setElementSelected(element: string) {
    this.elementSelected = element;
  }

  selectAllArquivo() {
    if (!this.checkedAllArquivo) {
      this.select?.options.forEach((item: MatOption) => {
        item.select();
      });
    } else {
      this.select?.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }

    this.interteminateArquivo = false;
    this.checkedAllArquivo = !this.checkedAllArquivo;
  }

  selectAllDestinatario() {
    if (!this.checkedAllDestinatario) {
      this.selectDestinatario?.options.forEach((item: MatOption) => {
        item.select();
      });
    } else {
      this.selectDestinatario?.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }

    this.interteminateDestinatario = false;
    this.checkedAllDestinatario = !this.checkedAllDestinatario;
  }

  checkHandle() {
    const optionFound = this.select?.options.filter(
      (item: MatOption) => item.selected
    );
    if (optionFound?.length === 0) {
      this.checkedAllArquivo = false;
    }

    if (optionFound?.length === this.arquivoAnexadoList.length) {
      this.checkedAllArquivo = true;
    }

    if (optionFound && optionFound?.length > 0 && optionFound?.length < this.arquivoAnexadoList.length) {
      this.interteminateArquivo = true;
    } else {
      this.interteminateArquivo = false;
    }
  }

  checkHandleDestinatario() {
    const optionFound = this.selectDestinatario?.options.filter(
      (item: MatOption) => item.selected
    );
    if (optionFound?.length === 0) {
      this.checkedAllDestinatario = false;
    }

    if (optionFound?.length === this.destinatarioList.length) {
      this.checkedAllDestinatario = true;
    }

    if (optionFound && optionFound?.length > 0 && optionFound?.length < this.destinatarioList.length) {
      this.interteminateDestinatario = true;
    } else {
      this.interteminateDestinatario = false;
    }
  }

  insertContent(content: string) {
    if (this.elementSelected === 'editor') {
      tinymce.activeEditor?.insertContent(content);
    }
    if (this.elementSelected === 'assunto') {
      const textField = document.getElementById("assunto2");

      if (textField instanceof HTMLInputElement) {
        const cursorPosition = textField?.selectionStart;
        const textBeforeCursor = textField.value.substring(0, cursorPosition || undefined);
        const textAfterCursor = textField.value.substring(cursorPosition || 0);

        const updatedText =
          textBeforeCursor + content + textAfterCursor;

        this.assunto.setValue(updatedText);
      }

    }
  }

  showContent() {
    const content = this.editor?.editor.getContent();
    this.previewContent = content
    this.openModalPreview()
  }

  getContentEditor() {
    const content = this.editor?.editor.getContent();
    return content;
  }

  openModalPreview() {
    this.dialog.open(ModalPreviewMessageComponent, {
      width: '960px',
      height: '900px',
      data: {
        previewContent: this.previewContent,
        rotinas: this.rotina.value,
        destinatarios: this.destinatario.value,
        anexos: this.arquivoAnexado.value,
        templateName: this.nome.value
      }
    })
  }

  cancelButtonHandle() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.getBack(), this.dialog.closeAll() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openModalSucess() {
    const dialogConfig = this.getConfigModal()
    this.dialog.open(ModalGenericComponent, {
      ...dialogConfig,
      data: {
        icon: 'success',
        text: 'Template salvo com sucesso.',
        primaryButtonAction: () => { this.getBack(), this.dialog.closeAll() },
        primaryButtonText: 'IR PARA SELEÇÃO DOS TEMPLATES',
        primaryButtonVariant: 'primary',
      }
    })
  }

  getConfigModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '384px'
    return dialogConfig;
  }

  openModalErrorNewTemplate() {
    const dialogConfig = this.getConfigModal()
    this.dialog.open(ModalGenericComponent, {
      ...dialogConfig,
      data: {
        icon: 'error',
        text: 'Não foi possível salvar o template. Tente novamente mais tarde ou entre em contato com o suporte.',
        primaryButtonAction: () => { this.getBack(), this.dialog.closeAll() },
        primaryButtonText: 'IR PARA SELEÇÃO DOS TEMPLATES',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openModalErrorParameters() {
    const dialogConfig = this.getConfigModal()
    this.dialog.open(ModalGenericComponent, {
      ...dialogConfig,
      data: {
        icon: 'error',
        text: 'Não foi possível exibir a página, pois alguns itens não puderam ser carregados.',
        primaryButtonAction: () => { this.getBack(), this.dialog.closeAll() },
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openModalErrorRotinaDestinatario() {
    this.dialog.open(ModalGenericComponent, {
      width: '400px',
      data: {
        icon: 'error',
        text: 'Não foi possível criar um novo template, pois já existe um modelo com a mesma Rotina/Destinatário.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  getMessageContents() {
    this.isLoading = true;
    this.apiService.getMessage().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result) {
            this.listWildCards = result.wildcards;
            this.arquivoAnexadoList = result.anexosTemplates;
            this.destinatarioList = result.destinatariosEmail;
            this.listRotina = result.rotinasEmail;
            if (this.templateData) {
              this.populaDataEdit()
            }
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.openModalErrorParameters()
          console.log(error);
        },
      });
  }

  handleSaveButtonClick() {
    this.assunto.markAllAsTouched()
    this.nome.markAllAsTouched()
    this.descricao.markAllAsTouched()
    this.rotina.markAllAsTouched()
    this.destinatario.markAllAsTouched()
    const mensagem = this.getContentEditor()
    if (mensagem?.length === 0) { 
      this.mensagemInvalid = true;
      this.typeErrorMessage = 'required'
    }
    else if (mensagem && mensagem?.length < 20) {
      this.mensagemInvalid = true;
      this.typeErrorMessage = 'length'
    }
    else { 
      this.mensagemInvalid = false;
      this.typeErrorMessage = ''
    }

    const isValidFields = this.assunto.valid && this.rotina.valid && this.destinatario.valid && this.nome.valid && this.descricao.valid && !this.mensagemInvalid
    if (isValidFields) {
      const newMessage = {
        nome: this.nome.value,
        descricao: this.descricao.value,
        assunto: this.assunto.value,
        mensagem: mensagem,
        rotinaEmail: this.rotina.value,
        anexosTemplates: this.arquivoAnexado.value,
        destinatariosEmail: this.destinatario.value,
        idTemplateComunicacao: this.templateData ? this.templateData?.idTemplateComunicacao : 0
      }
      this.postNewMessage(newMessage)
    }
  }

  postNewMessage(newMessage: any) {
    this.isLoading = true;

    const apiObservable = this.templateData 
    ? this.apiService.updateMessage(newMessage) 
    : this.apiService.postNewMessage(newMessage)

    apiObservable.pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openModalSucess()
        },
        error: (error) => {
          this.isLoading = false;
          if (error?.status 
            && error?.status == 400
            && error?.error?.Errors 
            && error?.error?.Errors[0].includes("Já existe um template com a mesma Rotina / Destinatário")) {
            this.openModalErrorRotinaDestinatario()
          } else {
            this.openModalErrorNewTemplate()
          }
          console.log(error);
        },
      });
  }

  populaDataEdit() {
    this.nome.setValue(this.templateData?.nome)
    this.descricao.setValue(this.templateData?.descricao)
    this.assunto.setValue(this.templateData?.assunto)
    this.pickRotina()
    this.pickDestinatario()
    this.pickArquivoAnexado()
  }

  pickMensagem() {
    if (this.templateData?.mensagem) {
      tinymce.activeEditor?.insertContent(this.templateData?.mensagem);
    }
  }

  pickRotina() {
    const rotinaSelecionada = this.listRotina.find((rotina: { idRotinaEmail: any; }) => rotina.idRotinaEmail === this.templateData.rotinaEmail.idRotinaEmail)
    if (rotinaSelecionada) {
      this.rotina.setValue(rotinaSelecionada);
    }
  }

  pickDestinatario() {
    const destinatariosSelecionados: any[] | null = []

    this.templateData.destinatariosEmail.forEach((destinatarioEmail: { idDestinatarioEmail: any; }) => {
      const destinatarioSelecionado = this.destinatarioList.find((destinatario: { idDestinatarioEmail: any; }) => destinatario.idDestinatarioEmail === destinatarioEmail.idDestinatarioEmail)

      if (destinatarioSelecionado) {
        destinatariosSelecionados.push(destinatarioSelecionado)
      }
    })
    this.destinatario.setValue(destinatariosSelecionados)
    setTimeout(() => {
      this.checkHandleDestinatario()
    }, 200)
  }

  pickArquivoAnexado() {
    const arquivosSelecionados: any[] | null = []

    this.templateData.anexosTemplates.forEach((anexoTemplate: { idAnexoTemplate: any; }) => {
      const arquivoAnexoSelecionado = this.arquivoAnexadoList.find((arquivo: { idAnexoTemplate: any; }) => arquivo.idAnexoTemplate === anexoTemplate.idAnexoTemplate)

      if (arquivoAnexoSelecionado) {
        arquivosSelecionados.push(arquivoAnexoSelecionado)
      }
    })
    this.arquivoAnexado.setValue(arquivosSelecionados)
    setTimeout(() => {
      this.checkHandle()
    }, 200)
  }

}
