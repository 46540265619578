<yfs-header></yfs-header>
<section class="container-content">
  <div class="button-back">
    <yfs-link-back text="Voltar para tabela de usuários" (click)="getBackHandle()"></yfs-link-back>
  </div>
  <div class="container-title">
    <h1>EDITAR USUÁRIO EXTERNO</h1>
  </div>
  <mat-card>
    <mat-card-content>
      <div class="content">
        <mat-form-field class="name-input" appearance="fill">
          <mat-label>Nome completo</mat-label>
          <input data-test="input-name" [formControl]="fullName" matInput maxlength="100">
          <mat-error *ngIf="fullName.hasError('required')">
            O campo é obrigatório
          </mat-error>
          <mat-error *ngIf="fullName.hasError('maxlength')">
            O limite de caracteres é de 100
          </mat-error>
          <mat-error *ngIf="fullName.hasError('invalidName')">Nome incompleto</mat-error>
        </mat-form-field>
        <mat-form-field class="cpf-input" appearance="fill">
          <mat-label>CPF</mat-label>
          <input data-test="input-name" [formControl]="cpf" [textMask]="{mask: cpfMask, guide: false }" matInput maxlength="100">
          <mat-error *ngIf="cpf.hasError('required')">
            O campo é obrigatório
          </mat-error>
          <mat-error *ngIf="cpf.hasError('cpfInvalid') && !cpf.hasError('required')">CPF inválido</mat-error>
          <mat-error *ngIf="cpf.hasError('maxlength')">
            O limite de caracteres é de 100
          </mat-error>
        </mat-form-field>
        <mat-form-field class="celular-input" appearance="fill">
          <mat-label>Celular</mat-label>
          <input data-test="input-name" [formControl]="celular" [textMask]="{mask: celularMask }" matInput maxlength="100">
          <mat-error *ngIf="celular.hasError('required')">
            O campo é obrigatório
          </mat-error>
          <mat-error *ngIf="celular.hasError('maxlength')">
            O limite de caracteres é de 100
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputs" appearance="fill">
          <mat-label>E-mail</mat-label>
          <input data-test="input-email" [formControl]="email" matInput maxlength="50" (input)="checkEmailsMatch()">
          <mat-error *ngIf="email.hasError('required')">
            O campo é obrigatório
          </mat-error>
          <mat-error *ngIf="email.hasError('email') && !email.hasError('required') && !email.hasError('invalidEmailFormat')">
            Insira um e-mail válido
          </mat-error>
          <mat-error *ngIf="!email.hasError('required') && email.hasError('invalidEmailFormat')">
            O e-mail deve ter o formato correto (nome@yamaha)
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputs" appearance="fill">
          <mat-label>Repetir o e-mail</mat-label>
          <input data-test="input-email-confirm" [formControl]="emailConfirm" matInput maxlength="50" (input)="checkEmailsMatch()">
          <mat-error *ngIf="emailConfirm.hasError('email') && !emailConfirm.hasError('required')">
            Insira um e-mail válido
          </mat-error>
          <mat-error *ngIf="emailConfirm.invalid && emailConfirm.hasError('required')">
            O campo é obrigatório
          </mat-error>
          <mat-error *ngIf="emailConfirm.hasError('maxlength')">
            O limite de caracteres é de 50
          </mat-error>
          <mat-error *ngIf="emailConfirm.hasError('emailMismatch')">
            Os e-mails informados não são iguais
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputs" appearance="fill">
          <mat-label>Perfil do usuário</mat-label>
          <mat-select data-test="select-userProfile" [formControl]="userProfile" (selectionChange)="onUserProfileChange($event)">
            <mat-option *ngFor="let item of userProfiles" [value]="item.idPerfilAcesso">{{item.nome}}</mat-option>
          </mat-select>
          <mat-error *ngIf="userProfile.invalid">
            O campo é obrigatório
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputs" id="concessionaria" appearance="fill" floatLabel="always">
          <mat-label for="concessionaria">Concessionária</mat-label>
          <mat-select *ngIf="multiple" [formControl]="concessionaria" (selectionChange)="onConcessionariaSelectionChange($event.value)" multiple >
            <mat-option *ngFor="let concessionaria of listaConcessionaria" [value]="concessionaria.IdConcessionaria ? concessionaria.IdConcessionaria : concessionaria.idConcessionaria">
              <span class="select-label">
                {{ concessionaria.RazaoSocial ? concessionaria.RazaoSocial : (concessionaria.codigo ? concessionaria.codigo + ' - ' : '') + concessionaria.razaoSocial }}
              </span>
            </mat-option>
          </mat-select>
          <mat-select *ngIf="!multiple" [formControl]="concessionaria" (selectionChange)="onConcessionariaSelectionChange($event.value)" >
            <mat-option [value]="concessionaria.idConcessionaria" *ngFor="let concessionaria of listaConcessionaria">
              <span class="select-label">
                {{ concessionaria.RazaoSocial ? concessionaria.RazaoSocial : (concessionaria.codigo ? concessionaria.codigo + ' - ' : '') + concessionaria.razaoSocial }}
              </span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="concessionaria.hasError('required')">
            O campo é obrigatório
          </mat-error>
        </mat-form-field>
      </div>
      <div class="container-buttons">
        <div class="content-buttons">
          <yfs-button state="enabled" label="CANCELAR" type="secondary" (click)="openDialogCancel()"></yfs-button>
          <yfs-button [state]="emailConfirm.hasError('emailMismatch') || email.hasError('invalidEmailFormat') || concessionaria.hasError('required') || userProfile.invalid || emailConfirm.hasError('required') || celular.invalid || fullName.hasError('invalidName') || fullName.hasError('required')? 'disabled' : 'enabled'" label="SALVAR" type="primary" [press]="openDialogSaveUser"></yfs-button>
      </div>
      </div>
    </mat-card-content>
  </mat-card>
</section>
<yfs-alert-box *ngIf="timeoutError || genericError"></yfs-alert-box>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
