<div class="dialog-content" mat-dialog-content>
    <span class="title">Alteração de senha</span>
    <span class="subtitle">Por motivos de segurança, antes de acessar o Liberacred é necessário realizar a criação de
        uma nova senha seguindo os critérios abaixo:</span>

    <div class="content-inputs">
        <div class="rules-content">
            <span class="rules-title">Sua senha deve conter:</span>
            <div class="rules">
                <span [style.color]="getColor(validatesPassword.size)">
                    <mat-icon aria-hidden="false" aria-label="Ícone de expandir"
                        class="material-symbols-outlined rules-icon" [fontIcon]="getIcon(validatesPassword.size)">
                    </mat-icon>De 8 a 56 caracteres
                </span>
                <span [style.color]="getColor(validatesPassword.smallLetters)">
                    <mat-icon aria-hidden="false" aria-label="Ícone de expandir"
                        class="material-symbols-outlined rules-icon" [fontIcon]="getIcon(validatesPassword.smallLetters)"></mat-icon>
                    Ao menos 1 letra minúscula (a..z)
                </span>
                <span [style.color]="getColor(validatesPassword.capitalLetters)">
                    <mat-icon aria-hidden="false" aria-label="Ícone de expandir"
                        class="material-symbols-outlined rules-icon" [fontIcon]="getIcon(validatesPassword.capitalLetters)"></mat-icon>
                    Ao menos 1 letra maiúscula (A..Z)
                </span>
                <span [style.color]="getColor(validatesPassword.number)">
                    <mat-icon aria-hidden="false" aria-label="Ícone de expandir"
                        class="material-symbols-outlined rules-icon" [fontIcon]="getIcon(validatesPassword.number)"></mat-icon>
                    Ao menos 1 número (0..9)
                </span>
                <span [style.color]="getColor(validatesPassword.specialCharacter)">
                    <mat-icon aria-hidden="false" aria-label="Ícone de expandir"
                        class="material-symbols-outlined rules-icon" [fontIcon]="getIcon(validatesPassword.specialCharacter)"></mat-icon>
                    Ao menos 1 caractere especial (!@$#%*)
                </span>
            </div>
        </div>
        <div class="inputs">
            <mat-form-field appearance="fill">
                <mat-label>Senha atual</mat-label>
                <input data-test="input-current-password" [formControl]="senhaAtual" (focusout)="checkCurrentPassword()"
                    [type]="hideSenhaAtual ? 'password' : 'text'" matInput>
                <button data-test="button-hide" mat-icon-button matSuffix (click)="hideSenhaAtual = !hideSenhaAtual"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideSenhaAtual">
                    <mat-icon class="visibility-icon">{{hideSenhaAtual ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="senhaAtual.hasError('required')">
                    O campo é obrigatório
                </mat-error>
                <mat-error *ngIf="senhaAtual.hasError('invalidPassword')">
                   Senha atual está incorreta
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Nova senha</mat-label>
                <input data-test="input-new-password" [formControl]="novaSenha" (input)="validatePassword(true)" (keydown.space)="$event.preventDefault()" (paste)="$event.preventDefault()" (copy)="$event.preventDefault()"
                    [type]="hideNovaSenha ? 'password' : 'text'" matInput>
                <button data-test="button-hide" mat-icon-button matSuffix (click)="hideNovaSenha = !hideNovaSenha"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNovaSenha">
                    <mat-icon class="visibility-icon">{{hideNovaSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="novaSenha.hasError('required')">
                    O campo é obrigatório
                </mat-error>
                <mat-error *ngIf="novaSenha.hasError('invalidPassword')">
                    Senha inválida
                </mat-error>
                <mat-error *ngIf="novaSenha.hasError('samePassword')">
                    A senha atual e a nova não podem ser iguais
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Repetir nova senha</mat-label>
                <input data-test="input-confirmation-password" [formControl]="senhaConfirmacao" (keydown.space)="$event.preventDefault()" (paste)="$event.preventDefault()" (copy)="$event.preventDefault()"
                    (input)="checkPasswords()" [type]="hideSenhaConfirmacao ? 'password' : 'text'" matInput>
                <button data-test="button-hide" mat-icon-button matSuffix
                    (click)="hideSenhaConfirmacao = !hideSenhaConfirmacao" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hideSenhaConfirmacao">
                    <mat-icon class="visibility-icon">{{hideSenhaConfirmacao ? 'visibility_off' :
                        'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="senhaConfirmacao.hasError('required')">
                    O campo é obrigatório
                </mat-error>
                <mat-error *ngIf="senhaConfirmacao.hasError('invalidPassword')">
                    As senhas não coincidem
                </mat-error>
            </mat-form-field>

            <yfs-button class="send-button" state="enabled" [fullWidth]="true" label="ALTERAR SENHA"
                (click)="redefinePassword()" type="primary"></yfs-button>
        </div>
    </div>

    <span class="informative-text">
        A Lei Geral de Proteção de Dados (LGPD - Lei nº 13709) dá o direito de solicitar ao Banco Yamaha a qualquer
        momento a exclusão de seus dados do sistema de Antecipação a Fornecedores. Pedimos que entre em contato com o
        SAC e manifeste seu desejo através dos canais de/ou Telefone: 0800-774-8283 ou por e-mail:
        sac.banco@yamaha-motor.com.br.
    </span>
</div>
