<yfs-header></yfs-header>
<section *ngIf="currentFlow != 'complete'" class="container-content">
  <div class="button-back">
    <yfs-link-back text="Voltar para simulação" (click)="getBackHandle()"></yfs-link-back>
  </div>
  <h1 *ngIf="(currentFlow != 'verifyData') && (currentFlow != 'concessionaire')">CADASTRO</h1>
  <h1 *ngIf="currentFlow == 'verifyData'">VERIFICAÇÃO DOS DADOS DA PROPOSTA</h1>
  <h1 *ngIf="currentFlow == 'concessionaire'">CANAL DE RELACIONAMENTO</h1>
  <div class="contant-flow">
    <yfs-register-concessionaire *ngIf="currentFlow === 'concessionaire'"
    (currentFlowChanged)="changeCurrentFlow($event)"></yfs-register-concessionaire>
    <yfs-register-documents *ngIf="currentFlow === 'document'"
      (currentFlowChanged)="changeCurrentFlow($event)"></yfs-register-documents>
    <yfs-register-personal-data *ngIf="currentFlow === 'personalData'"
      (currentFlowChanged)="changeCurrentFlow($event)"></yfs-register-personal-data>
    <yfs-register-address *ngIf="currentFlow === 'address'"
      (currentFlowChanged)="changeCurrentFlow($event)"></yfs-register-address>
    <yfs-register-contact *ngIf="currentFlow === 'contact'"
      (currentFlowChanged)="changeCurrentFlow($event)"></yfs-register-contact>
    <yfs-verify-data *ngIf="currentFlow === 'verifyData'"
      (currentFlowChanged)="changeCurrentFlow($event)"></yfs-verify-data>
  </div>
</section>
<section>
  <yfs-complete *ngIf="currentFlow === 'complete'"></yfs-complete>
</section>
