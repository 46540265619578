<yfs-header></yfs-header>
<section class="container-content">
  <div *ngIf="currentFlow === 'communication'">
    <div class="button-back">
      <yfs-link-back [text]="previousScreen === 'parameters' ? 'Voltar para parâmetros técnicos' : 'Voltar para minha área'" (click)="getBack()"></yfs-link-back>
    </div>
    <h1>COMUNICAÇÃO</h1>
    <mat-card class="card">
      <span class="h1">Selecione uma opção</span>
      <mat-card-content class="card-content">
        <mat-card
          class="min-card"
          [ngClass]="{ 'selected-card': isCardSelected[0] }"
          (click)="toggleCard(0)"
        >
          <span class="h1"> Cadastro de mensagens de <br> e-mail </span>
          <span class="h2"> Cadastre mensagens e templates de <br> e-mail </span>
          <span *ngIf="isCardSelected[0]" class="selected"
            ><mat-icon
              aria-hidden="false"
              aria-label="Ícone de selecionado"
              class="material-symbols-outlined"
              fontIcon="check_circle"
            ></mat-icon>
            Selecionado
          </span>
        </mat-card>
        <!-- TODO: Implementar cadastro de rotinas de e-mail -->
        <!-- <mat-card
          class="min-card"
          [ngClass]="{ 'selected-card': isCardSelected[1] }"
          (click)="toggleCard(1)"
        >
          <span class="h1"> Cadastro de rotinas de e-mail </span>
          <span class="h2"> Cadastre rotinas de e-mail </span>
          <span *ngIf="isCardSelected[1]" class="selected"
            ><mat-icon
              aria-hidden="false"
              aria-label="Ícone de selecionado"
              class="material-symbols-outlined"
              fontIcon="check_circle"
            ></mat-icon>
            Selecionado
          </span>
        </mat-card> -->
      </mat-card-content>
    </mat-card>
    <mat-card class="card" *ngIf="isCardSelected[0] && !showErrorMessage" style="margin-top: 16px">
      <div class="template-header">
        <div class="template-header-itens">
          <span class="h1">Selecione um template existente:</span>
          <span class="templates">{{templates}} templates encontrados</span>
        </div>
        <yfs-button
          class="template-button"
          state="enabled"
          label="NOVA MENSAGEM"
          type="primary"
          (click)="goToMessage()"
        ></yfs-button>
      </div>
      <mat-card-content class="card-content-template">
        <mat-card  class="min-card-template" *ngFor="let message of templateList">
          <div class="card-message">
           <span class="h1">{{ message.nome | ellipsis:48 }}</span>
            <span class="h2">{{ message.descricao | ellipsis:120 }}</span>
            <div class="card-button">
              <div class="button">
                <span class="selected-preview">
                  <yfs-tooltip [tooltipText]="'Ver prévia'"
                    ><mat-icon
                      (click)="previewMessage($event, message)"
                      aria-hidden="false"
                      aria-label="Ícone de preview"
                      class="material-symbols"
                      fontIcon="visibility"
                    >
                    </mat-icon>
                  </yfs-tooltip>
                </span>
              </div>
              <div (click)="editMessage($event, message)" class="edit-button">
                <span class="selected-edit-delete">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Ícone de selecionado"
                    class="material-symbols-outlined"
                    fontIcon="edit"
                  ></mat-icon>
                  Editar
                </span>
              </div>
              <div (click)="deleteMessage($event, message.nome, message.idTemplateComunicacao)" class="delete-button">
                <span class="selected-edit-delete">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Ícone de selecionado"
                    class="material-symbols-outlined"
                    fontIcon="delete"
                  ></mat-icon>
                  Excluir
                </span>
              </div>
            </div>
          </div>
        </mat-card>
      </mat-card-content>
      <div class="button-more">
        <yfs-button type="secondary" *ngIf="!empty" state="enabled" size="small" label="CARREGAR MAIS" (click)="moreItens()" icon="add-circle-outline" ></yfs-button>
      </div>
    </mat-card>
    <mat-card class="card" *ngIf="showErrorMessage" style="margin-top: 16px">
      <mat-card-content class="card-content-error">
        <img src="assets/svg/error-icon.svg" alt="documento vazio" height="48" width="48">
        <span class="error-message">Estamos com problemas para exibir os templates no momento.</span>
        <span class="error-message">Tente recarregar ou volte mais tarde.</span>
        <yfs-button
          state="enabled"
          class="button-error"
          label="VOLTAR PARA MINHA ÁREA"
          type="primary"
          (click)="getBack()"
        ></yfs-button>
      </mat-card-content>
    </mat-card>
  </div>

  <yfs-new-message *ngIf="currentFlow === 'newMessage'" (currentFlowChanged)="closeNewMessage()" [templateData]="templateSelected"></yfs-new-message>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
