<yfs-header [flowSeller]="true"></yfs-header>
<div class="container-content">
  <div class="button-back">
    <yfs-link-back text="Voltar para Minha área" (click)="getBack()"></yfs-link-back>
  </div>
  <h1>GERAR ARQUIVO CONTÁBIL</h1>
  <h2>
    Selecione a data da geração do arquivo. Por padrão a data preenchida é a
    do dia anterior ao atual.
  </h2>
  <mat-card class="card" *ngIf="!success">
    <mat-card-content class="card-content">
      <div *ngIf="emptyDocument && !generationError">
        <img src="assets/svg/empty.svg" alt="documento vazio">
        <div class="message-empty-document">
        <span>Nenhum resultado encontrado para a data selecionada. Por favor, tente novamente com outra data.</span>
      </div>
    </div>
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>Data da movimentação</mat-label>
        <input type="hidden" [matDatepicker]="picker" [max]="maxDate" [(ngModel)]="dataLabel"
          (ngModelChange)="onInputChange()" />
        <input data-test="input-date-movimentation" matInput [textMask]="{ mask: mask }" [max]="maxDate"
          [formControl]="dataMovimentacao" [ngModel]="dataLabel | date : 'dd/MM/yyyy'"
          (ngModelChange)="onInputChange()" />
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="dataMovimentacao.hasError('required')">
          O campo é obrigatorio
        </mat-error>
        <mat-error class="date-error" *ngIf="dataMovimentacao.hasError('invalidDate')">Data inválida.</mat-error>
        <mat-error class="date-error" *ngIf="dataMovimentacao.hasError('dataValida')"><img src="assets/svg/atention.svg"
            alt="icone de atenção" /> Data inválida. Insira uma data anterior a hoje.</mat-error>
      </mat-form-field>
      <yfs-button label="GERAR ARQUIVO" type="primary" [press]="sendRequest" 
        data-test="button-generate" class="btn-generate" 
        [state]="dataMovimentacao.hasError('dataValida') || dataMovimentacao.hasError('required') || dataMovimentacao.hasError('invalidDate') ? 'disabled' : 'enabled'">
      </yfs-button>
      <span *ngIf="generationError" data-test="error-message-file-size" class="error-message"><img
          alt="icone de atenção" src="assets/svg/atention.svg" /> Não foi possível gerar o
        arquivo contábil</span>
    </mat-card-content>
  </mat-card>
  <mat-card class="card" *ngIf="success">
    <mat-card-content class="card-content">
      <img class="icon-success" src="assets/svg/success.svg" alt="logo modal success" />
      <div class="message">
        <span>Arquivo gerado com sucesso!</span>
      </div>
      <div class="message">
        <span>Você já pode fazer o download abaixo.</span>
      </div>
      <yfs-button state="enabled" class="btn-download" label="BAIXAR ARQUIVO" type="primary" (click)="downloadFile()"></yfs-button>
      <div>
        <a (click)="openDialog()">GERAR OUTRO ARQUIVO</a>
      </div>
      <div class="atention">
        <span>(O arquivo atual será apagado)</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
