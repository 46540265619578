<mat-card>
  <mat-card-content>
<span class="title-document">Endereço</span>
<div class="container-content">
  <div *ngIf="!timeoutError && !genericError" class="content">
    <mat-form-field class="inputs" appearance="fill">
      <mat-label>CEP</mat-label>
      <input data-test="input-cep" [formControl]="cep" matInput [textMask]="{mask: cepMask}" (focusout)="validateCep($event)">
      <mat-error *ngIf="cep.hasError('required')">
        O campo é obrigatorio
      </mat-error>
      <mat-error *ngIf="cep.hasError('invalidCep')">O CEP é inválido</mat-error>
    </mat-form-field>
    <mat-form-field class="inputs" appearance="fill">
      <mat-label>Rua/Avenida/Praça</mat-label>
      <input data-test="input-street" [formControl]="logradouro" matInput maxlength="60">
      <mat-error *ngIf="logradouro.invalid">
        O campo é obrigatorio
      </mat-error>
      <mat-error *ngIf="logradouro.hasError('maxlength')">
        O limite de caracteres é de 60
      </mat-error>
    </mat-form-field>
    <mat-form-field class="inputs" appearance="fill">
      <mat-label>Número</mat-label>
      <input data-test="input-number-address" [formControl]="numero" maxlength="5" (input)="formatFieldControl(numero)" matInput>
      <mat-error *ngIf="numero.hasError('required')">
        O campo é obrigatorio
      </mat-error>
      <mat-error *ngIf="numero.hasError('maxlength')">
        só é permitido 5 caracteres
      </mat-error>
    </mat-form-field>
    <mat-form-field class="inputs" appearance="fill">
      <mat-label>Complemento</mat-label>
      <input data-test="input-complement-address" [formControl]="complemento" (input)="formatFieldControl(complemento)" matInput maxlength="40">
      <mat-error *ngIf="complemento.hasError('maxlength')">
        O limite de caracteres é de 40
      </mat-error>
    </mat-form-field>
    <mat-form-field class="inputs" appearance="fill">
      <mat-label>Bairro</mat-label>
      <input data-test="input-neighborhood" [formControl]="bairro" matInput maxlength="20">
      <mat-error *ngIf="bairro.hasError('maxlength')">
        O limite de caracteres é de 20
      </mat-error>
      <mat-error *ngIf="bairro.hasError('required')">
        O campo é obrigatorio
      </mat-error>
    </mat-form-field>
    <mat-form-field class="inputs" appearance="fill">
      <mat-label>Cidade</mat-label>
      <input data-test="input-city" [formControl]="cidade" matInput maxlength="60">
      <mat-error *ngIf="cidade.invalid">
        O campo é obrigatorio
      </mat-error>
      <mat-error *ngIf="cidade.hasError('maxlength')">
        O limite de caracteres é de 60
      </mat-error>
    </mat-form-field>
    <mat-form-field class="inputs" appearance="fill">
      <mat-label>Estado</mat-label>
      <mat-select data-test="select-uf" [formControl]="estado">
        <mat-option *ngFor="let enum of listUfs | keyvalue" [value]="enum.key">{{enum.key}}</mat-option>
      </mat-select>
      <mat-error *ngIf="estado.invalid">
        O campo é obrigatorio
      </mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="!timeoutError && !genericError" class="container-buttons">
    <div class="divisor-content">
        <yfs-button state="enabled" label="CANCELAR" type="tertiary" (click)="openDialog()"></yfs-button>
        <span class="remaining-number">4 de 5</span>
    </div>
    <div class="divisor-content-next">
      <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="goBack()"></yfs-button>
      <yfs-button [state]="validCep ? 'enabled' : 'disabled'" label="PROSSEGUIR" type="primary" [press]="nextStep"></yfs-button>
    </div>
</div>
</div>
</mat-card-content>
</mat-card>
<yfs-alert-box *ngIf="timeoutError || genericError"></yfs-alert-box>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
