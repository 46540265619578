import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';

@Component({
  selector: 'yfs-modal-confirm-accreditation',
  templateUrl: './modal-confirm-accreditation.component.html',
  styleUrls: ['./modal-confirm-accreditation.component.scss']
})
export class ModalConfirmAccreditationComponent {

  public listaTipo = [{
    IdTipo: 1,
    Nome: "Concessionária"
  },
  {
    IdTipo: 2,
    Nome: "Parceiro"
  }
  ];
  public tipo = new FormControl();
  public selectedTipo: number[] = []
  public idsTipo: number[] = []
  public checkedAllTipo = false;
  public isLoading = false;
  public disabledType = false;

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmAccreditationComponent>,
    public dialog: MatDialog,
    private apiService: ApiHttpService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.verifyPlan()
    if (this.data?.infos?.tipo !== undefined) {
      const tipoSelecionado = this.listaTipo.find(tipo => tipo.IdTipo === this.data.infos.tipo);
      if (tipoSelecionado) {
        this.tipo.setValue(tipoSelecionado.IdTipo);
      }
    }

    this.tipo.valueChanges.subscribe(value => {
      this.onTipoChange(value);
    });
  }

  checkHandleTipo(IdTipo: number) {
    if (this.selectedTipo.includes(IdTipo)) {
      this.selectedTipo = this.selectedTipo.filter(item => item !== IdTipo);
    } else {
      this.selectedTipo.push(IdTipo);
    }

    this.idsTipo = this.selectedTipo.slice();

    if (this.selectedTipo.length === this.listaTipo.length) {
      this.checkedAllTipo = true;
    } else {
      this.checkedAllTipo = false;
    }
    this.tipo.setValue(this.selectedTipo);
  }

  onTipoChange(newTipo: number) {
    this.data.infos.tipo = newTipo;
  }

  closeModal() {
    this.dialogRef.close();
  }

  accredit = () => {
    this.isLoading = true;
    const idConcessionaria = this.data.infos.idConcessionaria;
    const idTipo = this.tipo.value;
    this.apiService.credenciar(idConcessionaria, idTipo).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openDialogSucess();
        },
        error: (error) => {
          this.isLoading = false;
          this.openDialogError();
          console.log(error);
        },
      });
  }

verifyPlan(){
  this.isLoading = true;
  const idConcessionariaAccredit = this.data?.infos?.idConcessionaria;
  this.apiService.getLinkedPlan(idConcessionariaAccredit, true).pipe(
    timeout(30000),
    catchError((error) => {
      if (error instanceof TimeoutError) {
        this.isLoading = false;
        return throwError(
          () => 'A requisição demorou muito tempo e foi cancelada.'
        );
      }
      return throwError(() => error);
    })
  )
    .subscribe({
      next: (result) => {
        if(result){
          this.isLoading = false;
          this.disabledType = result.body
          if(this.disabledType === true){
            this.tipo.disable()
          }else{
            this.tipo.enable()
          }
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
}

openDialogError() {
  this.dialog.open(ModalGenericComponent, {
    width: '397px',
    data: {
      icon: 'error',
      secondaryText: 'Algo deu errado e não conseguimos fazer o credenciamento. <br /> <strong>Tente outra vez ou volte mais tarde.</strong>',
      primaryButtonAction: 'close',
      primaryButtonText: 'FECHAR',
      primaryButtonVariant: 'secondary',
      secundaryButtonText: 'TENTAR NOVAMENTE',
      secundaryButtonVariant: 'primary',
      secundaryButtonAction: () => { this.accredit() }
    },
  });
}

openDialogSucess() {
  this.dialog.open(ModalGenericComponent, {
    width: '383px',
    data: {
      icon: 'success',
      textBold: 'Credenciamento feito com sucesso.',
      primaryButtonAction: () => { this.dialog.closeAll(), window.location.reload() },
      primaryButtonText: 'FECHAR',
      primaryButtonVariant: 'primary',
    },
  });
}
}
